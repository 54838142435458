
import React, { useEffect, useState,useRef } from "react"
import yukturleri from "./YukTurleri";

import iller from "./iller";
import bosaltmaIller from "./iller"
import { Link, useParams,useNavigate, Navigate,useLocation,useHref } from "react-router-dom";
import ilceler from "./ilceler.js"
import bosaltmaIlceler from "./ilceler.js"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Uetds from "./Uetds";

 const UetdsDuzenle = () =>{ 
    let navigate = useNavigate();
    
    const location = useLocation();
    console.log(location);
    console.log(location.state.lastpage);

    let {id} = useParams();
   const [uetds,setUetds] = useState();
   const [plaka1,setPlaka1] = useState();
   const [plaka2,setPlaka2] = useState();
   const [tcKimlikNo1,setTcKimlikNo1] = useState();
   const [tcKimlikNo2,setTcKimlikNo2] = useState();
   const [tasimaBedeli,setTasimaBedeli] = useState();
   const [aliciUnvan,setAliciUnvan] =useState();
   const [gondericiUnvan,setGondericiUnvan] = useState();
   const [yukBirimi,setYukBirimi] = useState();
   const [yukCinsId,setYukCinsId] = useState();
   const [yukCinsAdi,setYukCinsAdi] = useState();
   const [yukMiktarı,setYukMiktarı] = useState();
   const [yuklemeIlMernisId,setyuklemeIlMernisId] = useState();
   const [yuklemeIlAdi,setYuklemeIlAdi] = useState();
   const [bosaltmaIlAdi,setBosaltmaIlAdi] = useState();
   const [yuklemeIlceAdı,setYuklemeIlceAdi] = useState();
   const [yuklemeIlceMernisKodu,setYuklemeIlceMernisKodu] = useState("");
   const [bosaltmaIlceAdi,setBosaltmaIlceAdi] = useState();
   const [bosaltmaIlMernisKodu,setBosaltmaIlMernisId] = useState();
   const [bosaltmaIlceMernisId, setBosaltmaIlceMernisId] = useState();
   const [yuklemeTarihi,setYuklemeTarihi] = useState();
   const [bosaltmaTarihi,setBosaltmaTarihi] = useState();
   const [yuklemeSaati, setYuklemeSaati] = useState();
   const [bosaltmaSaati,setBosaltmaSaati] = useState();

  const yuklemeIlceler = ilceler.filter(ilce=>ilce.ilce_sehirkey==yuklemeIlMernisId);
  const bosaltmaIlcelerr = bosaltmaIlceler.filter(ilce=>ilce.ilce_sehirkey==bosaltmaIlMernisKodu);
  const token = localStorage.getItem("token")
    useEffect(()=>{
    fetch(`https://apipartner.yuksis.com/api/uetds/GetById?id=${id}`,{
        method: "Get",
        withCredentials:true,
        
        headers: {
          "content-type": "application/json",
          "Authorization": `Bearer ${token}`
      }})
    .then(response=>response.json()).then(response=>{
        setUetds(response.data)
       setPlaka1(response.data.plaka1);
       setPlaka2(response.data.plaka2);
       setTcKimlikNo1(response.data.tcKimlikNo1);
       setTcKimlikNo2(response.data.tcKimlikNo2);
       setTasimaBedeli(response.data.tasimaBedeli);
       setAliciUnvan(response.data.aliciUnvan);
       setGondericiUnvan(response.data.gondericiUnvan);
       setYukBirimi(response.data.yukBirimi);
       setYukCinsId(response.data.yukCinsId);
       setYukCinsAdi(response.data.yukCinsAdi);
       setYukMiktarı(response.data.yukMiktarı); 
       setyuklemeIlMernisId(response.data.yuklemeIlMernisId);
       setYuklemeIlceMernisKodu(response.data.yuklemeIlceMernisKodu);
       setYuklemeSaati(response.data.yuklemeSaati);
       setYuklemeTarihi(response.data.yuklemeTarihi);
       setBosaltmaIlMernisId(response.data.bosaltmaIlMernisKodu);
   
       setBosaltmaSaati(response.data.bosaltmaSaati);
       setBosaltmaTarihi(response.data.bosaltmaTarihi);
       setYuklemeIlAdi(response.data.yuklemeIlAdi);
       setYuklemeIlceAdi(response.data.yuklemeIlceAdı);
       setBosaltmaIlAdi(response.data.bosaltmaIlAdi);
       setBosaltmaIlceAdi(response.data.bosaltmaIlceAdı);
       setBosaltmaIlceMernisId(response.data.bosaltmaIlceMernisKodu);




    }).catch(error=>console.log(error))
    
},[id])
const Kaydet = () =>{   
   
        fetch(`https://apipartner.yuksis.com/api/uetds/UpdateUetds`,{
            method: "POST",
            withCredentials:true,
        

           
            headers: {
              "content-type": "application/json",
              "Authorization": `Bearer ${token}`
          },
          body: JSON.stringify({
              id:id,plaka1:plaka1,plaka2:plaka2,tcKimlikNo1:tcKimlikNo1,tcKimlikNo2:tcKimlikNo2,tasimaBedeli:tasimaBedeli,aliciUnvan:aliciUnvan,gondericiUnvan:gondericiUnvan,yukBirimi:yukBirimi,yukCinsAdi:yukCinsAdi,yukCinsId:yukCinsId,
              yukMiktarı:yukMiktarı,yuklemeIlAdi:yuklemeIlAdi,yuklemeIlMernisId:yuklemeIlMernisId,yuklemeIlceAdı,yuklemeIlceAdı,yuklemeIlceMernisKodu,yuklemeIlceMernisKodu,yuklemeSaati:yuklemeSaati,yuklemeTarihi:yuklemeTarihi,bosaltmaIlAdi:bosaltmaIlAdi,
              bosaltmaIlMernisKodu:bosaltmaIlMernisKodu,bosaltmaIlceAdı:bosaltmaIlceAdi,bosaltmaIlceMernisKodu:bosaltmaIlceMernisId,bosaltmaSaati:bosaltmaSaati,bosaltmaTarihi:bosaltmaTarihi
          })
        }).then(response=>response.json()).then(response=>{if(response.statusCode==200){
            navigate("/Uetds",{state:location.state.lastpage});
            toast.success("Başarıyla Düzenlendi.",{autoClose: 500})
        }}).catch(error=>console.log(error));

}
console.log(yuklemeIlAdi);
console.log(yuklemeIlceAdı)
    
   
    return(<div class="row" >
<div class="content">          
    <div class="content-page" style={{margin:50}}>
        <div class="content">        
            <div class="row">
                <div class="col-sm-12">
                    <div class="three-menu">
                        <h4 class="page-title">UETDS Talep Düzenle</h4>
                        <ol class="breadcrumb">
                            <li><button onClick="" title="Anasayfa">Anasayfa</button>/</li>
                            <li><a to="/Uetds" href="UEDTS-Talepler.aspx" title="UEDTS Talep Listesi">UETDS Talepleri</a>/</li>
                            <li>UETDS Talep Düzenleme</li>
                        </ol>
                    </div>
                </div>
            </div>
        
            <div class="row">
                <div class="col-lg-12 tab-list">
    
                    <ul class="nav nav-tabs" role="tablist">
                        <li role="presentation" class="active"><a href="#genel" aria-controls="genel" role="tab" data-toggle="tab">UEDTS Talebi </a></li>
                    </ul>
    
           
                    <div class="tab-content">
                        
                            <div role="tabpanel" class="tab-pane active" id="genel">
    
                                <div class="form-group row">
                                    <label class="col-sm-2 col-xs-12 form-control-label">Gönderici Ünvan</label>
                                    <div class="col-sm-4 col-xs-12">
                                        <input name="ctl00$ContentPlaceHolder1$txtGondericiUnvan" value={gondericiUnvan} onChange={e=>setGondericiUnvan(e.target.value)}  type="text" id="ContentPlaceHolder1_txtGondericiUnvan" class="form-control" required=""/>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-sm-2 col-xs-12 form-control-label">Alıcı Ünvan</label>
                                    <div class="col-sm-4 col-xs-12">
                                        <input name="ctl00$ContentPlaceHolder1$txtAliciUnvan" type="text" value={aliciUnvan} onChange={e=>setAliciUnvan(e.target.value)} id="ContentPlaceHolder1_txtAliciUnvan" class="form-control" required=""/>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-sm-2 col-xs-12 form-control-label">Araç Plaka</label>
                                    <div class="col-sm-4 col-xs-12">
                                        <input name="ctl00$ContentPlaceHolder1$txtServisAracPlaka" value={plaka1} onChange={(e)=>setPlaka1(e.target.value)} type="text" id="ContentPlaceHolder1_txtServisAracPlaka" class="form-control" required=""/>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-sm-2 col-xs-12 form-control-label">Dorse Plaka</label>
                                    <div class="col-sm-4 col-xs-12">
                                        <input name="ctl00$ContentPlaceHolder1$txtServisDorsePlaka"  value={plaka2} onChange={(e)=>setPlaka2(e.target.value)} type="text" id="ContentPlaceHolder1_txtServisDorsePlaka" class="form-control"/>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-sm-2 col-xs-12 form-control-label">Şoför TC No</label>
                                    <div class="col-sm-4 col-xs-12">
                                        <input name="ctl00$ContentPlaceHolder1$txtServisSoforTCNo" value={tcKimlikNo1} onChange={(e)=>setTcKimlikNo1(e.target.value)} type="text" id="ContentPlaceHolder1_txtServisSoforTCNo" class="form-control" required=""/>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-sm-2 col-xs-12 form-control-label">Yardımcı Sürücü TC No</label>
                                    <div class="col-sm-4 col-xs-12">
                                        <input name="ctl00$ContentPlaceHolder1$txtYardimciSurucuTCNo" value={tcKimlikNo2} onChange={(e)=>setTcKimlikNo2(e.target.value)} type="text" id="ContentPlaceHolder1_txtYardimciSurucuTCNo" class="form-control"/>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-sm-2 col-xs-12 form-control-label">Yük Cinsi</label>
                                    <div class="col-sm-4 col-xs-12">    
                                        <select name="ctl00$ContentPlaceHolder1$ddlYukCinsi" onChange={(e)=>{setYukCinsId(e.target.value)}} id="ContentPlaceHolder1_ddlYukCinsi" class="form-control">
      
                                        <option selected  value={yukCinsId}>{yukCinsAdi}</option>
       {yukturleri.map(yuk=>{
           return ( <option   value={yuk.key}>{yuk.label}</option>)
       })}

    
    </select>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-sm-2 col-xs-12 form-control-label">Yükleme İli</label>
                                    <div class="col-sm-4 col-xs-12">
                                        <select name="ctl00$ContentPlaceHolder1$ddlYuklemeIl" onChange={e=>{setyuklemeIlMernisId(e.target.value);setYuklemeIlAdi(iller.find(il=>il.id==e.target.value).name)}} id="ContentPlaceHolder1_ddlYuklemeIl" class="form-control">
                                        <option selected value={yuklemeIlMernisId} >{yuklemeIlAdi}</option>
                        {iller.map(il=>{
                            return (
                            
                                <option id={il.name}  value={il.id}>{il.name}</option>
                            )
                        })}
    
    </select>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-sm-2 col-xs-12 form-control-label">Yükleme İlce</label>
                                    <div class="col-sm-4 col-xs-12">
                                      <select name="ctl00$ContentPlaceHolder1$ddlYuklemeIlce" onChange={(e)=>{setYuklemeIlceMernisKodu(e.target.value);setYuklemeIlceAdi(ilceler.find(ilce=>ilce.ilce_key==e.target.value).ilce_title)}} id="ContentPlaceHolder1_ddlYuklemeIlce" class="form-control">
                                      <option selected value={yuklemeIlceMernisKodu} >{yuklemeIlceAdı}</option>
                                      {yuklemeIlceler.map(yuklemeIlce => {
                    return (
                        <option value={yuklemeIlce.ilce_key}>{yuklemeIlce.ilce_title}</option>
                    )
                })}  

      
    
    </select>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-sm-2 col-xs-12 form-control-label">Boşaltma İli</label>
                                    <div class="col-sm-4 col-xs-12">
                                        <select name="ctl00$ContentPlaceHolder1$ddlBosaltmaIl" onChange={(e)=>{setBosaltmaIlMernisId(e.target.value);setBosaltmaIlAdi(iller.find(il=>il.id==e.target.value).name)}} class="form-control">
                                        <option selected value={bosaltmaIlMernisKodu} >{bosaltmaIlAdi}</option>
        {bosaltmaIller.map(il=>{
            return (
                <option  value={il.id}>{il.name}</option>
)


        }
       
        )}
       
      
    
    </select>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-sm-2 col-xs-12 form-control-label">Boşaltma İlce</label>
                                    <div class="col-sm-4 col-xs-12">
                                       
                                        <select name="ctl00$ContentPlaceHolder1$ddlBosaltmaIlce" onChange={e=>{setBosaltmaIlceMernisId(e.target.value);setBosaltmaIlceAdi(ilceler.find(ilce=>ilce.ilce_key==e.target.value).ilce_title)}} id="ContentPlaceHolder1_ddlBosaltmaIlce" class="form-control">
                                        <option selected value={bosaltmaIlceMernisId} >{bosaltmaIlceAdi}</option>
                             {bosaltmaIlcelerr.map(ilce=>{
                                 return ( <option  value={ilce.ilce_key}>{ilce.ilce_title}</option> )
                             })}
    
    </select>
                                    </div>
                                </div>
                        

                               
                                
                                <div class="form-group row">
                                    <label class="col-sm-2 col-xs-12 form-control-label">Yükleme Tarihi</label>
                                    <div class="col-sm-4 col-xs-12">
                                        <input name="yuklemeTarihi" placeholder="11/11/2022 formatında olmalı" value={yuklemeTarihi} onChange={e=>setYuklemeTarihi(e.target.value)} id="ContentPlaceHolder1_txtYuklemeTarihi" maxlength="10" class="form-control" pattern="dd/mm/yyyy"  type="text" required="required"/>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-sm-2 col-xs-12 form-control-label">Yükleme Saati</label>
                                    <div class="col-sm-4 col-xs-12">
                                        <input name="ctl00$ContentPlaceHolder1$txtServisYuklemeSaati" value={yuklemeSaati}  onChange={e=>setYuklemeSaati(e.target.value)} placeholder="11:11 formatında olmalı" type="text" id="ContentPlaceHolder1_txtServisYuklemeSaati" class="form-control time"  required="" maxlength="5"/>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-sm-2 col-xs-12 form-control-label">Boşaltma Tarihi</label>
                                    <div class="col-sm-4 col-xs-12">
                                        <input name="ctl00$ContentPlaceHolder1$txtBosaltmaTarihi" value={bosaltmaTarihi}  onChange={e=>setBosaltmaTarihi(e.target.value)}  id="ContentPlaceHolder1_txtBosaltmaTarihi" class="form-control" placeholder="11/11/2022 formatında olmalı" maxlength="10" type="text" required=""/>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-sm-2 col-xs-12 form-control-label">Boşaltma Saati</label>
                                    <div class="col-sm-4 col-xs-12">
                                        <input name="ctl00$ContentPlaceHolder1$txtServisBosaltmaSaati" value={bosaltmaSaati}  onChange={e=>setBosaltmaSaati(e.target.value)} placeholder="11:11 formatında olmalı" type="text" id="ContentPlaceHolder1_txtServisBosaltmaSaati" class="form-control time"  required="" maxlength="5"/>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-sm-2 col-xs-12 form-control-label">Yük Miktarı</label>
                                    <div class="col-sm-4 col-xs-12">
                                        <input name="ctl00$ContentPlaceHolder1$txtYukMiktari" type="number" value={yukMiktarı} onChange={(e)=>setYukMiktarı(e.target.value)} id="ContentPlaceHolder1_txtYukMiktari" class="form-control" required=""/>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-sm-2 col-xs-12 form-control-label">Yük Birimi</label>
                                    <div class="col-sm-4 col-xs-12">
                                        <select  onChange={e=>setYukBirimi(e.target.value)} name="ctl00$ContentPlaceHolder1$ddlBosaltmaIlce" id="ContentPlaceHolder1_ddlBosaltmaIlce" class="form-control">
                                        <option defaultValue={yukBirimi}>{yukBirimi}</option>
        <option value="AD">Adet</option>
        <option value="PK">Paket/Koli</option>
        <option value="KG">Kilogram</option>

        <option value="TON">TON</option>
     
    
    </select>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-sm-2 col-xs-12 form-control-label">Taşıma Bedeli</label>
                                    <div class="col-sm-8 col-xs-12">
                                        <input name="ctl00$ContentPlaceHolder1$txtTasimaBedeli" value={tasimaBedeli} onChange={(e)=>setTasimaBedeli(e.target.value)} type="text" id="ContentPlaceHolder1_txtTasimaBedeli" class="form-control" required=""/>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-sm-2 form-control-label"></label>
                                <div class="col-sm-9">
                                    <input onClick={Kaydet} type="submit" name="ctl00$ContentPlaceHolder1$btnKaydet" value="Kaydet" id="ContentPlaceHolder1_btnKaydet" class="btn btn-primary"/>
                                </div>
                            </div>
                        
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</div>)
 



}
export default UetdsDuzenle;