import React,{useEffect, useState,useRef} from "react";
import Defaultcss from "./css/Default.css"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import iller from "./iller";
import ReactPaginate from "react-paginate";
import { Link ,Outlet,useLocation} from "react-router-dom";
import { Button } from "@mui/material";
import Popup from 'reactjs-popup';

const Araclar = () =>{
    const [sayfaSayisi,setSayfaSayisi] = useState(1);



const [eyurtNumber,setEyurtNumber] = useState("");
const [uyelerLoading,setUyelerLoading] = useState(true);
const [plaka,setPlaka]  = useState("");
const [duzenlenecek,setDuzenlenecek] = useState();
const [editedPhoneNumber,setEditedPhoneNumber] = useState()
const [currentPage,setCurrentPage] = useState(1);
const [toplamSayfa,setToplamSayfa] = useState();
const [aktifPasif,setAktifPasif] = useState("");
const [updateLoading,setUpdateLoading] = useState(false);
const [eyurtTasitId,setEyurtTasitId] = useState("");
const [araclar,setAraclar] = useState([]);
const [duzenlencekAktifPasif,setDuzenlenecekAktifPasif] = useState();
const [eyurtMusteriId,setEyurtMusteriId] = useState();
const [duzenlenecekTruckType,setDuzenlenecekTruckType] = useState();
const [excelLoading,setExcelLoading] = useState(false);
const token = localStorage.getItem("token");
const [yetkiBelgesiPopup,setYetkiBelgesiPopup] = useState();
const [muayeneGoruntulePopup,setMuayeneGoruntulePopup] = useState();
const [belgeAktiflikMessage,setBelgeAktiflikMessage] =  useState();
const [muayeneMesajı,setMuayeneMesajı] = useState();
const closeYetkiBelgesiPopup = () => setYetkiBelgesiPopup(false);
const closeMuayenePopup = () => setMuayeneGoruntulePopup(false);
const [cezaSorgulaPopup,setCezaSorgulaPopup] = useState();
const [cezaMesajı,setCezaMesajı] = useState("");
const [cezaListesi,setCezaListesi] = useState();
const closeCezaSorgulaPopup = () => setCezaSorgulaPopup(false);
const [topluCezaPopup,setTopluCezaPopup] = useState();
const closeTopluCezaPopup = () => setTopluCezaPopup(false);
const [cezaSorguAralığı,setcezaSorguAralığı] = useState();
const [topluCezaAracTipi,setTopluCezaAracTipi]  = useState();
const [topluCezaLoading,setTopluCezaLoading] =useState(false); 
const [topluCezaKapatPopup,setTopluCezaKapatPopup] = useState();
const [topluMuayenePopup,setTopluMuayenePopup] = useState();
const [muayenePlaka,setMuayenePlaka] = useState();
const [cezaSorgulaDisabledButtton,setCezaSorgulaDisabledButton] = useState(false);
const [duzenlenecekPlaka,setDuzenlenecekPlaka] = useState();
const [duzenlenecekEyurtMusteriId,setDuzenlenecekEyurtMusteriId] = useState();
const [duzenlenecekTasitId,setDuzenlenecekTasitId] = useState();
const [updateHataErrorList,setUpdateHataErrorList] = useState([]);
console.log(cezaSorguAralığı);
console.log(topluCezaAracTipi);

const topluCezaSorgula = () =>{

if(topluCezaAracTipi=="Çekici"){
  setTopluCezaLoading(true);
  fetch(`https://apipartner.yuksis.com/api/TruckTrailer/KamyonVeyaCekiciCezalarınıGetirExcel`,{
    method: "Get",
    withCredentials:true,
    
    headers: {
      "content-type": "application/json",
      "Authorization": `Bearer ${token}`
  }}).then(response=>response.json()).then(response=>{setTopluCezaLoading(false);
    const link = document.createElement('a');
    link.href = `https://apipartner.yuksis.com/Images/CekiciVeyaKamyonCezaListesi.xlsx`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  
  
  
  }).catch(err=>console.log(err));
}
else if(topluCezaAracTipi=="Dorse"){
setTopluCezaLoading(true);
fetch(`https://apipartner.yuksis.com/api/TruckTrailer/DorseCezalarınıGetirExcel`,{
  method: "Get",
  withCredentials:true,
  
  headers: {
    "content-type": "application/json",
    "Authorization": `Bearer ${token}`
}}).then(response=>response.json()).then(response=>{setTopluCezaLoading(false);
  const link = document.createElement('a');
  link.href = `https://apipartner.yuksis.com/Images/DorseCezaListesi.xlsx`;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);



}).catch(err=>console.log(err));

}}
const topluMuayeneSorgula = () =>{

  if(topluCezaAracTipi=="Çekici"){
    setTopluCezaLoading(true);
    fetch(`https://apipartner.yuksis.com/api/TruckTrailer/KamyonVeyaCekiciMuayeneGetirExcel`,{
      method: "Get",
      withCredentials:true,
      
      headers: {
        "content-type": "application/json",
        "Authorization": `Bearer ${token}`
    }}).then(response=>response.json()).then(response=>{setTopluCezaLoading(false);
      const link = document.createElement('a');
      link.href = `https://apipartner.yuksis.com/Images/CekiciKamyonTopluMuayene.xlsx`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    
    
    
    }).catch(err=>console.log(err));
  }
  else if(topluCezaAracTipi=="Dorse"){
  setTopluCezaLoading(true);
  fetch(`https://apipartner.yuksis.com/api/TruckTrailer/DorseMuayeneleriniGetirExcel`,{
    method: "Get",
    withCredentials:true,
    
    headers: {
      "content-type": "application/json",
      "Authorization": `Bearer ${token}`
  }}).then(response=>response.json()).then(response=>{setTopluCezaLoading(false);
    const link = document.createElement('a');
    link.href = `https://apipartner.yuksis.com/Images/DorseTopluMuayene.xlsx`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  
  
  
  }).catch(err=>console.log(err));
  
  }}
  


const cezaSorgula  = (plaka) =>{
  setCezaSorgulaDisabledButton(true);
  fetch(`https://apipartner.yuksis.com/api/TruckTrailer/CezaSorgula?Plaka=${plaka}`,{
    method: "Get",
    withCredentials:true,
    
    headers: {
      "content-type": "application/json",
      "Authorization": `Bearer ${token}`
  }}).then(response=>response.json()).then(response=>{ console.log(response); if(response.message==null){
    setCezaListesi(response.data.$values);
    setCezaMesajı();
    setCezaSorgulaDisabledButton(false)
  }
  else 
{setCezaMesajı(response.message);setCezaListesi()}}).then(response=>{setCezaSorgulaPopup((o)=>!o);setCezaSorgulaDisabledButton(false)}).catch(err=>{console.log(err);setCezaSorgulaDisabledButton(false)});
}


const yetkiBelgesiSorgula = (plaka) =>{
  fetch(`https://apipartner.yuksis.com/api/TruckTrailer/AracYetkiBelgesiSorgula?Plaka=${plaka}`,{
    method: "Get",
    withCredentials:true,
    
    headers: {
      "content-type": "application/json",
      "Authorization": `Bearer ${token}`
  }}).then(response=>response.json()).then(response=>{console.log(response);setBelgeAktiflikMessage(response.data);}).then(response=>setYetkiBelgesiPopup((o)=>!o)).catch(err=>console.log(err));
}
const muayeneSorgula = (plaka) =>{
  fetch(`https://apipartner.yuksis.com/api/TruckTrailer/AracMuayeneSorgula?Plaka=${plaka}`,{
    method: "Get",
    withCredentials:true,
    
    headers: {
      "content-type": "application/json",
      "Authorization": `Bearer ${token}`
  }}).then(response=>response.json()).then(response=>{console.log(response);if(response.statusCode==200){setMuayeneMesajı(response.message)}else{
    setMuayeneMesajı("Sorguda Bir Hata Meydana Geldi.")
  }}).then(response=>setMuayeneGoruntulePopup((o)=>!o)).catch(err=>console.log(err));
}



const filtreleriTemizle = () =>{


  setUyelerLoading(true);
  fetch(`https://apipartner.yuksis.com/api/TruckTrailer/GetAllTruckAndTrailer?currentPage=${currentPage}`,{
      method: "Get",
      withCredentials:true,
      
      headers: {
        "content-type": "application/json",
        "Authorization": `Bearer ${token}`
    }})
  .then(response=>response.json()).then(response=>{setUyelerLoading(false);setAraclar(response.data.clientModelClass.$values);setToplamSayfa(response.data.count);console.log(response)}).catch(err=>{console.log(err);setUyelerLoading(false)});


    setEyurtNumber("");
    setEyurtTasitId("");
    setPlaka("");
    setAktifPasif("");




  }
  console.log(duzenlenecek+"duzenlence");
  const  GetExcelTruckAndTrailer = () =>{
    setExcelLoading(true);
      fetch(`https://apipartner.yuksis.com/api/TruckTrailer/GetTruckAndTrailerToExcel`,{
        method: "Get",
        withCredentials:true,
        
        headers: {
          "content-type": "application/json",
          "Authorization": `Bearer ${token}`
      }}).then(response=>response.json()).then(response=>{console.log(response);setExcelLoading(false);
        const link = document.createElement('a');
        link.href = `https://apipartner.yuksis.com/Images/AraclarListe.xlsx`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }).catch(err=>console.log(err));
    }
  
const aramaYap = () =>{
  setUyelerLoading(true);
  fetch(`https://apipartner.yuksis.com/api/TruckTrailer/GetAllTruckAndTrailer?currentPage=${currentPage}&&plaka=${plaka}&&eyurtNumber=${eyurtNumber}&&eyurtTasitId=${eyurtTasitId}&&aktifPasif=${aktifPasif}`,{
      method: "Get",
      withCredentials:true,
      
      headers: {
        "content-type": "application/json",
        "Authorization": `Bearer ${token}`
    }})
  .then(response=>response.json()).then(response=>{setAraclar(response.data.clientModelClass.$values);setToplamSayfa(response.data.count);console.log(response);setCurrentPage(1)}).then(response=>setUyelerLoading(false)).catch(err=>{console.log(err);setUyelerLoading(false)});
}
useEffect(()=>{
    setUyelerLoading(true);
    fetch(`https://apipartner.yuksis.com/api/TruckTrailer/GetAllTruckAndTrailer?currentPage=${currentPage}&&plaka=${plaka}&&eyurtNumber=${eyurtNumber}&&eyurtTasitId=${eyurtTasitId}&&aktifPasif=${aktifPasif}`,{
        method: "Get",
        withCredentials:true,
        
        headers: {
          "content-type": "application/json",
          "Authorization": `Bearer ${token}`
      }})
    .then(response=>response.json()).then(response=>{setUyelerLoading(false);setAraclar(response.data.clientModelClass.$values);setToplamSayfa(response.data.count);console.log(response)}).catch(err=>{console.log(err);setUyelerLoading(false)});
},[currentPage,aktifPasif]) 
const UpdateAraclar = () =>{
  setUpdateLoading(true);
  fetch(`https://apipartner.yuksis.com/api/TruckTrailer/UpdateTasit`,{method:"Post",
  withCredentials:true,
  body: JSON.stringify({aktifPasif:duzenlencekAktifPasif,truckType:duzenlenecekTruckType,id:parseInt(duzenlenecek),eyurtMusteriId:duzenlenecekEyurtMusteriId,eYurtTasitId:duzenlenecekTasitId,plaka:duzenlenecekPlaka}),
  headers:{
    "content-type": "application/json",
    "Authorization": `Bearer ${token}`
  }}).then(response=>response.json()).then(response=>{if(response.statusCode==200){
    setDuzenlenecek("");
    setUpdateLoading(false);
    setUpdateHataErrorList(response.data.$values);
    console.log(response);
  }}).catch(err=>{console.log(err);setUpdateLoading(false)});
}
console.log(toplamSayfa);
return (
    <div className="row" >
    <div className="col-lg-12 tab-list">
  
  
        <ul className="nav nav-tabs" role="tablist">
            <li role="presentation" className="active"><a href="#" aria-controls="home" role="tab" data-toggle="tab" style={{color:"#555"}}>Araçlar</a></li>
        </ul>
      
            <div   id="collapseExample">
            <ul style={{marginBottom:20}} class="nav justify-content-center">
            <li class="nav-item">

  </li>  

  
 
  
    

  <li class="nav-item">
 
   
  </li>

  <li class="nav-item" style={{marginLeft:100}}>

  </li>
  

</ul></div>
       
     <div style={{margin:20}} className="tab-content">
 

            <div role="tabpanel" className="tab-pane active" id="home" style={{overflow:"scroll"}}>  
            {updateHataErrorList.length>0 ? updateHataErrorList.map(x=>{
                    return (<div style={{width:1500}} class="alert alert-danger" role="alert">
{x}
</div>)

                   }) : ""}
                <table className="table table-responsive table-striped">
                
                    <tbody>
                  
                   
             
                    <tr> 
             
                    <th>  <input onKeyDown={(e) => {
    if (e.code === "Enter" || e.code=="NumpadEnter") {
      aramaYap();
    }}} class="form-control" value={eyurtTasitId} onChange={e=>{setEyurtTasitId(e.target.value)}} placeholder="E-Yurt Taşıt Id"/></th>
                    <th>  <input onKeyDown={(e) => {
    if (e.code === "Enter" || e.code=="NumpadEnter") {
      aramaYap();
    }}} class="form-control" value={eyurtNumber} onChange={e=>{setEyurtNumber(e.target.value)}} placeholder="E-Yurt Müşteri Id"/></th>
                  <th><input onKeyDown={(e) => {
    if (e.code === "Enter" || e.code=="NumpadEnter") {
      aramaYap();
    }}} class="form-control" value={plaka} onChange={e=>{setPlaka(e.target.value)}} placeholder="Plaka"/></th>
                  <th><select onChange={(e)=>setAktifPasif(e.target.value)} value={aktifPasif} aria-label="Default select example" className="form-select" type={""} ><option value={"aktif"}>Aktif</option><option value={"pasif"}>Pasif</option><option value={""}>Tümü</option></select></th>
                  <th><button onClick={()=>aramaYap()} style={{backgroundColor:"#e5e619"}} type="button" className="btn">Filtrele<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-funnel-fill" viewBox="0 0 16 16">
  <path d="M1.5 1.5A.5.5 0 0 1 2 1h12a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.128.334L10 8.692V13.5a.5.5 0 0 1-.342.474l-3 1A.5.5 0 0 1 6 14.5V8.692L1.628 3.834A.5.5 0 0 1 1.5 3.5v-2z"/>
</svg></button></th>
         <th> <button onClick={()=>filtreleriTemizle()} style={{backgroundColor:"#ef233c" ,boxShadow: '3px 3px 9px #F4AAB9'}} type="button" className="btn btn-danger">Filtreleri Temizle</button></th>

         <th>

         {excelLoading==true ? <button style={{marginBottom:10,marginLeft:10    ,boxShadow: '3px 3px 9px #F4AAB9'}} class="btn btn-primary" type="button" disabled>
  <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
 İndiriliyor...
</button> :  <button type="button"  onClick={()=>GetExcelTruckAndTrailer()} class="btn btn-success" style={{marginBottom:10,marginLeft:10    ,boxShadow: '3px 3px 9px #F4AAB9'}}>Excel Çıktısı Al</button>}  


         </th> 
        
         <th><button onClick={()=>setTopluMuayenePopup((o)=>!o)} type="button" style={{backgroundColor:"#0F3460",color:"white" ,boxShadow: '3px 3px 9px #F4AAB9'}} className="btn ">Toplu Muayene Sorgula</button></th> 
         <th><button onClick={()=>setTopluCezaPopup((o)=>!o)} style={{boxShadow: '3px 3px 9px #F4AAB9'}} type="button" className="btn btn-danger">Toplu Ceza Sorgula</button></th> 
                
                  </tr>

              
                        <tr>
                       
                       
                     
                            <th  style={{backgroundColor:"#7FBCD2",width:120,color:"white"}}>E-Yurt Taşıt Id</th>
                            <th style={{backgroundColor:"#7FBCD2",width:120,color:"white"}}>E-Yurt Müşteri Id</th>    <th style={{backgroundColor:"#7FBCD2",width:125,color:"white"}}>Plaka</th>
                          <th style={{backgroundColor:"#7FBCD2",width:120,color:"white",color:"white",justifyContent:"center",alignItems:"center",alignSelf:"center"}}><p style={{alignSelf:"center"}}>Belge</p></th>
                        
                           <th style={{backgroundColor:"#7FBCD2",width:100,color:"white",color:"white"}}>Tür</th>

                            <th style={{backgroundColor:"#7FBCD2",width:100,color:"white"}}>Düzenle</th>
                            <th style={{backgroundColor:"#7FBCD2",width:100,color:"white"}}>Yetki Belgesi Sorgula</th>
                            <th style={{backgroundColor:"#7FBCD2",width:100,color:"white"}}>Muayene Sorgula</th>
                            <th style={{backgroundColor:"#7FBCD2",width:100,color:"white"}}>Ceza Sorgula</th>
                       
                      
                      
                        </tr>
                    
{ araclar.length!=0 ? araclar.map(u=>{
    
    return(
        
        
      uyelerLoading ? <div class="spinner-border" role="status">
  <span class="sr-only">Loading...</span>
  
</div>:
        
     
       
  
             
       
   <tr>
   
   {u?.id==duzenlenecek ?  <td  ><input placeholder={u?.eyurtTasitId} className="form-control" value={duzenlenecekTasitId} onChange={(e)=>setDuzenlenecekTasitId(e.target.value)}></input></td> :  <td  ><a href={`http://e-yurt.yazilimci.info/center/tasitdetay.aspx?id=${u?.eyurtTasitId}`} style={{color:"black",textDecoration:"underline",color:"blue"}} target="_blank">{u?.eyurtTasitId}</a></td> }
          
          
          
         {u?.id==duzenlenecek ?<td><input placeholder={u?.eyurtMusteriId} className="form-control" value={duzenlenecekEyurtMusteriId} onChange={(e)=>setDuzenlenecekEyurtMusteriId(e.target.value)}></input></td>  :  <td ><a href={`http://e-yurt.yazilimci.info/center/musteridetay.aspx?id=${u?.eyurtMusteriId}`} style={{color:"black",textDecoration:"underline",color:"blue"}} target="_blank" >{u?.eyurtMusteriId}</a></td>  } 
         {u?.id==duzenlenecek ?  <td><input placeholder={u?.plaka} className="form-control" value={duzenlenecekPlaka} onChange={(e)=>setDuzenlenecekPlaka(e.target.value)}></input></td> : <td>{u?.plaka}</td>} 
          
  {u?.id==duzenlenecek ? <td><select onChange={(e)=>setDuzenlenecekAktifPasif(e.target.value)} value={duzenlencekAktifPasif} aria-label="Default select example" className="form-select" type={""} ><option value="" disabled selected>Lütfen Bir Seçenek Seçiniz</option><option value={"aktif"}>Aktif</option><option value={"pasif"}>Pasif</option></select></td> : 
  <td>{u?.isActive ? <div><svg color="green" xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-shield-fill-check" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M8 0c-.69 0-1.843.265-2.928.56-1.11.3-2.229.655-2.887.87a1.54 1.54 0 0 0-1.044 1.262c-.596 4.477.787 7.795 2.465 9.99a11.777 11.777 0 0 0 2.517 2.453c.386.273.744.482 1.048.625.28.132.581.24.829.24s.548-.108.829-.24a7.159 7.159 0 0 0 1.048-.625 11.775 11.775 0 0 0 2.517-2.453c1.678-2.195 3.061-5.513 2.465-9.99a1.541 1.541 0 0 0-1.044-1.263 62.467 62.467 0 0 0-2.887-.87C9.843.266 8.69 0 8 0zm2.146 5.146a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 7.793l2.646-2.647z"/>
</svg></div>  : <div><svg color="red" xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-shield-fill-x" viewBox="0 0 16 16">
  <path d="M8 0c-.69 0-1.843.265-2.928.56-1.11.3-2.229.655-2.887.87a1.54 1.54 0 0 0-1.044 1.262c-.596 4.477.787 7.795 2.465 9.99a11.777 11.777 0 0 0 2.517 2.453c.386.273.744.482 1.048.625.28.132.581.24.829.24s.548-.108.829-.24a7.159 7.159 0 0 0 1.048-.625 11.775 11.775 0 0 0 2.517-2.453c1.678-2.195 3.061-5.513 2.465-9.99a1.541 1.541 0 0 0-1.044-1.263 62.467 62.467 0 0 0-2.887-.87C9.843.266 8.69 0 8 0zM6.854 5.146 8 6.293l1.146-1.147a.5.5 0 1 1 .708.708L8.707 7l1.147 1.146a.5.5 0 0 1-.708.708L8 7.707 6.854 8.854a.5.5 0 1 1-.708-.708L7.293 7 6.146 5.854a.5.5 0 1 1 .708-.708z"/>
</svg></div>}</td>
  
  } 
        
           <td >{u?.truckType} </td>


           {u?.id==duzenlenecek ? <td><button type="button" class="btn btn-success" onClick={()=>UpdateAraclar()}>Kaydet</button><button type="button" class="btn btn-danger" onClick={()=>{setDuzenlenecek("");setDuzenlenecekAktifPasif("");setDuzenlenecekEyurtMusteriId();setDuzenlenecekPlaka();setEyurtTasitId()}} style={{marginLeft:5}}>İptal</button></td> :
           <td><button onClick={()=>{setDuzenlenecek(u?.id);setDuzenlenecekTruckType(u?.truckType);setDuzenlenecekEyurtMusteriId(u?.eyurtMusteriId);setDuzenlenecekTasitId(u?.eyurtTasitId);setDuzenlenecekPlaka(u?.plaka)}} type="button" class="btn btn-secondary">Düzenle</button></td> }
       
           <td><button onClick={()=>yetkiBelgesiSorgula(u?.plaka)} type="button" class="btn btn-info">Yetki Belgesi Sorgula</button></td>
           <td><button onClick={()=>{muayeneSorgula(u?.plaka);setMuayenePlaka(u?.plaka)}} type="button" class="btn btn-warning">Muayene Sorgula</button></td>
           <td ><button disabled={cezaSorgulaDisabledButtton} style={{backgroundColor:"#A62349"}} onClick={()=>cezaSorgula(u?.plaka)} type="button" class="btn btn-warning">Ceza Sorgula</button></td>
       </tr>);


   })  : <h4 style={{alignSelf:"center"}}>Sonuç Bulunamadı.</h4>  }
                       
                   
               
            
                                  
                    </tbody>
                   
                </table>    
                               
                <div className="clearfix"></div>
               
            </div>
            <nav style={{marginTop:20}} aria-label="Page navigation example">
  <ul class="pagination justify-content-center">
  <Popup open={yetkiBelgesiPopup} closeOnDocumentClick onClose={closeYetkiBelgesiPopup}  contentStyle={{borderRadius:7,backgroundColor:"#6FEDD6",borderWidth:0}} >

  <div class="card"  >
  <div class="card-header">
    Yetki Belgesi Sorgu
  </div>

  {belgeAktiflikMessage!=null  ?
    <div class="card-body">
    <h5 class="card-title">Firma : {belgeAktiflikMessage?.firmaUnvan}</h5>
    <p class="card-text">Belge Türü : {belgeAktiflikMessage?.belgeTuru}</p>
    <p class="card-text">Geçerlilik Tarihi:{belgeAktiflikMessage?.belgeGecerlilikTarihi}</p>
  
  </div> :   <div class="card-body">
    <p>Veri Bulunamadı</p>
  
  </div> }

</div>

   
  
 
  </Popup>
  <Popup open={muayeneGoruntulePopup} closeOnDocumentClick onClose={closeMuayenePopup} contentStyle={{borderRadius:7,backgroundColor:"#6FEDD6",borderWidth:0}}  >

<div class="card"  >
<div class="card-header">
Muayene Bilgisi
</div>
{muayeneMesajı?.charAt(0)=="M" ? <p>{ muayeneMesajı}</p> : <p> {muayenePlaka} Muayene Geçerlilik Tarihi : {muayeneMesajı?.substring(0)}</p> }

 





</div>

 


</Popup>
 <Popup open={cezaSorgulaPopup} closeOnDocumentClick onClose={closeCezaSorgulaPopup} contentStyle={{borderRadius:7,backgroundColor:"#6FEDD6",borderWidth:0}}  >

<div class="card"  >
<div class="card-header">
Ceza Bilgisi
</div>
 {cezaMesajı!=null  ?  <p>{cezaMesajı}</p> : cezaListesi.map(x=>{
  return (
  <div class="card-body">
    <h5 class="card-title">Ceza Gerekçe : {x?.cezaGerekce}</h5>
    <p class="card-text">Ceza Giren Bölge : {x?.cezaGirenBolge}</p>
    <p class="card-text">Ceza Kesen Kurum:{x?.cezaKesenKurum}</p>
    <p class="card-text">Ceza Tutarı:{x?.cezaTutari} Tl</p>
    <p class="card-text">Ceza Kesilen Kurum:{x?.firmaUnvan}</p>
    <p class="card-text">İstasyon Adı:{x?.istasyonAdi}</p>
    <p class="card-text">Plaka:{x?.plaka}</p>
    <p class="card-text">Veriliş Saati Tarihi:{x?.verilisSaati} {x?.verilisTarihi} </p>
   
    



  
  </div> )
 })} 

 





</div>

 


</Popup>
  <Popup open={topluCezaPopup} closeOnDocumentClick={false}   contentStyle={{borderRadius:7,backgroundColor:"#E7F6F2",borderWidth:0,padding:40}}  >
  <div style={{textAlign:"end"}}><button onClick={()=>setTopluCezaKapatPopup(true)} type="button" class="btn btn-danger"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-circle" viewBox="0 0 16 16">
  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
</svg> Kapat</button></div>
  <form>

  <div class="form-group">
    <label for="formGroupExampleInput2">Araç Türü Seçiniz</label>
    <select onChange={(e)=>setTopluCezaAracTipi(e.target.value)} defaultValue={"Default"} class="form-control form-control-lg">
    <option  disabled value="Default">Lütfen Araç Tipini Seçiniz </option>
  <option value={"Çekici"}>Çekici veya Kamyon </option>
  <option value={"Dorse"}>Dorse</option>

  

</select>
  </div>
</form>
 
 
 
 {topluCezaLoading==false ?   <button  onClick={()=>topluCezaSorgula()} type="button" class="btn btn-success">Sorgula</button>:<button class="btn btn-primary" type="button" disabled>
  <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
Sorgulama Yapılıyor. Lütfen Bekleyiniz. Sorgu Sonunda Cezalar Excel Formatında İndirilecektir.
</button>  }



 


</Popup>

      <Popup  open={topluCezaKapatPopup} closeOnDocumentClick={false} 
contentStyle={{borderRadius:10,width:800,alignItems:"center",alignContent:"center",justifyContent:"center"}}
        ><h6 style={{alignSelf:"center",textAlign:"center"}}>Sorgudan Çıkmak İstediğinize Emin Misiniz?</h6>   <div style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
     
<button type="button" class="btn btn-info" onClick={()=>setTopluCezaKapatPopup(false)}>Sorguya Devam Et</button>
<button style={{marginLeft:10}}  type="button" class="btn btn-warning" onClick={()=>{setTopluCezaPopup(false);setTopluCezaKapatPopup(false)}}>Sorgudan Çık</button></div>
</Popup>


<Popup open={topluMuayenePopup} closeOnDocumentClick={true} onClose={()=>setTopluMuayenePopup(false)}  contentStyle={{borderRadius:7,backgroundColor:"#E7F6F2",borderWidth:0,padding:40}}  >

  <form>
 
  <div class="form-group">
    <label for="formGroupExampleInput2">Araç Türü Seçiniz</label>
    <select onChange={(e)=>setTopluCezaAracTipi(e.target.value)} defaultValue={"Default"} class="form-control form-control-lg">
    <option  disabled value="Default">Lütfen Araç Tipini Seçiniz </option>
  <option value={"Çekici"}>Çekici veya Kamyon </option>
  <option value={"Dorse"}>Dorse</option>

  

</select>
  </div>
</form>
 
 
 
 {topluCezaLoading==false ?   <button  onClick={()=>{topluMuayeneSorgula()}} type="button" class="btn btn-success">Sorgula</button>:<button class="btn btn-primary" type="button" disabled>
  <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
Sorgulama Yapılıyor. Lütfen Bekleyiniz. Sorgu Sonunda Muayeneler Excel Formatında İndirilecektir.
</button>  }



 


</Popup>


  <ReactPaginate
         pageCount={toplamSayfa/10}
         containerClassName={"pagination"}
            pageClassName={"page-item"}
            pageLinkClassName={"page-link"}
            activeClassName = {"active"} 
        
  

            onPageChange={(data)=>{setCurrentPage(data.selected+1)}}
            breakLabel={"..."}
         
  
            previousLabel={<h2></h2>}
            nextLabel={<h3></h3>}
          />   
  
 
 
  </ul>
</nav>     
       </div>  
   
   
         </div></div>
)
}
export default Araclar;