


const kasatipler  =[

 {
     truck_type_id: 1 , trailer_type:"Açık" , trailer_type_id: 1 
 },
 {
    truck_type_id: 1 , trailer_type:"Tenteli" , trailer_type_id: 2 
},
{
    truck_type_id: 1 , trailer_type:"Sal Kasa" , trailer_type_id: 3
},
{
    truck_type_id: 1 , trailer_type:"Soğutuculu (-18/+18)" , trailer_type_id: 4
},

{
    truck_type_id: 3 , trailer_type:"Açık" , trailer_type_id: 5
},
{
    truck_type_id: 3 , trailer_type:"Tenteli" , trailer_type_id: 6
},
{
    truck_type_id: 3 , trailer_type:"Soğutuculu (-18/+18)" , trailer_type_id: 7
},
{
    truck_type_id: 4 , trailer_type:"Açık" , trailer_type_id: 8
},
{
    truck_type_id: 4 , trailer_type:"Tenteli" , trailer_type_id: 9
},
{
    truck_type_id: 4 , trailer_type:"Soğutuculu (-18/+18)" , trailer_type_id: 10
},
{
    truck_type_id: 5 , trailer_type:"Açık" , trailer_type_id: 11
},
{
    truck_type_id: 5 , trailer_type:"Tenteli" , trailer_type_id: 12
},
{
    truck_type_id: 5 , trailer_type:"Soğutuculu (-18/+18)" , trailer_type_id: 13
},






];

export default kasatipler;