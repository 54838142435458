

import React, { useEffect,useState } from "react";
import { Link, useParams,useNavigate, Navigate,useLocation,useHref } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ProfilePage from "./css/ProfilePage.css"
import Popup from 'reactjs-popup';
import { useContext } from "react";
import { AuthContext } from "./Auth";
import 'reactjs-popup/dist/index.css';
const UserDetay = ()=>{

const {userName} = useParams();  
console.log(userName);
const [user,setUser] = useState();
const [loading,setLoading] = useState();
const [openEhliyet,setOpenEhliyet] =useState();
const [openSrc,setOpenSrc] = useState();
const closeModalEhliyet = () => setOpenEhliyet(false);
const closeModalSrc = () => setOpenSrc(false);
   const token = localStorage.getItem("token");
useEffect(()=>
    {
      setLoading(true)
        fetch(`https://apipartner.yuksis.com/api/auth/GetUserByPhoneNumber?phoneNumber=${userName}`,{
            method: "GET",  
            withCredentials:true,
       
            headers: {
              "content-type": "application/json",
              "Authorization": `Bearer ${token}`
        }}).then(response=>response.json()).then(response=>{
      
            setUser(response.data);setLoading(false);
            
            console.log(response)
    
    
    
        
    
    
    
    }).catch(error=>console.log(error))},[]);
    useEffect(()=>
    {
      setLoading(true)
        fetch(`https://apipartner.yuksis.com/api/TruckTrailer/GetTrailerAndTruckByUserName?phoneNumber=${userName}`,{
            method: "GET",  
            withCredentials:true,
       
            headers: {
              "content-type": "application/json",
              "Authorization": `Bearer ${token}`
        }}).then(response=>response.json()).then(response=>{
      
            console.log(response);setLoading(false);
            
            console.log(response)
    
    
    
        
    
    
    
    }).catch(error=>console.log(error))},[]);
    const {userType} = useContext(AuthContext);
 
    return(
<section class="section about-section gray-bg" id="about"  >
            <div class="container" style={{paddingTop:100,marginBottom:300}}>
                <div class="row align-items-center flex-row-reverse" style={{backgroundColor:"white",borderRadius:50}}>
                    <div class="col-lg-6">
                        <div class="about-text go-to">
                    <h4 class="dark-color"><h4 style={{display:"inline",fontWeight:"bold"}}>İsim Soyisim</h4> : {user?.name} {user?.surname}</h4>
                            <div className="container">
                                <div className="row">  
                                <div className="col">
                                <h5>Ehliyet</h5>
                        <button type="button" className="button" onClick={() => setOpenEhliyet(o => !o)}>  <img height={100}  src={user?.ehliyet!=null ? `https://apipartner.yuksis.com/Images/${user?.ehliyet}` : "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAOAAAADgCAMAAAAt85rTAAAAUVBMVEXS0tLy8vL///+AgIDz8/P7+/vu7u7n5+fk5OTU1NR9fX3Y2NiMjIyioqKCgoJ7e3udnZ3d3d2xsbF1dXWRkZGrq6vGxsa9vb24uLiXl5fMzMyth4PaAAADJElEQVR4nO3d0XKjIBSA4bhoEtFgE5Nm2/d/0EWaq94YFOec4/7/E/AVBMw408OfnXeQHsDW/RfAeq9VzQ+w2m0vYC09js0CaD2A1gNoPYDWA2i9XOCx+AiO9aZ/3SxgXbXF69rHcUth5gweytd+9FsK82bwuAGwuwz9qd5socoD24v34dxsw1MCdD6cqh3PoAs/wk2IGoDeuRBXab2JUAkwCv11z8AQjcO53uBBVAJ0aRKvzZ6Bflql5U8LPcDgXRjb4lOoB5iQQ1d6p9EFjDtNaaEqYAghCss+h6qAaa8ZPpuq4HGhDhj8cGh2DIx3Nj98F1yl2oDxThOfw4JCdcC0lQ7PptSvPxqBPrjxs9StTSMw7TTPQkKdwHgiTqdFCaJSYFylw63IHCoFpufwb4l3fK3AtEpv9fpfTPUCY+Nz/XmoFzjdvMfH6kWqF5iQ423tHOoGxneLy0qhbuB0WjzWnRa6gVPDZdVpoR/o/KpVqh8YV+nXilWqHzj91nZZLrQAjM/hx+LH0AYwrtJ9A30Y7gtfnmwAJ+PHsou3EWDwfvhaJDQCjDPo/H3JpxhmgNP7YX/M/6nNDnAS3vNXqR1g8CH4PnuV2gG6dGvrj5nHhSlg2mpOWT5rwFh/yppCa8B4ILosoTVg+uzrnLHTWAP66WuM6ZOhvQJf0/j+J0MWgcFlzKFJYDzx3fVNoUVgmsOxa966t9kEps30vQ9qrAJ98ONbn+7JA7tFwHTkt838gSgPXDaDqfF7ftziwPbw6JfXWZjBtltcO38cygNXdZ4dM0CAogEECFA2gAABygYQIEDZAAIEKBtAgABlAwgQoGwAAQKUDSBAgLIBBAhQNoAAAcoGECBA2QACBCgbQIAAZQMIEKBsAAEClA0gQICyAQQIUDaAAAHKBhAgQNkAAgQoG0CAAGUDCBCgbAABApQNIECAsgEECFA2gAABygYQIEDZAAIEKBtAgABlAwgQoGwAAQKUrTCwqqRBvys9g/qAswPPBJ7OJ1XN/6/hzCVaK2t+xJlAewG0HkDrAbQeQOsBtN4LKD2M7XoBm/32A9x1uwf+A5RFr+nQX9z0AAAAAElFTkSuQmCC"} /></button> 
                                </div>
                                <div className="col">
                                <h5>Src Belgesi</h5>
                          <button type="button" className="button" onClick={() => setOpenSrc(o => !o)}>    <img height={100} src={`https://apipartner.yuksis.com/Images/${user?.srcBelgesi}`}/></button>  </div>
                                </div>
                               
                                
                            </div>
                       
                            <div class="row about-list">
                                <div class="col-md-9">
                                  
                                <div class="media">
                                        <label>Telefon Numarası</label>
                                        <p>{user?.phoneNumber}</p>
                                    </div>
                                 
                                  
                                </div>
                                <div class="col-md-3">
                                  
                                  
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="about-avatar">
                            <img src={`https://apipartner.yuksis.com/Images/${user?.profilePhoto}`} width={250} height={250} style={{borderRadius:50}} title="" alt=""/>
                        </div>
                    </div>
                    <Popup contentStyle={{borderRadius:10}} open={openEhliyet} closeOnDocumentClick onClose={closeModalEhliyet}>
      
          <a style={{display:"inline"}} className="close" onClick={closeModalEhliyet}>
            &times;
          </a>
         <img  alt="" height={500} width={900} style={{borderRadius:20}} src={user?.ehliyet!=null ? `https://apipartner.yuksis.com/Images/${user?.ehliyet}` : "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAOAAAADgCAMAAAAt85rTAAAAUVBMVEXS0tLy8vL///+AgIDz8/P7+/vu7u7n5+fk5OTU1NR9fX3Y2NiMjIyioqKCgoJ7e3udnZ3d3d2xsbF1dXWRkZGrq6vGxsa9vb24uLiXl5fMzMyth4PaAAADJElEQVR4nO3d0XKjIBSA4bhoEtFgE5Nm2/d/0EWaq94YFOec4/7/E/AVBMw408OfnXeQHsDW/RfAeq9VzQ+w2m0vYC09js0CaD2A1gNoPYDWA2i9XOCx+AiO9aZ/3SxgXbXF69rHcUth5gweytd+9FsK82bwuAGwuwz9qd5socoD24v34dxsw1MCdD6cqh3PoAs/wk2IGoDeuRBXab2JUAkwCv11z8AQjcO53uBBVAJ0aRKvzZ6Bflql5U8LPcDgXRjb4lOoB5iQQ1d6p9EFjDtNaaEqYAghCss+h6qAaa8ZPpuq4HGhDhj8cGh2DIx3Nj98F1yl2oDxThOfw4JCdcC0lQ7PptSvPxqBPrjxs9StTSMw7TTPQkKdwHgiTqdFCaJSYFylw63IHCoFpufwb4l3fK3AtEpv9fpfTPUCY+Nz/XmoFzjdvMfH6kWqF5iQ423tHOoGxneLy0qhbuB0WjzWnRa6gVPDZdVpoR/o/KpVqh8YV+nXilWqHzj91nZZLrQAjM/hx+LH0AYwrtJ9A30Y7gtfnmwAJ+PHsou3EWDwfvhaJDQCjDPo/H3JpxhmgNP7YX/M/6nNDnAS3vNXqR1g8CH4PnuV2gG6dGvrj5nHhSlg2mpOWT5rwFh/yppCa8B4ILosoTVg+uzrnLHTWAP66WuM6ZOhvQJf0/j+J0MWgcFlzKFJYDzx3fVNoUVgmsOxa966t9kEps30vQ9qrAJ98ONbn+7JA7tFwHTkt838gSgPXDaDqfF7ftziwPbw6JfXWZjBtltcO38cygNXdZ4dM0CAogEECFA2gAABygYQIEDZAAIEKBtAgABlAwgQoGwAAQKUDSBAgLIBBAhQNoAAAcoGECBA2QACBCgbQIAAZQMIEKBsAAEClA0gQICyAQQIUDaAAAHKBhAgQNkAAgQoG0CAAGUDCBCgbAABApQNIECAsgEECFA2gAABygYQIEDZAAIEKBtAgABlAwgQoGwAAQKUrTCwqqRBvys9g/qAswPPBJ7OJ1XN/6/hzCVaK2t+xJlAewG0HkDrAbQeQOsBtN4LKD2M7XoBm/32A9x1uwf+A5RFr+nQX9z0AAAAAElFTkSuQmCC"}/>
      
      </Popup>
      <Popup contentStyle={{borderRadius:10}}c open={openSrc} closeOnDocumentClick onClose={closeModalSrc}>
      
      <a style={{display:"inline"}} className="close" onClick={closeModalSrc}>
        &times;
      </a>
     <img  alt="" height={500} width={900} style={{borderRadius:20}} src={user?.srcBelgesi!=null ? `https://apipartner.yuksis.com/Images/${user?.srcBelgesi}` : "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAOAAAADgCAMAAAAt85rTAAAAUVBMVEXS0tLy8vL///+AgIDz8/P7+/vu7u7n5+fk5OTU1NR9fX3Y2NiMjIyioqKCgoJ7e3udnZ3d3d2xsbF1dXWRkZGrq6vGxsa9vb24uLiXl5fMzMyth4PaAAADJElEQVR4nO3d0XKjIBSA4bhoEtFgE5Nm2/d/0EWaq94YFOec4/7/E/AVBMw408OfnXeQHsDW/RfAeq9VzQ+w2m0vYC09js0CaD2A1gNoPYDWA2i9XOCx+AiO9aZ/3SxgXbXF69rHcUth5gweytd+9FsK82bwuAGwuwz9qd5socoD24v34dxsw1MCdD6cqh3PoAs/wk2IGoDeuRBXab2JUAkwCv11z8AQjcO53uBBVAJ0aRKvzZ6Bflql5U8LPcDgXRjb4lOoB5iQQ1d6p9EFjDtNaaEqYAghCss+h6qAaa8ZPpuq4HGhDhj8cGh2DIx3Nj98F1yl2oDxThOfw4JCdcC0lQ7PptSvPxqBPrjxs9StTSMw7TTPQkKdwHgiTqdFCaJSYFylw63IHCoFpufwb4l3fK3AtEpv9fpfTPUCY+Nz/XmoFzjdvMfH6kWqF5iQ423tHOoGxneLy0qhbuB0WjzWnRa6gVPDZdVpoR/o/KpVqh8YV+nXilWqHzj91nZZLrQAjM/hx+LH0AYwrtJ9A30Y7gtfnmwAJ+PHsou3EWDwfvhaJDQCjDPo/H3JpxhmgNP7YX/M/6nNDnAS3vNXqR1g8CH4PnuV2gG6dGvrj5nHhSlg2mpOWT5rwFh/yppCa8B4ILosoTVg+uzrnLHTWAP66WuM6ZOhvQJf0/j+J0MWgcFlzKFJYDzx3fVNoUVgmsOxa966t9kEps30vQ9qrAJ98ONbn+7JA7tFwHTkt838gSgPXDaDqfF7ftziwPbw6JfXWZjBtltcO38cygNXdZ4dM0CAogEECFA2gAABygYQIEDZAAIEKBtAgABlAwgQoGwAAQKUDSBAgLIBBAhQNoAAAcoGECBA2QACBCgbQIAAZQMIEKBsAAEClA0gQICyAQQIUDaAAAHKBhAgQNkAAgQoG0CAAGUDCBCgbAABApQNIECAsgEECFA2gAABygYQIEDZAAIEKBtAgABlAwgQoGwAAQKUrTCwqqRBvys9g/qAswPPBJ7OJ1XN/6/hzCVaK2t+xJlAewG0HkDrAbQeQOsBtN4LKD2M7XoBm/32A9x1uwf+A5RFr+nQX9z0AAAAAElFTkSuQmCC"}/>
  
  </Popup>
                </div>
           
            </div>
        </section>)
}
export default UserDetay;