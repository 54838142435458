import React,{useEffect, useState,useRef } from "react";
import { useContext } from "react";
import { AuthContext } from "./Auth";
import { Link } from "react-router-dom";
import { color, style } from "@mui/system";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from "react-router-dom";
import iller from "./iller.js"
import ReactPaginate from "react-paginate";
import AracTaleplerimm from "./css/AracTaleplerim.css";
import checkboxcss from "./css/checkbox.css";

const AracTaleplerim = () =>{
  let navigate = useNavigate();

    let {token}  = useContext(AuthContext);
    let {userName}  = useContext(AuthContext);
    console.log(userName)
    const [loads,setLoads] = useState([]);
    const [constPerPageItem,setPerPageItem] = useState(10);
    const [loading,setLoading] = useState(true);
    const [currentPage,setCurrentPage] = useState(1);
    const [itemCount,setItemCount] =useState(0);
    
    const [gondericiIlFilter,setGondericiIlFilter] = useState("");
    const [aliciIlFilter,setAliciIlFilter] = useState("");
    const [gondericiFilter,setGondericiFilter] = useState("");
    const [aliciFilter,setfilterAliciUnvan] = useState("");
    const [secili,setSecili] = useState([]);
    const [yukleriBirGunErteleLoading,setYukleriBirGunErteleLoading] = useState(false);
    
    const filtreleriTemizle = () =>{
      setGondericiIlFilter("");
      setAliciIlFilter("");
      setfilterAliciUnvan("");
      setGondericiFilter("");
    }
 console.log(gondericiIlFilter)
 console.log(secili);
 const yukleriBirGunErtele = () =>{
  setYukleriBirGunErteleLoading(true);
  fetch("https://apipartner.yuksis.com/api/load/TopluYukGuncelle",{
    method:"Post",
    withCredentials:true,
    body: JSON.stringify(secili),
    headers: {
        "content-type": "application/json",
        "Authorization": `Bearer ${token}`
    }}).then(response=>response.json()).then(response=>{toast.success("Başarıyla Güncellendi.");console.log(response);setSecili([]);setYukleriBirGunErteleLoading(false)}).catch(err=>{toast.error("Bir Hata Meydana Geldi");console.log(err);setYukleriBirGunErteleLoading(false)});
 }
        useEffect(()=>
{
  setLoading(true)
    fetch(`https://apipartner.yuksis.com/api/load/Get10and10ByUser?sayi=${currentPage}&&gonderici=${gondericiIlFilter}&&aliciIlFilter=${aliciIlFilter}&&gondericiFilter=${gondericiFilter}&&aliciFilter=${aliciFilter}`,{
        method: "Get",  
        withCredentials:true,
        
        headers: {
          "content-type": "application/json",
          "Authorization": `Bearer ${token}`
    }}).then(response=>response.json()).then(response=>{
  if(response.data?.yukListesi!=null){
    setLoads(response.data.yukListesi.$values)
  }
  ;setLoading(false);setItemCount(response.data.itemCount);
        
        console.log(response)



    



}).catch(error=>console.log(error))},[currentPage,aliciIlFilter,gondericiIlFilter,aliciFilter,gondericiFilter,yukleriBirGunErteleLoading]);
  console.log(secili);
 
  const deleteItem = (id)=>{
    fetch(`https://apipartner.yuksis.com/api/load/RemoveLoad?id=${id}`,{
method: "Get",  
withCredentials:true,

headers: {
  "content-type": "application/json",
  "Authorization": `Bearer ${token}`
}}).then(response=>response.json().then(response=>{if(response.statusCode==200){
  toast.success("Yük Başarıyla Silindi.");setItemCount(itemCount-1)
}}).catch(error=>toast.error(error))

)}
const yukleriOneCikar = ()=>{
  setYukleriBirGunErteleLoading(true);
  fetch("https://apipartner.yuksis.com/api/load/YukleriOneCikar",{
    method:"Post",
    withCredentials:true,
    body: JSON.stringify(secili),
    headers: {
        "content-type": "application/json",
        "Authorization": `Bearer ${token}`
    }}).then(response=>response.json()).then(response=>{toast.success("Başarıyla Güncellendi.");console.log(response);setSecili([]);setYukleriBirGunErteleLoading(false)}).catch(err=>{toast.error("Bir Hata Meydana Geldi");console.log(err);setYukleriBirGunErteleLoading(false)});
}
const oneCikarmayiIptalEt = ()=>{
  setYukleriBirGunErteleLoading(true);
  fetch("https://apipartner.yuksis.com/api/load/OneCıkarmayıIptalEt",{
    method:"Post",
    withCredentials:true,
    body: JSON.stringify(secili),
    headers: {
        "content-type": "application/json",
        "Authorization": `Bearer ${token}`
    }}).then(response=>response.json()).then(response=>{toast.success("Başarıyla Güncellendi.");console.log(response);setSecili([]);setYukleriBirGunErteleLoading(false)}).catch(err=>{toast.error("Bir Hata Meydana Geldi");console.log(err);setYukleriBirGunErteleLoading(false)});
}

    return  (
    
    
        <div  className="col" >
        <div className="col-lg-12 tab-list">
      
      
    
   
            <div style={{marginTop:10}} className="two-menu">
                               
      
                                <ol className="breadcrumb">
                              
                                    <li><Link to="/" href="default.aspx" title="Anasayfa">Anasayfa </Link></li>
                                    <li> /Araç Taleplerim</li>      
                                </ol>     
                                <hr></hr>  
                            </div>
                    
            <div  className="tab-content">
          
            <div  id="collapseExample" >
            <ul style={{margin:10}} class="nav justify-content-start">
            <li class="nav-item">

  </li>  
  
 
  

 
  

</ul></div>
                <div role="tabpanel" className="tab-pane active" id="home" style={{overflow:"scroll"}}>  
               
                    <table className="table  table-responsive">
                    
                        <tbody>
                        <tr>
                        <th></th>
                      <th> <li class="nav-item">
    <input  onChange={e=>setGondericiFilter(e.target.value)} class="form-control"  value={gondericiFilter} placeholder="GöndericiUnvan"/>
  </li>   </th>
 <th>   <li class="nav-item">
    <input onChange={e=>setfilterAliciUnvan(e.target.value)} class="form-control"  value={aliciFilter} placeholder="Alici Unvan"/>
  </li>   </th>
  <th>

  <li class="nav-item">
    <select class="form-select" value={gondericiIlFilter} onChange={e=>setGondericiIlFilter(e.target.value)}>
      <option disabled selected value="">{gondericiIlFilter.length==0  ? "Lütfen Yükleme İlini Seçiniz"  : gondericiIlFilter}</option>
{iller.map(il=>{
  return (<option value={il.name}>{il.name}</option>)
})}

    </select>
    
  </li>
  </th>
  <th>
  <li class="nav-item">
  <select class="form-select" value={aliciFilter} onChange={e=>setAliciIlFilter(e.target.value)}>
      <option disabled selected value="">{aliciIlFilter.length==0 ? "Lütfen Boşaltma İlini Seçiniz" : aliciIlFilter}</option>
{iller.map(il=>{
  return (<option value={il.name}>{il.name}</option>)
})}

    </select>
  </li>
  </th>
  <th>

  <li class="nav-item" >
  <button onClick={()=>filtreleriTemizle()} style={{backgroundColor:"#ef233c"}} type="button" className="btn btn-danger">Filtreleri Temizle</button>  </li>
</th> {userName=="05413083048"  ? <span><th><li class="nav-item" >
{yukleriBirGunErteleLoading ? <button class="btn btn-primary" type="button" disabled>
  <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
  Lütfen Bekleyiniz...
</button> :  <button onClick={()=>oneCikarmayiIptalEt()} disabled={secili.length==0 ? true : false} style={{backgroundColor:"#5F6F94"}} type="button" className="btn btn-info">Öne Çıkarmayı İptal Et</button>} </li></th>

  <th>  <li class="nav-item" >
  
 {yukleriBirGunErteleLoading ? <button class="btn btn-primary" type="button" disabled>
  <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
  Lütfen Bekleyiniz...
</button> :  <button onClick={()=>yukleriOneCikar()} disabled={secili.length==0 ? true : false} style={{backgroundColor:"#5800FF",color:"#72FFFF"}} type="button" className="btn btn-white">Seçili Yükleri Öne Çıkar</button>} </li></th></span>  : ""}  <th>  <li class="nav-item" >
 {yukleriBirGunErteleLoading ? <button class="btn btn-primary" type="button" disabled>
  <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
  Lütfen Bekleyiniz...
</button> :  <button onClick={()=>yukleriBirGunErtele()} disabled={secili.length==0 ? true : false} style={{backgroundColor:"#5F6F94"}} type="button" className="btn btn-info">Seçili Yüklerin Tarihlerini Bir Gün Ertele</button>} 
</li></th>
    <th></th>
    <th></th>
    <th></th>

                        </tr>
                            <tr>
                            
                         <th></th>
                         <th>Öne Çıkarma</th>
                                <th >Gönderici Unvan</th>
                                
                                <th>Alıcı Unvan</th>
                                <th>Rota Bilgileri</th>
                                <th>Araç Özellikleri</th>
                                <th>Yük Özellikleri</th>
                                <th>Yükleme Tarih/Saat</th>
                                <th>Boşaltma Tarih/Saat	</th>
                               
                                
                                <th>Detay/Güncelle</th>
                                <th>Sil</th>
                         
                          
                                
                          
                          
                            </tr>
    {loading ? <td><button class="btn btn-primary" style={{width:200}} type="button" disabled>
      <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
      Yükleniyor
    </button></td> : loads.length==0 ? <h3>Henüz Araç Talebiniz Bulunmuyor</h3>  :   loads.length!=0 ? loads.map(u=>{
       
   
        return(
            
            
            
            
             <tr style={u?.aspiredLoads==null ? {backgroundColor:"white"} : u.aspiredLoads?.$values?.some(x=>x.isApproved==true) ? {backgroundColor:"#00b4d8"} :  u.aspiredLoads.$values.length>0 && {backgroundColor:"#fdd85d"}}  key={u.$id} >
    
           
            <td><label class="pure-material-checkbox">
  <input value={u?.id} onChange={(e)=>{if(e.target.checked){
    setSecili([...secili,e.target.value]);
  }
  else{
    setSecili(secili.filter(x=>x!=e.target.value))
  }
  }} type="checkbox"/>
  <span></span>
</label></td>

<td>{u?.isFavorite==true?<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-star" viewBox="0 0 16 16">
  <path d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.565.565 0 0 0-.163-.505L1.71 6.745l4.052-.576a.525.525 0 0 0 .393-.288L8 2.223l1.847 3.658a.525.525 0 0 0 .393.288l4.052.575-2.906 2.77a.565.565 0 0 0-.163.506l.694 3.957-3.686-1.894a.503.503 0 0 0-.461 0z"/>
</svg>: ""  }</td>
               <td >{u.senderName}</td>
               <td>{u.receiverName}</td>
              
               <td>{u.senderAdressCity} <h5></h5> {u.receiverCity}</td>
               <td>{u.truckName}/{u.trailerName}</td>
               <td>{u.loadQuantity} {u.loadUnit}/{u.loadType}</td>
               <td>{u.planningLoadDate}/ <br></br>{u.planningLoadTime}</td>
               <td>{u.planningUnloadDate}<br></br>{u.planningUnloadTime}</td>
             
               <td><div class="btn-group" role="group" aria-label="Basic example">
  <Link to={`${u.id}`} type="button" class="btn btn-secondary">Detay/Teklifler/Güncelle</Link>


</div></td>
               
               <td><button onClick={()=>deleteItem(u.id)} type="button" class="btn btn-danger">Sil</button></td>
           </tr>);
    
    
       })  : <h4 style={{alignSelf:"center"}}>Araç Talebiniz Bulunmamaktadır.</h4>  }
                           
                       
                           
                
                                      
                        </tbody>
                       
                    </table>    
                                   
                    <div className="clearfix"></div>
                   
                </div>
                <nav style={{marginTop:20}} aria-label="Page navigation example">
      <ul class="pagination justify-content-center">
      <ReactPaginate
            pageCount={itemCount/50}
            previousLabel={<svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="currentColor" class="bi bi-arrow-left-square-fill" viewBox="0 0 16 16">
  <path d="M16 14a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12zm-4.5-6.5H5.707l2.147-2.146a.5.5 0 1 0-.708-.708l-3 3a.5.5 0 0 0 0 .708l3 3a.5.5 0 0 0 .708-.708L5.707 8.5H11.5a.5.5 0 0 0 0-1z"/>
</svg>}
            nextLabel={<svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="currentColor" class="bi bi-arrow-right-square-fill" viewBox="0 0 16 16">
  <path d="M0 14a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2a2 2 0 0 0-2 2v12zm4.5-6.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5a.5.5 0 0 1 0-1z"/>
</svg>}
            breakLabel={<svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="currentColor" class="bi bi-three-dots" viewBox="0 0 16 16">
  <path d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z"/>
</svg>}
            onPageChange={(data)=>{setCurrentPage(data.selected+1)}}
            containerClassName={"pagination"}
            pageClassName={"page-item"}
            pageLinkClassName={"page-link"}
            activeClassName = {"active"} 
          
          />
     
          
      
           
     


     
      </ul>
        
     
      
     
    </nav>       
           </div>    </div></div>)
}
export default AracTaleplerim;
