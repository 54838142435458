import React,{useEffect, useState,useRef} from "react";
import Defaultcss from "./css/Default.css"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import iller from "./iller";
import ReactPaginate from "react-paginate";
import { Link ,Outlet,useLocation} from "react-router-dom";
import { Button } from "@mui/material";
import Popup from 'reactjs-popup';



const IletisimEkle = () =>{
    const [ContactData,setContactData] = useState([]);
    const [numaraEklePopup,setNumaraEklePopup] = useState(false);
    const [phoneNumber,setPhoneNumber] = useState();
    const [tittle,setTittle] = useState();
    const [guncellenecekItem,setGuncellenecekItem] = useState();
    const [iletisimNumarasıEkleLoading,setIletisimNumarasıEkleLoading] = useState(false);
    console.log(guncellenecekItem);
    const postData = async () => {
      setIletisimNumarasıEkleLoading(true)
        const token = localStorage.getItem("token");
     if (token !== null) {
       
         fetch("https://apipartner.yuksis.com/api/user/AddContact", {
             method: "Post",
             withCredentials: true,
             body: JSON.stringify({ email: "info@yurtlojistik.com.tr", "location": null, phoneNumber: phoneNumber , tittle: tittle}),
             headers: {
                 "content-type": "application/json",

                 "Authorization": `Bearer ${token}`
             }
         }).then(response=>response.json()).then(response=>{toast.success("İletişim Numarası Eklendi");setNumaraEklePopup(false);setPhoneNumber();setTittle();setIletisimNumarasıEkleLoading(false)}).catch(err=>console.log(err))}
    
}
   
   
    const getDataa = async () => {

        const token = localStorage.getItem("token");
     if (token !== null) {
       
         fetch("https://apipartner.yuksis.com/api/user/GetContactNumbers", {
             method: "GET",
             withCredentials: true,
          
             headers: {
                 "content-type": "application/json",

                 "Authorization": `Bearer ${token}`
             }
         }).then(response=>response.json()).then(response=>{console.log(response.data.$values);setContactData(response.data.$values)}).catch(err=>console.log(err))}
    
}
const updateData = async () => {
  setIletisimNumarasıEkleLoading(true)
    const token = localStorage.getItem("token");
 if (token !== null) {
   
     fetch("https://apipartner.yuksis.com/api/user/UpdateContact", {
         method: "Post",
         withCredentials: true,
         body: JSON.stringify(guncellenecekItem),
         headers: {
             "content-type": "application/json",

             "Authorization": `Bearer ${token}`
         }
     }).then(response=>response.json()).then(response=>{toast.success("İletişim Numarası Güncellendi");setIletisimNumarasıEkleLoading(false);setGuncellenecekItem()}).catch(err=>console.log(err))}

}
const deleteData = async (x) => {
  setIletisimNumarasıEkleLoading(true)
    const token = localStorage.getItem("token");
 if (token !== null) {
   
     fetch("https://apipartner.yuksis.com/api/user/DeleteContact", {
         method: "Post",
         withCredentials: true,
         body: JSON.stringify(x),
         headers: {
             "content-type": "application/json",

             "Authorization": `Bearer ${token}`
         }
     }).then(response=>response.json()).then(response=>{toast.success("İletişim Numarası Silindi");setIletisimNumarasıEkleLoading(false);setGuncellenecekItem()}).catch(err=>console.log(err))}

}
useEffect(()=>{getDataa()},[iletisimNumarasıEkleLoading])
 return (
    <div  className="col" >
    <Popup open={numaraEklePopup} contentStyle={{padding:50,borderRadius:20}} closeOnDocumentClick onClose={()=>setNumaraEklePopup(false)}  >
    <div class="input-group mb-3">
  <div class="input-group-prepend">
    <span class="input-group-text" id="basic-addon1">Başlık</span>
  </div>
  <input value={tittle} onChange={(e)=>setTittle(e.target.value)} type="text" class="form-control" placeholder="Örnek : Muhasebe" aria-label="Username" aria-describedby="basic-addon1"/>
  
</div>
   <div class="input-group mb-3">
  <div class="input-group-prepend">
    <span class="input-group-text" id="basic-addon1">Telefon Numarası</span>
  </div>
  <input value={phoneNumber} onChange={(e)=>setPhoneNumber(e.target.value)} type="text" class="form-control" placeholder="05********" aria-label="Username" aria-describedby="basic-addon1"/>
  
</div>
<button type="button" class="btn btn-info" onClick={()=>postData()} style={{width:200}}>Ekle</button>



    </Popup>
    <div className="col-lg-12 tab-list">
  
  

    
        <div style={{marginTop:10}} className="two-menu">
                           
  
                            <ol className="breadcrumb">
                          
                                <li><Link to="/" href="default.aspx" title="Anasayfa">Anasayfa </Link></li>
                                <li> /İletişim</li>      
                            </ol>     
                            <hr></hr>  
                        </div>
                
        <div  className="tab-content">
        <button onClick={()=>{setNumaraEklePopup(true)}} disabled={iletisimNumarasıEkleLoading} type="button" class="btn btn-success">İletişim Numarası Ekle</button>
        <div  id="collapseExample" >
        <ul style={{margin:10}} class="nav justify-content-start">
        <li class="nav-item">

</li>  







</ul></div>
            <div role="tabpanel" className="tab-pane active" id="home" style={{overflow:"scroll"}}>  
           
<table class="table table-hover">
  <thead>
    <tr>
      <th scope="col">id</th>
      <th style={{width:300}} scope="col">Başlık</th>
      <th scope="col" style={{width:300}}>Telefon Numarası</th>
      <th  scope="col">Güncelle  /Sil</th>
    
      
    </tr>
  </thead>
  <tbody>
 
    {ContactData?.map(x=>{
        return (   <tr>

      <td>{x?.id}</td>
      {guncellenecekItem?.id==x?.id ? <td><input className="form-control" value={guncellenecekItem?.tittle} onChange={(e)=>{setGuncellenecekItem({...guncellenecekItem,tittle:e.target.value})}}></input></td> :  <td>{x?.tittle}</td> }
      {guncellenecekItem?.id==x?.id ? <td><input className="form-control" value={guncellenecekItem?.phoneNumber} onChange={(e)=>{setGuncellenecekItem({...guncellenecekItem,phoneNumber:e.target.value})}}></input></td> : <td> {x?.phoneNumber}</td>}
      {guncellenecekItem?.id==x?.id ? <td><button onClick={()=>updateData()} style={{marginRight:10}} type="button" class="btn btn-success">Kaydet</button><button onClick={()=>setGuncellenecekItem()} type="button"  class="btn btn-danger">İptal</button></td> : <td><button onClick={()=>setGuncellenecekItem(x)} type="button" class="btn btn-secondary">Güncelle</button><button onClick={()=>deleteData(x)} style={{marginLeft:20}} type="button" class="btn btn-danger">Sil</button></td>  }</tr>)
    })}
 
  
   
  </tbody>
</table>
</div></div></div></div>
 )
}
export default IletisimEkle;