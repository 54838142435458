
import React,{useState,useEffect} from "react";
import { Link } from "react-router-dom";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const AddNewTruck = () =>{
    
    const token =  localStorage.getItem("token");
    const [tasitId,setTasitId] = useState("");
const [plaka,setPlaka] =useState("");
const [truckType,setTruckType] = useState("");
const [eyurtNumber,setEyurtNumber] = useState();
const [errorMessage,setErrorMessage] = useState();


const [file,setFile] = useState();  
const [fileName,setFileName] = useState();  
const [topluTalepErrorMessage,setTopluTalepErrorMessage] = useState();
const [yukleniyorButton,setYukleniyorButton] = useState(false);   
const saveFile = (e)=>{
    setFile(e.target.files[0])
    

}

const uploadFile = () =>{
    if(file==null){
        setTopluTalepErrorMessage("Lütfen Önce Excel Dosyasını Seçiniz")
    }
    else{
 
   var myFile = new FormData();
   myFile.append('file',file);
   setYukleniyorButton(true)
 
   fetch("https://apipartner.yuksis.com/api/truckTrailer/AddTruckTrailerFromClientToplu",{
    method: "post",
    withCredentials: true,
   

  
    headers: {
         
                  
        "Authorization": `Bearer ${token}`
    },
    body: myFile
}).then(response=>response.json()).then(response=>{
    toast.success("Araçlar Başarıyla Eklendi.");


    setTopluTalepErrorMessage();
  
    setYukleniyorButton(false)


}).catch(error=>{ setYukleniyorButton(false);setTopluTalepErrorMessage("Lütfen excel formatında bir belge yükleyiniz ve doğru bir şablon kullanınız.");console.log(error)});
}}

const addTruckAndTrailer = () =>{
         if(eyurtNumber!=null && plaka!=null){
          fetch("https://apipartner.yuksis.com/api/truckTrailer/AddTruckTrailerFromClientTekli",{
            method:"Post",
            withCredentials:true,
            body: JSON.stringify({trailerPlate:plaka,truckType:truckType,eYurtNumber:eyurtNumber,eYurtTasitId:tasitId,truckPlateNumber:plaka}),
            headers: {
                "content-type": "application/json",
                "Authorization": `Bearer ${token}`
            }}).then(response=>response.json()).then(response=>{if(response.statusCode==200){
                toast.success("Araç Başarıyla Eklendi.");
                setErrorMessage();
               
      
               
            }
        if(response.statusCode==400){
          response.error.errors.$values.map(x=>
            setErrorMessage(x)
            )
         
               
        }
        }).catch(error=>{setErrorMessage("Bir Hata Meydana Geldi Lütfen Alanları  Kontrol Ederek Tekrar Yüklemeyi Deneyiniz.");console.log(error)});
       
         }
         else{
           setErrorMessage("Lütfen E Yurt Numarası ve Plakayı Girdiğinizden Emin Olunuz.")
         }
  
}

    return ( <div  className="col" >
    <div className="three-menu">
        
 
                                 <ol className="breadcrumb">
                                 <div style={{boxShadow:"5px 5px 12px #888888",borderRadius:6,backgroundColor:"#f8f7ff"}} >
   <div class="card-body" ><div  className="container">
   <h5 className="page-title">Toplu Araç Ekle</h5>
   {topluTalepErrorMessage ? <div class="alert alert-danger" role="alert">
 {topluTalepErrorMessage} 
 
 </div>: "" }   
 
                
          <div className="row">
 
          <div style={{marginTop:32}} className="col-8"> {yukleniyorButton ? <button class="btn btn-primary" type="button" disabled>
   <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
   Yükleniyor
 </button> :  <button  type="button" style={{color:"black"}} class="btn btn-info btn-sm" onClick={uploadFile}><p style={{display:"inline",color:"white",fontWeight:"bold"}}>Araçları Ekle</p></button>  } <input  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" style={{width:250,display:"inline",marginLeft:20}} className="form-control form-control-sm" type="file"   onChange={saveFile}></input>
                                 </div>
       
         
                              
          <div  className="col-4"><p style={{color:"black"}}><div className="container"><div className="row">
          <div className="column" style={{marginTop:22}}><Link target="_blank" download to={"/files/AraclarSablon.xlsx"} style={{color:"#029ede",fontWeight:"bolder"}}>Toplu Araç Ekleme Excel Sablonunu İndirmek İçin Tıklayınız</Link></div>
          <div className="column"><p style={{fontWeight:"bolder"}}>Not: Başarılı bir şekilde toplu Araç Eklemek  için lütfen excel şabloununu kullanınız veya örnek alınız.</p></div>
          
          </div></div>
 </p></div>
        
 
          </div>
 
   </div>
                                
                                
                                 </div> 
 </div> 
                                 
                                 
                                 </ol>
                             </div>
     <div style={{marginTop:50}} className="col-lg-8 tab-list">
   
   
      
 
         <div style={{marginTop:10}} className="two-menu">
                            
   
                             <ol className="breadcrumb">
                           
                                 <li><Link to="/" href="default.aspx" title="Anasayfa">Anasayfa </Link></li>
                                 <li> /Yeni Araç Kaydı</li>      
                             </ol>     
                             <hr></hr>  
                         </div>
                 
         <div  className="tab-content">
     <form>  {errorMessage!=null ? <div class="alert alert-danger" role="alert">
  {errorMessage}
</div> : "" }  
     <div class="form-row">
       <div class="col-md-4 mb-3">
         <label for="validationDefault01">Kullanıcı E-Yurt Numarası </label>
         <input type="text" value={eyurtNumber} onChange={(e)=>setEyurtNumber(e.target.value)} class="form-control" id="validationDefault01" placeholder="E-Yurt Müşteri Numarası"  required/>
       
       </div>
       <div class="col-md-4 mb-3">
         <label for="validationDefault01">E-Yurt Taşıt Id</label>
         <input type="text" value={tasitId} onChange={(e)=>setTasitId(e.target.value)} class="form-control" id="validationDefault01" placeholder="E-Yurt Taşıt Numarası"  required/>
 
       
       </div>
       <div class="col-md-4 mb-3">
         <label for="validationDefault01">Plaka</label>
         <input  type="text" value={plaka} onChange={(e)=>setPlaka(e.target.value)} class="form-control" id="validationDefault01" placeholder="Plaka"  required/>
       
       </div>
   
 
     </div>
    
 
    
     <div class="form-row">
       <div class="col-md-6 mb-3">
         <label for="validationDefault03">Araç Türü</label>
         <select class="form-select" value={truckType} onChange={e=>setTruckType(e.target.value)}>
       <option disabled selected value="">{truckType?.length==0  ? "Lütfen Araç Tipini Seçiniz"  : truckType}</option>

<option value="Trucks">Çekici</option>
<option value="Trayler">Dorse</option>
</select></div></div>
      
    
    
     <button class="btn btn-primary" onClick={addTruckAndTrailer} type="button">Araç Ekle</button>
   </form></div></div></div>
  )
}
export default AddNewTruck;