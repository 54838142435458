import React,{useEffect, useState,useRef} from "react";
import Defaultcss from "./css/Default.css"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import iller from "./iller";
import ReactPaginate from "react-paginate";
import { Link ,Outlet,useLocation,useParams} from "react-router-dom";

const Uetds = () =>{
    const token = localStorage.getItem("token");
    const [uetds,setUetds] = useState([]);
    const [uetdsLoading,setUetdsLoading] = useState(true);
     const location = useLocation();
    const [loading,setLoading] = useState(true);
    const [constPerPageItem,setPerPageItem] = useState(10);
    const [currentPage,setCurrentPage] = useState(location.state==null ? 1 : location.state);
    const [isEgeApproved,setIsEgeApproved] = useState(false);
    const [itemCount,setItemCount] =useState(0);
    const [pageArray,setPageArray] = useState([]);
    const [hataMesajları,setHataMesajları] = useState([]);
    const [bosaltmaIlAdi,setbosaltmaIlAdi] = useState("");
    const [yuklemeIlAdi,setYuklemeIlAdi] = useState("")
    const [plaka1,setPlaka1] = useState("");    
    const [tcKimlikNo1,setcKimlikNo1] = useState("");
    const [uetdsSending,setUetdsSending] = useState(false);
    const [excelLoading,setExcelLoading] = useState(false);

   const  GetExcelUetds = () =>{
    setExcelLoading(true);
      fetch(`https://apipartner.yuksis.com/api/UETDS/GetUetdsFromDatabaseToExcel`,{
        method: "Get",
        withCredentials:true,
        
        headers: {
          "content-type": "application/json",
          "Authorization": `Bearer ${token}`
      }}).then(response=>response.json()).then(response=>{console.log(response);setExcelLoading(false);
        const link = document.createElement('a');
        link.href = `https://apipartner.yuksis.com/Images/UetdsList.xlsx`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }).catch(err=>console.log(err));
    }

   
 
      const filtreleriTemizle = () =>{
        setbosaltmaIlAdi("");
        setYuklemeIlAdi("");
        setPlaka1("");
        setcKimlikNo1("");



      }


    const uetdsGonder = (id) =>{
      setUetdsSending(true);    

        fetch(`https://apipartner.yuksis.com/api/uetds/SendUetds?id=${id}`,{
            method: "POST",
            withCredentials:true,
            
            headers: {
              "content-type": "application/json",
              "Authorization": `Bearer ${token}`,
          }}).then(response=>response.json()).then(response=>
            {if(response.statusCode==200){
              toast.success("İşlem Başarılı",{autoClose: 500})
             setHataMesajları("");
              setUetdsSending(false);
        
        } else {
            setHataMesajları(response.error.errors.$values);
            setUetdsSending(false);
           
        }})
           
            
            
          .catch(error=>{setUetdsSending(false);console.log(error)});
           
    }
   
   
    useEffect(()=>{
      setUetdsLoading(true);
    fetch(`https://apipartner.yuksis.com/api/uetds/GetUetds10by10?currentPage=${currentPage}&isEgeApproved=${isEgeApproved}&&bosaltmaIlAdi=${bosaltmaIlAdi}&&yuklemeIlAdi=${yuklemeIlAdi}&&plaka1=${plaka1}&&TcKimlikNo1=${tcKimlikNo1}`,{
        method: "Get",
        withCredentials:true,
        
        headers: {
          "content-type": "application/json",
          "Authorization": `Bearer ${token}`
      }}).then(response=>response.json()).then(response=>{setUetds(response.data.uetdsData.$values);setItemCount(response.data.itemCount)}).then(response=>setUetdsLoading(false)).catch(error=>{setUetdsLoading(false);console.log(error)})},[uetdsSending,currentPage,bosaltmaIlAdi,yuklemeIlAdi,plaka1,tcKimlikNo1,isEgeApproved])
 
    
    
  
   

    return (
    
    
    
    <div className="row" >
    <div className="col-lg-12 tab-list">
   
        <ul className="nav nav-tabs" role="tablist">
            <li role="presentation" className="active"><a href="#" aria-controls="home" role="tab" data-toggle="tab" style={{color:"#555"}}>UETDS Talep Durumları</a></li>
        </ul>

      <div className="tab-content">
      
            <div  id="collapseExample">
            <ul style={{marginBottom:20}} class="nav justify-content-center">
            <li class="nav-item">

  </li>  
  <li class="nav-item">
 
  </li>   
  <li class="nav-item">

  </li>   
  <li class="nav-item">
 
    
  </li>
  <li class="nav-item">
  
   
  </li>
  <li class="nav-item">

   
  </li>
  <li class="nav-item" style={{marginLeft:100}}>
 
  </li>
  

</ul></div>
       
       {hataMesajları?.length==0 ? "" :     
  hataMesajları.map(hata=>{
return (
    <div class="alert alert-danger" role="alert">
 {hata}
</div>
)
   

  })}
            <div role="tabpanel" className="tab-pane active" id="home" style={{overflow:"scroll"}}>  
   {excelLoading==true ?  <button style={{marginBottom:10,marginLeft:10    ,boxShadow: '3px 3px 9px #F4AAB9',}} class="btn btn-primary" type="button" disabled>
  <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
  indiriliyor..
</button> :  <button type="button" onClick={()=>GetExcelUetds()} class="btn btn-success" style={{marginBottom:10,marginLeft:10    ,boxShadow: '3px 3px 9px #F4AAB9'}}>Excel Çıktısı Al</button> }        
   
 
        <table className="table table-responsive table-striped">
              
                    <tbody>
                    
                    <tr>
                    <th> <button onClick={()=>filtreleriTemizle()} style={{backgroundColor:"#ef233c"}} type="button" className="btn btn-danger">Filtreleri Temizle</button></th>
<th> <select onChange={(e)=>{setIsEgeApproved(e.target.value);setCurrentPage(1)}} value={isEgeApproved}  class="form-select" aria-label="Default select example">
               
               <option value="" disabled selected>Lütfen Bir Seçenek Seçiniz</option>
             <option   value={false}>Gönderilmemişler</option>
             <option value={true}>Gönderilmişler</option>
            
           </select></th>
           <th><input class="form-control" value={plaka1} onChange={e=>{setCurrentPage(1);setPlaka1(e.target.value)}} placeholder="Çekici Plakası"/></th>
<th></th><th>  <input class="form-control" value={tcKimlikNo1} onChange={e=>{setCurrentPage(1);setcKimlikNo1(e.target.value)}} placeholder="Tc Kimlik Numarası"/></th>
<th></th><th>    <select onChange={e=>setYuklemeIlAdi(e.target.value)} class="form-select"  value={yuklemeIlAdi} placeholder="Yükleme Yeri">
    <option disabled selected value="">{yuklemeIlAdi?.length==0  ? "Lütfen Yükleme İlini Seçiniz"  : yuklemeIlAdi}</option>
{iller.map(il=>{
  return (<option value={il.name}>{il.name}</option>)
})}

</select></th><th></th><th></th><th></th><th>   <select class="form-select" value={bosaltmaIlAdi} onChange={e=>{setCurrentPage(1);setbosaltmaIlAdi(e.target.value)}}>
      <option disabled selected value="">{bosaltmaIlAdi?.length==0  ? "Lütfen Boşaltma İlini Seçiniz"  : bosaltmaIlAdi}</option>
{iller.map(il=>{
  return (<option value={il.name}>{il.name}</option>)
})}

    </select></th>
                    </tr>
                        <tr>
                        <th>Düzenle</th>
                            <th>Gönder</th>
                       
                     
                            <th>Araç Plaka</th>
                            <th>Dorse Plaka</th>
                            <th>Şoför TC No</th>
                            <th>Yardımcı Şoför TC No</th>
                            <th>Yükleme İl</th>
                            <th>Yükleme İlçe</th>
                            <th>Yükleme Tarih</th>
                            <th>Yükleme Saati</th>
                            <th>Boşaltma İl</th>
                            <th>Boşaltma İlçe</th>
                            <th>Boşaltma Tarih</th>
                            <th>Boşaltma Saati</th>
                            <th>Yük Cinsi</th>
                            <th>Yük Miktarı</th>
                            <th>Yük Miktar Birimi</th>
                            <th>Taşıma Bedeli</th>
                            <th>Gönderici Ünvan</th>
                            <th>Alıcı Ünvan</th>
                            <th>Durum</th>
                      
                      
                        </tr>
{ uetds.length!=0 ? uetds.map(u=>{
    
    return(

      uetdsLoading ? <div class="spinner-border" role="status">
  <span class="sr-only">Loading...</span>
</div>:
        
        
        
        
         <tr style={u?.unId!=null ? {backgroundColor:"#EB5F5D",height:65}: {height:65}} >

         {u.isEgeApproved  ?  "-" :     <td>  <Link to={`${u.id}`} state={{lastpage:currentPage}} href="UETDS-TalepGuncelle.aspx?id=10916" className=""><button type="button" class="btn btn-secondary btn-sm ">Düzenle</button></Link></td>}
           <td>
             
            {u.isEgeApproved  ?  "Gönderilmiş" : uetdsSending ? <div class="spinner-grow" role="status">
  <span class="sr-only">Loading...</span>
</div> : <input onClick={()=>uetdsGonder(u.id)}  type="button" id="ContentPlaceHolder1_lstListe_btnGonder_0" className="btn-sm btn-danger pointer " value="Uetds Gönder" />}   
           </td>
        
        
           <td >{u.plaka1}</td>
           <td>{u.plaka2}</td>
           <td>{u.tcKimlikNo1}</td>
           <td>{u.tcKimlikNo2}</td>
           <td>{u.yuklemeIlAdi}</td>
           <td>{u.yuklemeIlceAdı}</td>
           <td>{u.yuklemeTarihi}</td>
          <td>{u.yuklemeSaati}</td>
           <td>{u.bosaltmaIlAdi}</td>
           <td>{u.bosaltmaIlceAdı}</td>
          
           <td>{u.bosaltmaTarihi}</td>
           <td>{u.bosaltmaSaati}</td>
           <td>{u.yukCinsAdi}</td>
           <td>{u.yukMiktarı}</td>
           <td>{u.yukBirimi}</td>
           <td>{u.tasimaBedeli}</td>
           <td>{u.gondericiUnvan}</td>
           <td>{u.aliciUnvan}</td>
           <td>{u.isEgeApproved ? "Gonderilmiş" : "Gönderilmemiş"}</td>
       </tr>);


   })  : <h4 style={{alignSelf:"center"}}>Onay Bekleyen Yük Bildirimi Bulunmamaktadır.</h4>  }
                       
                   
                       
            
                                  
                    </tbody> 
                   
                </table>    
                               
                <div className="clearfix"></div>
               
            </div>
            <nav style={{marginTop:20}} aria-label="Page navigation example">
  <ul class="pagination justify-content-center">
  
  <ReactPaginate
            pageCount={itemCount/10}
            previousLabel={<svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="currentColor" class="bi bi-arrow-left-square-fill" viewBox="0 0 16 16">
  <path d="M16 14a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12zm-4.5-6.5H5.707l2.147-2.146a.5.5 0 1 0-.708-.708l-3 3a.5.5 0 0 0 0 .708l3 3a.5.5 0 0 0 .708-.708L5.707 8.5H11.5a.5.5 0 0 0 0-1z"/>
</svg>}
            nextLabel={<svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="currentColor" class="bi bi-arrow-right-square-fill" viewBox="0 0 16 16">
  <path d="M0 14a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2a2 2 0 0 0-2 2v12zm4.5-6.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5a.5.5 0 0 1 0-1z"/>
</svg>}
            breakLabel={<svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="currentColor" class="bi bi-three-dots" viewBox="0 0 16 16">
  <path d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z"/>
</svg>}     
            onPageChange={(data)=>{setCurrentPage(data.selected+1)}}
            containerClassName={"pagination"}
            pageClassName={"page-item"}
            pageLinkClassName={"page-link"}
            activeClassName = {"active"} 
            
          />
  
       
 
   
  
 
 
  </ul>
</nav>       
       </div> 
   
   
         </div></div>
       
 )

}
export default Uetds;