

import React, { useEffect, useState } from "react";
import { useContext } from "react";
import iller from "./iller.js";
import ilceler from "./ilceler.js"
import { AuthContext } from "./Auth";
import kasatipler from "./kasatipler.js";
import { Link,useLocation } from "react-router-dom";
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { borderRadius, display, height, style } from "@mui/system";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



const AracTalep = () =>
{
    const TruckTypes = [{id : 1 , name : "Tır Dorseli" },{id : 2 , name : "Tır Kısa Damper Dorseli" },{id : 3 , name : "Kırkayak" },{id : 4 , name : "10 Teker" },
    {id : 5 , name : "6 Teker" },{id : 6 , name : "Kamyonet" },{id :7 , name : "Panelvan" },{id :8 , name : "Kamyon Römork" },{id :9 , name : "Özel Amaçlı" },{id:10,name: "Farketmez"}]
    
    const [truckType,setTruckType] = useState(0);
    const [trailerType,setTrailerType] = useState("0");
    const [activeStep, setActiveStep] = useState(1);
    const [bulunulanSayfa,setBulunulanSayfa] = useState();
    const [locationHash,setLocationHash]=useState();
    const [yuklemeIli,setYuklemeIli] = useState("1");
    const [tabanType,setTabanType] = useState();
    const [yukCinsi,setYukCinsi] = useState();
    const [yukMiktari,setYukMiktari] = useState();
    const [hacim,setHacim] = useState();
    const [odemeYontemi,setOdemeYontemi] = useState();
    const [tasimaBedeli,setTasimaBedeli] = useState();
    const [yukDeger,setYukDeger] = useState();
    const [paketlemeTipi,setPaketlemeTipi] =useState();
    const [adresBasligi,setAdresBasligi] = useState();
    const [yuklemeIlcesi,setYuklemeIlcesi] = useState();
    const [yuklemeAdresAciklamasi,setYuklemeAdresAciklamasi] = useState();
    const [yuklemeYetkiliTelefon,setYuklemeYetkiliTelefon] = useState();
    const [yuklemeTarihi,setYuklemeTarihi] = useState();
    const [yuklemeSaati,setYuklemeSaati] = useState();
    const [bosaltmaAdresBasligi,setBosaltmaAdresBasligi] = useState();
    const [bosaltmaIli,setBosaltmaIli] = useState("1")
    const [bosaltmaIlcesi,setBosaltmaIlcesi] = useState();
    const [bosaltmaAdresiYetkiliTelefon,setBosaltmaAdresiYetkiliTelefon] = useState();
    const [bosaltmaTarihi,setBosaltmaTarihi] = useState();
    const [aliciTcVergiNo,setAliciTcVergiNo] = useState();
    const [bosaltmaSaati,setBosaltmaSaati] = useState();
    const [yukAciklamasi,setYukAciklamasi] = useState();
    const [bosaltmaAdresi,setBosaltmaAdresi] = useState();
    const [gondericiUnvan,setGondericiUnvan] = useState();
    const [aliciUnvan,setAliciUnvan] = useState();
    const [errorMessage,setErrorMessage] =useState();
    const [file,setFile] = useState();  
    const [fileName,setFileName] = useState();  
    const [isParsiyel,setIsParsiyel] = useState(false);
    const [topluTalepErrorMessage,setTopluTalepErrorMessage] = useState();
    const [yukleniyorButton,setYukleniyorButton] = useState(false);    
  
    const saveFile = (e)=>{
        setFile(e.target.files[0])
        

    }
    const uploadFile = () =>{
        if(file==null){
            setTopluTalepErrorMessage("Lütfen Önce Excel Dosyasını Seçiniz")
        }
        else{
     
       var myFile = new FormData();
       myFile.append('file',file);
       setYukleniyorButton(true)
     
       fetch("https://apipartner.yuksis.com/api/load/addloadtoplu",{
        method: "post",
        withCredentials: true,
       

      
        headers: {
           

            "Authorization": `Bearer ${token}`
        },
        body: myFile
    }).then(response=>response.json()).then(response=>{if(response.statusCode==200){
        toast.success("Yükler Başarıyla Eklendi.");
        setErrorMessage();
        setTopluTalepErrorMessage();
        setLocationHash("#step-1")
        setYukleniyorButton(false)
    }
else{
    setTopluTalepErrorMessage("Lütfen excel formatında bir belge yükleyiniz ve doğru bir şablon kullanınız.")
    setYukleniyorButton(false);
}
}).catch(error=>{ setYukleniyorButton(false);setTopluTalepErrorMessage("Lütfen excel formatında bir belge yükleyiniz ve doğru bir şablon kullanınız.")});
    }}
    const waitinLoad = {truckType:truckType,trailerType:trailerType,senderName:gondericiUnvan==null  ? "boş" : gondericiUnvan ,senderGSM:yuklemeYetkiliTelefon,senderAdressCity:iller.find(il=>il.id===yuklemeIli).name.toLocaleUpperCase(),senderAdressTown:yuklemeIlcesi,
        senderAdress:yuklemeAdresAciklamasi,receiverName:aliciUnvan==null ? "boş" : aliciUnvan,receiverTCKN:aliciTcVergiNo,receiverGSM:bosaltmaAdresiYetkiliTelefon,receiverCity:iller.find(il=>il.id===bosaltmaIli).name.toLocaleUpperCase(),receiverAdressTown:bosaltmaIlcesi,
    receiverAdress:bosaltmaAdresi,loadType:yukCinsi,loadUnit:"TON",loadQuantity:parseInt(yukMiktari),loadValue:yukDeger,bosaltmaAdresBasligi:bosaltmaAdresBasligi,yuklemeAdresBasligi:adresBasligi,loadDescription:yukAciklamasi,
    loadBulk:hacim!=null ?  parseInt(hacim) : 0,paymentType:odemeYontemi,freight:parseInt(tasimaBedeli),planningLoadDate:yuklemeTarihi,planningLoadTime:yuklemeSaati,paketlemeType:paketlemeTipi,tabanType:tabanType,isParsiyel:(isParsiyel=="true" ? true : false),
    planningUnloadDate:bosaltmaTarihi,planningUnloadTime:bosaltmaSaati,truckName: TruckTypes.find(kasa=>kasa.id==truckType)?TruckTypes.find(kasa=>kasa.id==truckType).name : "null",trailerName:kasatipler.find(trailer=>trailer.trailer_type_id==trailerType)?kasatipler.find(trailer=>trailer.trailer_type_id==trailerType).trailer_type : "null" }
 
    const token = localStorage.getItem("token");
    const formSubmit = () =>{
      let isThereAnyNullElement = [];

    

    if(yuklemeIlcesi==null){
        isThereAnyNullElement.push("Yükleme İl ve İlçesi");
    }
  
    
    if(bosaltmaIlcesi==null){
        isThereAnyNullElement.push("Boşaltma İl ve İlçesi");
    }
  
    
   
  
  
 
if(isThereAnyNullElement.length>0){
    
    let stringHataMesajı = "Lütfen Açıklamadaki Alanları Doldurunuz: ";
    isThereAnyNullElement.forEach(item=>{
        stringHataMesajı = stringHataMesajı + item.toString()+",";
        setErrorMessage(stringHataMesajı);
    }
    
        
        )
}


else {
    
    fetch("https://apipartner.yuksis.com/api/load/AddLoad",{
        method:"Post",
        withCredentials:true,
        body: JSON.stringify(waitinLoad),
        headers: {
            "content-type": "application/json",
            "Authorization": `Bearer ${token}`
        }}).then(response=>response.json()).then(response=>{if(response.statusCode==200){
            toast.success("Yük Başarıyla Eklendi.");
            setErrorMessage();
            setLocationHash("#step-1")
            setTrailerType("0")
            

           
        }
    else{
        setErrorMessage("Bir Hata Meydana Geldi Lütfen Alanları Tekrar Kontrol Ederek Yüklemeyi Deneyiniz.")
    
    }
    }).catch(error=>setErrorMessage("Bir Hata Meydana Geldi Lütfen Alanları  Kontrol Ederek Tekrar Yüklemeyi Deneyiniz."));
}
        
       
    }
    let location = useLocation();
  console.log(waitinLoad);
    const array = ([...Array(28).keys()]);
    useEffect(()=>{
        setLocationHash(location.hash);
    },[location])
    
    var  trailerTypes = kasatipler.filter(kasa=>kasa.truck_type_id == truckType);
    return (<div className="row" style={{overflow:"auto",marginBottom:150}}>    
    
                <div className="col">
                    <div className="col">
                      
                        <div className="three-menu">
                                <h4 className="page-title">ARAÇ TALEP ET</h4>
                                <hr></hr>
                                <ol className="breadcrumb">
                                    <li><Link to="/" href="default.aspx" title="Anasayfa">Anasayfa </Link></li>
                                    <li> /Araç Talep Et</li>
                                </ol>
                            </div>
                            <div className="three-menu">
       

                                <ol className="breadcrumb">
                                <div   style={{boxShadow:"5px 5px 12px #888888",borderRadius:6,backgroundColor:"#f8f7ff"}} >
  <div  class="card-body" ><div  className="container">
  <h5 className="page-title">Toplu Talep Oluştur</h5>
{topluTalepErrorMessage ? <div class="alert alert-danger" role="alert">
{topluTalepErrorMessage} 

</div>: "" }                               
         <div className="row">

         <div style={{marginTop:32}} className="col-8"> {yukleniyorButton ? <button class="btn btn-primary" type="button" disabled>
  <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
  Yükleniyor
</button> :  <button   type="button" style={{color:"black"}} class="btn btn-info btn-sm" onClick={uploadFile}><p style={{display:"inline",color:"white",fontWeight:"bold"}}>Yükleri Yükle</p></button>  } <input accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" style={{width:250,display:"inline",marginLeft:20}} className="form-control form-control-sm" type="file"  onChange={saveFile}></input>
                                </div>
      
        
                             
         <div  className="col-4"><p style={{color:"black"}}><div className="container"><div className="row">
         <div className="column" style={{marginTop:22}}><a download  style={{color:"#029ede",fontWeight:"bolder"}}>Toplu Yük Talebi Excel Sablonunu İndirmek İçin Tıklayınız</a></div>
         <div className="column"><p style={{fontWeight:"bolder"}}>Not: Başarılı bir şekilde toplu yük talebinde bulunabilmek için lütfen excel şabloununu kullanınız veya örnek alınız.</p></div>
         
         </div></div>
</p></div>
       

         </div>

  </div>
                               
                               
                                </div> 
</div> 
                                
                                
                                </ol>
                            </div>
                       
                        <div className="col" style={{backgroundColor:"white",paddingTop:20,borderRadius:10,boxShadow:" rgba(0, 0, 0, 0.35) 0 5 15"}}>
                 <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 pull-right" >
                    <div className="stepwizard">
                        <div className="stepwizard-row setup-panel">
                            <div className="stepwizard-step">
                                <a href="#step-1" type="button"   className={locationHash=="#step-1" ? "btn btn-default btn-block btn-light"  : "btn btn-default btn-block"} style={locationHash=="#step-1" ? {backgroundColor:"#ffe8d6"} :{backgroundColor:"#FFFF"} }>1. ARAÇ ÖZELLİKLERİ <i className="far fa-check-circle hidden"></i></a>
                            </div>
                            <div className="stepwizard-step">
                                <a href="#step-2" type="button" className={locationHash=="#step-2" ? "btn btn-default btn-block btn-light"  : "btn btn-default btn-block"} style={locationHash=="#step-2" ? {backgroundColor:"#ffe8d6"} :{backgroundColor:"#FFFF"} } disabled="disabled">2. YÜK TİPİ VE ÖZELLİKLERİ <i className="far fa-check-circle hidden"></i></a>
                            </div>
                            <div className="stepwizard-step">
                                <a href="#step-3" type="button" className={locationHash=="#step-3" ? "btn btn-default btn-block btn-light"  : "btn btn-default btn-block"} style={locationHash=="#step-3" ? {backgroundColor:"#ffe8d6"} :{backgroundColor:"#FFFF"} } disabled="disabled">3. YÜKLEME BİLGİLERİ <i className="far fa-check-circle hidden"></i></a>
                            </div>
                            <div className="stepwizard-step">
                                <a href="#step-4" type="button" className={locationHash=="#step-4" ? "btn btn-default btn-block btn-light"  : "btn btn-default btn-block"} style={locationHash=="#step-4" ? {backgroundColor:"#ffe8d6"} :{backgroundColor:"#FFFF"} }>4. YÜKÜN TESLİM BİLGİLERİ <i className="far fa-check-circle  hidden"></i></a>
                            </div>
                            <div className="stepwizard-step">
                                <a href="#step-5" type="button" className={locationHash=="#step-5" ? "btn btn-default btn-block btn-light"  : "btn btn-default btn-block"} disabled="disabled" style={locationHash=="#step-5" ? {backgroundColor:"#ffe8d6"} :{backgroundColor:"#FFFF"} }>5. TAMAMLA <i className="far fa-check-circle  hidden"></i></a>
                            </div>
                        </div>
                 </div></div>
                 <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12" >
               
           
                    <div id="talepform">
                        <div className="panel panel-primary setup-content" id="step-1" style={locationHash=="#step-1" ? {display:"contents", } : {display:"none"}} >
                 <div className="panel-body">
                 
                                <section >
                        
                                    <h2>1. ARAÇ ÖZELLİKLERİ</h2>
                                    
                                   
                                  
                                   
                              
                                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                        <div className="form-box">
                                            <span className="form-label">Araç Türü*</span>    
                                            <div className="btn-group bootstrap-select">
                                               
                                                 <select  className="form-select"  onChange={e=>setTruckType(e.target.value)}>
                                        
                                                   <option value="0" disabled selected>Lütfen Araç Türünü Seçiniz</option>
                                                   {TruckTypes.map(truck => {
                                                       return ( <option value={truck.id} >{truck.name}</option>)
                                                   })}
                                              

                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                 
                                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                        <div className="form-box">
                                            <span className="form-label">Kasa Tipi</span>
                                            <div className="btn-group bootstrap-select">
                                            
                                               <select onChange={e=>setTrailerType(e.target.value)} className="form-select">
                                               <option disabled selected value="0">{truckType==0 ? "Lütfen Önce Araç Türünü Seçiniz" : trailerTypes.length==0 ? "Bu araç İçin Kasa Tipi Bulunmamaktadır." : "Lütfen Bir Kasa Tipi Seçiniz"}</option>
                                               {trailerTypes.map(trailer=>{
                                                       return (
                                                      <option value={trailer.trailer_type_id}>
                                                           {trailer.trailer_type}
                                                       </option>)})}
                                                    
                                                    


                                             </select> 

                                               

                                              
                                           </div>
                                   </div> </div>
                                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                        <div className="form-box">
                                            <span className="form-label">Zemin Tipi</span>
                                            <div className="btn-group bootstrap-select">
                                               
                                               <select onChange={(e)=>setTabanType(e.target.value)} className="form-select"  >
                                            <option disabled selected value="null">Lütfen Zemin Tipi Seçiniz</option>
                                               <option value="Saç Taban">Sac Taban </option>
                                               <option value="Ahşap Taban">Ahsap Taban</option>
                                              

                                               </select>
                                           </div>
                                        </div>
                                    </div>
                                   
                                  
                                   
                                    <div className="clearfix">     </div>
                                    <a class="btn btn-success nextBtn" type="button" href="#step-2">DEVAM ET »</a>
                                </section>  
                            </div>  </div> </div>
                            
                            <div className="panel panel-primary setup-content"  id="step-2" style={locationHash=="#step-2" ? {display:"contents"} : {display:"none"}}>
                            <div className="panel-body" >
                                <section>
                                    <h2 style={{backgroundColor:"#eee",padding:15,fontSize:22,fontWeight:700,borderRadius:5,color:"black"}}>2. YÜK TİPİ VE ÖZELLİKLERİ</h2>
                            
                                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                        <div className="form-box">
                                            <span className="form-label">Parça-Komple Yük</span>
                                            <select value={isParsiyel}  onChange={(e)=>setIsParsiyel(e.target.value)} name="ctl00$ContentPlaceHolder1$drpOdemeSekli" id="ContentPlaceHolder1_drpOdemeSekli" className="selectpicker" required="required" data-show-subtext="true" data-live-search="true" >
                                         
                     <option value={true}>Parça Yük</option>
                     <option value={false}>Komple Yük</option>
                   




                                            </select>
                                           
                                        </div>
                                    </div>
                              
                          
                                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                        <div className="form-box">
                                            <span className="form-label">Yük Cinsi</span>
                                            <div className="btn-group bootstrap-select">
                                            <input onChange={(e)=>setYukCinsi(e.target.value)} name="ctl00$ContentPlaceHolder1$txtYukCinsiAciklama" className="form-control"  id="ContentPlaceHolder1_txtYukCinsiAciklama" />
                                       









                                       </div>
                                        </div>
                                    </div>
                                 
                                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                        <div className="form-box">
                                            <span className="form-label">Yük Miktarı</span>
                                           
                                            <select type="number" onChange={(e)=>setYukMiktari(e.target.value)} name="ctl00$ContentPlaceHolder1$drpYukMiktari" id="ContentPlaceHolder1_drpYukMiktari" className="form-select" required="required">
                                            <option  value="null" disabled selected>Yük Miktarını Seçiniz</option>
                                            {(array.map(sayi=>{
                                             
                                                if(sayi==0)
                                                {
                                                    return("")
                                                }
                                                else
                                                {
                                                    return (<option  value={sayi}>{sayi} Ton</option>)
                                                }
                                                
                                            }


                                            ))

                                            }
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                        <div className="form-box">
                                            <span className="form-label">Toplam Hacim</span>
                                            <input onChange={(e)=>setHacim(e.target.value)} name="ctl00$ContentPlaceHolder1$txtHacim" type="text" id="ContentPlaceHolder1_txtHacim" placeholder="Hacim" className="form-control hacim"/>
                                        </div>
                                    </div>
                                  
                                
    
    
    
                                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                        <div className="form-box">
                                            <span className="form-label">Ödeme Şekli</span>
                                            <select onChange={(e)=>setOdemeYontemi(e.target.value)} name="ctl00$ContentPlaceHolder1$drpOdemeSekli" id="ContentPlaceHolder1_drpOdemeSekli" className="selectpicker" required="required" data-show-subtext="true" data-live-search="true" >
                                            <option value="null" disabled selected>Ödeme Yöntemi Seçiniz</option>
                     <option value="Karşı Ödemeli">Karşı Ödemeli</option>
                     <option value="Havale">Havale</option>
                     <option value="Havale(7Gün)">Havale(7Gün)</option>
                     <option value="Havale(14Gün)">Havale(14Gün)</option>
                     <option value="Nakit+Yakıt">Nakit+Yakıt</option>
                     <option value="Yakıt">Yakıt</option>
                     <option value="Diğer">Diğer</option>





                                            </select>
                                           
                                        </div>
                                    </div>
                                
                                 
                                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                        <div className="form-box">
                                            <span className="form-label">Taşıma Bedeli(Navlun Bedeli)</span>
                                            <input type="number" onChange={(e)=>setTasimaBedeli(e.target.value)} name="ctl00$ContentPlaceHolder1$drpPaketleme" id="ContentPlaceHolder1_drpPaketleme" className="selectpicker" required="required" data-show-subtext="true" data-live-search="true"/>
                                          </div>

                                            
                                            
                                    
                                        </div>
                                  
                                 
                                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                        <div className="form-box">
                                            <span className="form-label">Yükün Değeri</span>
                                            <input type="number" onChange={(e)=>setYukDeger(e.target.value)}  className="form-control"/>
                                          

                                            
                                            
                                    
                                        </div>
                                    </div>    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                        <div className="form-box">
                                            <span className="form-label">Paketleme Tipi*</span>
                                            <select onChange={e=>setPaketlemeTipi(e.target.value)} name="ctl00$ContentPlaceHolder1$drpPaketleme" id="ContentPlaceHolder1_drpPaketleme" className="selectpicker" required="required" data-show-subtext="true" data-live-search="true" >
                                            <option value="null" disabled selected>Paketleme Tipini Seçiniz...</option>
                                            <option value="Paletli Yuk"> Paletli Yuk</option>
                                            <option value="Kolili"> Kolili</option>
                                         
                                            <option value="Bidon Varil"> Bidon Varil</option>
                                            <option value="Big Bag"> Big Bag</option>
                                            <option value="Dokme">Dokme</option>
                                            <option value="IBC Tank">IBC Tank</option>
                                            <option value="Vinç">Vinç</option>


                                     
                                            
                                           </select>
                                         
                                    </div>  
                                  
                                    <a class="btn btn-warning prevBtn pull-left" type="button" href="#step-1">« ÖNCEKİ ADIM</a>
                            <a class="btn btn-success nextBtn" type="button" href="#step-3">DEVAM ET »</a>         
                                    </div> 
                                    
                                  
                                    <div className="clearfix"></div>
                               
                                </section>
                      
                            
                              

                            
                            
                            
                            
                            
                            
                            
                            
                            
                            
                            
                            
                            
                            
                            
                            
                            
                            </div></div>
                            <div class="panel panel-primary setup-content" id="step-3" style={locationHash=="#step-3" ? {display:"contents"} : {display:"none"} } >
                            <div class="panel-body">
                                <section>
                                    <div id="ContentPlaceHolder1_upYukleme">
                                        <h2  style={{backgroundColor:"#eee",padding:15,fontSize:22,fontWeight:700,borderRadius:5,color:"black"}}>3. YÜKLEME BİLGİLERİ</h2>
                                        
                                        <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12">
                                            <div class="form-box">
                                                <span class="form-label">Adres Başlığı</span>
                                                <input onChange={e=>setAdresBasligi(e.target.value)} placeholder="Lütfen Adresinizi Tanımlamak İçin Bir İsim Giriniz" name="ctl00$ContentPlaceHolder1$drpYuklemeAdres"  className="form-select" id="ContentPlaceHolder1_drpYuklemeAdres" class="selectpicker" required="required"  />
 
                                                
            
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12">
                                            <div class="form-box">
                                                <span class="form-label">Gönderici Unvan</span>
                                                <input onChange={e=>setGondericiUnvan(e.target.value)} placeholder="Lütfen Yükü Gönderen Firmanın İsmini Giriniz" name="ctl00$ContentPlaceHolder1$drpYuklemeAdres"  className="form-select" id="ContentPlaceHolder1_drpYuklemeAdres" class="selectpicker" required="required"  />
 
                                                
            
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12">
                                            <div class="form-box">
                                                <span class="form-label">Yükleme İlini Seçiniz</span>
                                                <select name="ctl00$ContentPlaceHolder1$drpYuklemeAdres" onChange={(e)=>{setYuklemeIli(e.target.value)}} className="form-select" id="ContentPlaceHolder1_drpYuklemeAdres" class="selectpicker" required="required"  >
 <option value="null" disabled selected> Yükleme İlini Seçiniz</option>
                                                   {iller.map(il=>
                                                   {
                                                       return(
                                                           <option value={il.id}>{il.name}</option>
                                                       )
                                                   })}
                                                </select>
            
                                            </div>
                                        </div>
                                 
                                        
                                        <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12">
                                            <div class="form-box">
                                                <span class="form-label">Yükleme İlçesi</span>
                                                <select onChange={e=>setYuklemeIlcesi(e.target.value)} name="ctl00$ContentPlaceHolder1$drpYuklemeAdres" className="form-select" id="ContentPlaceHolder1_drpYuklemeAdres" class="selectpicker" required="required" data-show-subtext="true" data-live-search="true" >
 <option value="null" disabled selected> Yükleme İlçesini Seçiniz</option>
                                                   {ilceler.filter(ilce=>ilce.ilce_sehirkey===yuklemeIli).map(ilce=>
                                                   {
                                                       return(
                                                           <option value={ilce.ilce_title}>{ilce.ilce_title}</option>
                                                       )
                                                   })}
                                                </select>
            
                                            </div>
                                        </div>      
                                        <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12">
                                            <div class="form-box">
                                                <span class="form-label">Adres(Cad/Sokak)</span>
                                                <textarea  onChange={(e)=>setYuklemeAdresAciklamasi(e.target.value)} placeholder="Örnek:Beyoğlu Sokak No:6 " name="ctl00$ContentPlaceHolder1$drpYuklemeAdres" className="form-select" id="ContentPlaceHolder1_drpYuklemeAdres" class="selectpicker" required="required" data-show-subtext="true" data-live-search="true" />

                                           
            
                                            </div>
                                        </div>              <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12">
                                            <div class="form-box">
                                                <span class="form-label">Yetkili Telefon Numarası</span>
                                                <input type="number" name="ctl00$ContentPlaceHolder1$drpYuklemeAdres" onChange={(e)=>{setYuklemeYetkiliTelefon(e.target.value)}} className="form-select" id="ContentPlaceHolder1_drpYuklemeAdres" class="selectpicker" required="required"  />

                                                 
                                          
                                            </div>
                                        </div>  
                                        <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12">
                                            <div class="form-box">
                                                <span class="form-label">Tahmini Yükleme Tarihi</span>
                                                <input name="ctl00$ContentPlaceHolder1$drpYuklemeAdres" onChange={(e)=>{setYuklemeTarihi(e.target.value.split('-').reverse().join("/"))}}  type="date" className="form-select" id="ContentPlaceHolder1_drpYuklemeAdres" class="selectpicker" required="required"  />

                                                 
                                          
                                            </div>
                                        </div>       
                                        <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12">
                                            <div class="form-box">
                                                <span class="form-label">Tahmini Yükleme Saati</span>
                                                <input name="ctl00$ContentPlaceHolder1$drpYuklemeAdres" onChange={(e)=>{setYuklemeSaati(e.target.value)}} type="time" className="form-select" id="ContentPlaceHolder1_drpYuklemeAdres" class="selectpicker" required="required"  />

                                                 
                                          
                                            </div>
                                        </div>       
                                                        <div class="clearfix"></div>
                                    </div>
                                </section>
                            </div>
                            <a class="btn btn-warning prevBtn pull-left" type="button" href="#step-2">« ÖNCEKİ ADIM</a>
                            <a class="btn btn-success nextBtn" type="button" href="#step-4">DEVAM ET »</a>
                        </div>
                        <div class="panel panel-primary setup-content"  id="step-4" style={locationHash=="#step-4" ? {display:"contents"} : {display:"none"}}>
                            <div class="panel-body">
                                <section>
                                    <div id="ContentPlaceHolder1_upBosaltma">
                                        <h2 style={{backgroundColor:"#eee",padding:15,fontSize:22,fontWeight:700,borderRadius:5,color:"black"}}>4. YÜKÜN TESLİM BİLGİLERİ</h2>
                                      
                                   <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12">
                                       <div class="form-box">
                                           <span class="form-label">Adres Başlığı</span>
                                           <input onChange={e=>setBosaltmaAdresBasligi(e.target.value)} placeholder="Lütfen Adresinizi Tanımlamak İçin Bir İsim Giriniz" name="ctl00$ContentPlaceHolder1$drpYuklemeAdres"  className="form-select" id="ContentPlaceHolder1_drpYuklemeAdres" class="selectpicker" required="required"  />

                                           
       
                                       </div>
                                   </div>
                                   <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12">
                                       <div class="form-box">
                                           <span class="form-label">Alıcı Unvan</span>
                                           <input onChange={e=>setAliciUnvan(e.target.value)} placeholder="Lütfen Adresinizi Tanımlamak İçin Bir İsim Giriniz" name="ctl00$ContentPlaceHolder1$drpYuklemeAdres"  className="form-select" id="ContentPlaceHolder1_drpYuklemeAdres" class="selectpicker" required="required"  />

                                           
       
                                       </div>
                                   </div>
                                   <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12">
                                       <div class="form-box">
                                           <span class="form-label">Boşaltma İlini Seçiniz</span>
                                           <select name="ctl00$ContentPlaceHolder1$drpYuklemeAdres" onChange={(e)=>{setBosaltmaIli(e.target.value)}} className="form-select" id="ContentPlaceHolder1_drpYuklemeAdres" class="selectpicker" required="required"  >
<option value="null" disabled selected> Yükleme İlini Seçiniz</option>
                                              {iller.map(il=>
                                              {
                                                  return(
                                                      <option value={il.id}>{il.name}</option>
                                                  )
                                              })}
                                           </select>
       
                                       </div>
                                   </div>
                                   
                                   <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12">
                                       <div class="form-box">
                                      <div className="contanier"></div>
                                           <span class="form-label">Boşaltma İlçesi</span>
                                           <select onChange={e=>setBosaltmaIlcesi(e.target.value)} name="ctl00$ContentPlaceHolder1$drpYuklemeAdres" className="form-select" id="ContentPlaceHolder1_drpYuklemeAdres" class="selectpicker" required="required" data-show-subtext="true" data-live-search="true" >
<option value="null" disabled selected> Boşaltma İlçesini Seçiniz</option>
                                              {ilceler.filter(ilce=>ilce.ilce_sehirkey===bosaltmaIli).map(ilce=>
                                              {
                                                  return(
                                                      <option value={ilce.ilce_title}>{ilce.ilce_title}</option>
                                                  )
                                              })}
                                           </select>
       
                                       </div>
                                   </div>      
                                   <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12">
                                       <div class="form-box">
                                           <span class="form-label">Adres(Cad/Sokak)</span>
                                           <textarea onChange={e=>setBosaltmaAdresi(e.target.value)} placeholder="Örnek:Beyoğlu Sokak No:6 " name="ctl00$ContentPlaceHolder1$drpYuklemeAdres" className="form-select" id="ContentPlaceHolder1_drpYuklemeAdres" class="selectpicker" required="required" data-show-subtext="true" data-live-search="true" />

                                      
       
                                       </div>
                                   </div>         
                                   <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12">
                                       <div class="form-box">
                                           <span class="form-label">Yetkili Telefon Numarası</span>
                                           <input type="number" name="ctl00$ContentPlaceHolder1$drpYuklemeAdres" onChange={(e)=>{setBosaltmaAdresiYetkiliTelefon(e.target.value)}} className="form-select" id="ContentPlaceHolder1_drpYuklemeAdres" class="selectpicker" required="required"  />

                                         
                                     
                                       </div>
                                   </div>  
                                   <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12">
                                       <div class="form-box">
                                           <span class="form-label">Tahmini Boşaltma Tarihi</span>
                                           <input name="ctl00$ContentPlaceHolder1$drpYuklemeAdres" onChange={(e)=>{setBosaltmaTarihi(e.target.value.split('-').reverse().join("/"))}} min={yuklemeTarihi?.split('/').reverse().join("-")} type="date" className="form-select" id="ContentPlaceHolder1_drpYuklemeAdres" class="selectpicker" required="required"  />

                                            
                                     
                                       </div>
                                   </div>       
                                   <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12">
                                       <div class="form-box">
                                           <span class="form-label">Tahmini Boşaltma Saati</span>
                                           <input name="ctl00$ContentPlaceHolder1$drpYuklemeAdres" onChange={(e)=>{setBosaltmaSaati(e.target.value)}} type="time" className="form-select" id="ContentPlaceHolder1_drpYuklemeAdres" class="selectpicker" required="required"  />

                                            
                                     
                                       </div>
                                   </div> 
                                   
                                   <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12">
                                       <div class="form-box">
                                           <span class="form-label">Alıcı TcNo/Vergi No</span>
                                           <input type="number" placeholder="İsteğe Bağlı" name="ctl00$ContentPlaceHolder1$drpYuklemeAdres" onChange={(e)=>{setAliciTcVergiNo(e.target.value)}}  className="form-select" id="ContentPlaceHolder1_drpYuklemeAdres" class="selectpicker" required="required"  />

                                            
                                     
                                       </div>
                                   </div> 
                                   </div>
                              
                                                                     <div class="clearfix"></div>
                                        <a class="btn btn-warning prevBtn pull-left" type="button" href="#step-3">« ÖNCEKİ ADIM</a>
                            <a class="btn btn-success nextBtn" type="button" href="#step-5">DEVAM ET »</a>
                                      </section> </div></div>
                                    
                                   
                                      <div class="panel panel-primary setup-content"  id="step-5" style={locationHash=="#step-5" ? {display:"contents"} : {display:"none"}}>
                                    
                            <div class="panel-body">
      
                                <section>
                                    <div id="ContentPlaceHolder1_upBosaltma">

                                        <h2 style={{backgroundColor:"#eee",padding:15,fontSize:22,fontWeight:700,borderRadius:5,color:"black"}}>5. YÜK KAYDINI TAMAMLA</h2>
                                </div>
                                {errorMessage ?  <div class="alert alert-danger" role="alert">
{errorMessage}
</div> : "" }  
                            <div class="alert alert-info" role="alert">
 Yüke Eklemek İstediğiniz Bir Açıklama Varsa Giriniz.
</div>

                                <textarea onChange={e=>setYukAciklamasi(e.target.value)} className="form-control" ></textarea>

                                      
                                                                        <div class="clearfix"></div>
                           
                            <a onClick={()=>formSubmit()} class="btn btn-success nextBtn" type="button" href="#step-5">TAMAMLA</a>
                                      </section> </div></div>



                            </div>
                 
                 
            
                 
                            </div></div>
                 
                 
                 
                 </div></div>)
   
}
export default AracTalep;