import React,{useState,useEffect} from "react";
import ReactPaginate from "react-paginate";
import { useContext } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "./Auth";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import iller from "./iller";
import Popup from 'reactjs-popup';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';

const Seferlerim = () =>{
  const containerStyle = {
    width: '1050px',
    height: '800px'
  };

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyAGCO6gi8rDbt4AFdMP9VF2EkUQmbw7M00"
  })
  const [map, setMap] = useState(null)
  

    const {userType} = useContext(AuthContext);
    const {token}  = useContext(AuthContext);
    const [seferler,setSeferler] = useState([])
    const [loading,setLoading] = useState(true);
    const [currentPage,setCurrentPage] = useState(1);
    const [itemCount,setItemCount] =useState(0);

    const [yuklemeIlAdi,setYuklemeIlAdi] = useState("");
    const [bosaltmaIlAdi,setbosaltmaIlAdi] = useState("");
    const [soforAdi,setSoforAdi] = useState(""); 
    const [gondericiUnvan,setGondericiUnvan] = useState("");
    const [aliciUnvan,setAliciUnvan] = useState(""); 
    const [user,setUser] = useState("");
    const [popupOpen,setPopupOpen] = useState(false);
    const filtreleriTemizle = () =>{
        setbosaltmaIlAdi("");
        setYuklemeIlAdi("");
        setSoforAdi("");
      setAliciUnvan("");
      setGondericiUnvan("");




      }
   
 
      const AnyReactComponent = ({ text }) => <div>{text}</div>;

    const seferiTamamla=(id)=>{
        fetch("https://apipartner.yuksis.com/api/shipment/CompleteShipmentFromWeb",{
            method:"Post",
            withCredentials:true,
            body: JSON.stringify({id:id}),
            headers: {
                "content-type": "application/json",
                "Authorization": `Bearer ${token}`,
                
                
            }}).then(response=>response.json()).then(response=>{if(response.statusCode==200){
                toast.success("Sevkiyat Tamamlandı");
           

               
            }
       
        }).catch(error=>console.log(error));
    }
    useEffect(()=>
    {
      setLoading(true);
        fetch(`https://apipartner.yuksis.com/api/shipment/GetShipmentsByLoadOwner?sayi=${currentPage}&&GondericiIl=${yuklemeIlAdi}&&aliciIl=${bosaltmaIlAdi}&&gondericiUnvan=${gondericiUnvan}&&aliciUnvan=${aliciUnvan}&&SoforAdi=${soforAdi}`,{
            method: "Get",  
            withCredentials:true,
           
            headers: {
              "content-type": "application/json",
              "Authorization": `Bearer ${token}`,
          
        }}).then(response=>response.json()).then(response=>{
          if(response.data.itemCount!=0){
            setSeferler(response.data.yukListesi.$values);setLoading(false);setItemCount(response.data.itemCount);
            
            console.log(response)
          }
          else{
                setLoading(false);
          }
        
    
    
    
    }).catch(error=>{setLoading(false);console.log(error)})},[currentPage,itemCount,bosaltmaIlAdi,yuklemeIlAdi,soforAdi,gondericiUnvan,aliciUnvan]);
    const konumuBul = (numberAspiredUser) =>{
  
        fetch(`https://apipartner.yuksis.com/api/auth/GetUserByPhoneNumber?phoneNumber=${numberAspiredUser}`,{
            method: "GET",  
            withCredentials:true,
       
            headers: {
              "content-type": "application/json",
              "Authorization": `Bearer ${token}`
        }}).then(response=>response.json()).then(response=>{
      
            setUser(response.data);
           
        }).then(response=>setPopupOpen(true)).catch(error=>console.log(error))}
 
     
   return(
   
   
   
   <div class="row" >        
    <div class="col">
    <Popup
  
 open={popupOpen}
  position="right center" 
  closeOnDocumentClick={false}
  contentStyle={{justifyContent:"center",alignContent:"center",alignItems:"center",width:1060}}
>   Kapat  <button type="button" onClick={()=>setPopupOpen(false)} style={{display:"inline-block"}} class="btn-close" aria-label="Close"></button>
<h5 style={{display:"inline-block",marginLeft:300,color:"gray",marginTop:120}}>Konum İzni : {user.konumIzni}</h5>
  <span><GoogleMap
        mapContainerStyle={containerStyle}
        center={{
    lat: user.latitude,
    lng: user.longtitude
  }}
        zoom={10}
        
     
      >
        <Marker position={{
    lat: user.latitude,
    lng: user.longtitude,
    
    
  }} label={`Konum Son Görülme : ${user.locationLastSeen}`}></Marker>
        <></>
      </GoogleMap></span>
 
      </Popup>
        
    <div style={{marginTop:10}} className="two-menu">
                               
      
                               <ol className="breadcrumb">
                             
                                   <li><Link to="/" href="default.aspx" title="Anasayfa">Anasayfa </Link></li>
                                   <li> /Seferlerim</li>      
                               </ol>     
                               <hr></hr>  
                           </div>  

        <div id="ContentPlaceHolder1_upIcerikler">
            <div class="row">
                <div class="col-lg-12">
                   
                </div>
            </div>
            <div id="ContentPlaceHolder1_Panel1">
            <div class="row" style={{marginTop:15,display:"flexbox"}} id="filterbox">
               
            </div>
            <div class="row">
                <div class="col-lg-12 tab-list">
              
                    <ul class="nav nav-tabs" role="tablist">
                    <tr style={{marginLeft:25}}>
                                    
                                    <th>
                                    <input class="form-control" value={soforAdi} onChange={e=>setSoforAdi(e.target.value)} placeholder="Şoför Adı Soyadi"/></th>
                                    <th> <input class="form-control" value={gondericiUnvan} onChange={e=>setGondericiUnvan(e.target.value)} placeholder="Gönderici Unvan"/></th>
                                    <th><input class="form-control" value={aliciUnvan} onChange={e=>setAliciUnvan(e.target.value)} placeholder="Alici Unvan"/></th>
                                    <th></th>
                                    <th>    <select onChange={e=>setYuklemeIlAdi(e.target.value)} class="form-select"  value={yuklemeIlAdi} placeholder="Yükleme Yeri">
<option disabled selected value="">{yuklemeIlAdi.length==0  ? "Lütfen Yükleme İlini Seçiniz"  : yuklemeIlAdi}</option>
{iller.map(il=>{
return (<option value={il.name}>{il.name}</option>)
})}

</select></th>
<th >  <select class="form-select" value={bosaltmaIlAdi} onChange={e=>setbosaltmaIlAdi(e.target.value)}>
  <option disabled selected value="">{bosaltmaIlAdi.length==0  ? "Lütfen Boşaltma İlini Seçiniz"  : bosaltmaIlAdi}</option>
{iller.map(il=>{
return (<option value={il.name}>{il.name}</option>)
})}

</select></th>
<th> <button onClick={()=>filtreleriTemizle()} style={{backgroundColor:"#ef233c"}} type="button" className="btn btn-danger">Filtreleri Temizle</button></th>
<th></th>
<th></th>
<th></th><th></th><th></th>
                                    </tr>
                    </ul>
                   
                  
                    <div class="tab-content">
                   
    
                        <div role="tabpanel" class="tab-pane active"  id="home">
                       <table  class="table table-striped table-responsive">
                    
                                        <tbody>
                            
                                    
                                  
                                        <tr>
                                           
                                        <th  >Yük Detay</th>
                                            <th style={{width:150}} >Şoför Ad Soyad</th>
                                            <th >Gönderici Unvan</th>   
                                            <th >Alıcı Unvan</th>   
                                            <th >GSM</th>
                                            <th >Yükleme Noktası-Varış Noktası</th>
                                           
                                            <th class="hidden-xs">Yükleme Tarih/Saat</th>
                                            <th class="hidden-xs" >Boşaltma Tarih/Saat</th>
                                            <th style={{width:50}} >Şöfor Bildirimi</th>
                                            <th  >Durum</th>
                                            <th >Tamamla</th>
                                     
                                           
                                        </tr>
                                    
 {seferler.map(sefer=>{
     return( 
      loading ? <div  class="spinner-border"   role="status">
  <span class="sr-only">Loading...</span>
</div> : 
       <tr style={{verticalAlign:"center"}}>
                                      
      <td style={{height:40,verticalAlign:"middle"}}> <Link to={`/AracTaleplerim/${sefer.loadId}`}   className="btn btn-warning" style={{height:40,verticalAlign:"middle"}} >Yük Detayları</Link></td>
                                      <td style={{height:40,verticalAlign:"middle"}} >{sefer.soforAdi}</td>
                                      <td style={{height:40,verticalAlign:"middle"}}>{sefer.load.senderName}</td>
                                      <td style={{height:40,verticalAlign:"middle"}}>{sefer.load.receiverName}</td>
                                      <td style={{height:40,verticalAlign:"middle"}}>{sefer.phoneNumberAspiredUser}{userType=="admin"  ? <button type="button" style={{marginLeft:5}} onClick={()=>{window.open(`https://yurtcagri.alo-tech.com/api/?function=click2call&phonenumber=0${sefer.phoneNumberAspiredUser}&apptoken=ahRzfm11c3RlcmktaGl6bWV0bGVyaXIfCxISVGVuYW50QXBwbGljYXRpb25zGICAhLHB4tYLDKIBFnl1cnRjYWdyaS5hbG8tdGVjaC5jb20`, "", "width=200,height=100");
}} class="btn btn-success">Ara</button> : ""}</td>
                                      <td style={{height:40,verticalAlign:"middle"}}><div style={{display:"flex",flexDirection:"column"}}><p>{sefer.load.senderAdressCity}/{sefer.load.senderAdressTown}</p><p>{sefer.load.receiverCity}/{sefer.load.receiverAdressTown}</p></div></td>
                                    
                                      <td style={{height:40,verticalAlign:"middle"}} class="hidden-xs">{sefer.load.planningLoadDate}/{sefer.load.planningLoadTime}</td>
                                      <td style={{height:40,verticalAlign:"middle"}} class="hidden-xs">{sefer.load.planningUnloadDate}/{sefer.load.planningUnloadTime}</td>
     
                                     {sefer?.isDriverApproved==false  ?  <td style={{height:40,verticalAlign:"middle"}} class="hidden-xs"><div class="alert alert-warning" role="alert">
Şoförün Sevkiyatı Başlatması Bekleniyor 
</div></td>: sefer?.isDriverApproved==true && sefer?.isDriverCompletedApproved == false ?   <td style={{height:40,verticalAlign:"middle"}} class="hidden-xs"><div class="alert alert-info" role="alert">
Sevkiyatta
</div></td> : sefer?.isDriverApproved==true && sefer?.isDriverCompletedApproved == true && sefer?.isCompleted==false ?  <td style={{height:40,verticalAlign:"middle"}}><div class="alert alert-success" role="alert">
<p style={{textAlign:"center"}}>Şoför Sevkiyatı Tamamladığını Bildirdi.</p>
</div></td> : sefer?.isDriverCompletedApproved == true && sefer?.isCompleted==true ? <td style={{height:40,verticalAlign:"middle"}} class="hidden-xs"><div class="alert alert-success" role="alert">
-
</div></td>   : ""  } 
                                      {sefer?.isCompleted  ? <td style={{height:40,verticalAlign:"middle"}}><span class="btn-sm btn-success">Taşıma Tamamlandı</span></td>  : sefer.isDriverApproved==false ? <td style={{height:40,verticalAlign:"middle"}}><button style={{marginTop:12,textAlign:"center"}} disabled="true" class="btn alert-primary" role="alert">
Sevkiyat Başlamamış
</button></td> : <td style={{height:40,verticalAlign:"middle"}}><button type="button"  class="btn btn-info btn-sm" onClick={()=>konumuBul(sefer.phoneNumberAspiredUser)}>Şoför Konumunu Gör</button></td> }
                                     {sefer.isCompleted ? <td style={{height:40,verticalAlign:"middle"}}><a href="#" class="btn btn-primary btn-lg disabled" role="button" aria-disabled="true">Tamamlandı</a></td> : <td style={{height:40,verticalAlign:"middle"}}><button disabled={sefer?.isDriverApproved==true && sefer?.isDriverCompletedApproved == false && sefer?.isCompleted==false ? true : false} onClick={()=>seferiTamamla(sefer.id)} type="button" class="btn btn-outline-success btn-sm">Seferi Tamamla</button></td> } 
                                  </tr>   
                              
                              

                              
                              )
 })}
                                
     
                             
                                 
                               
                                    </tbody></table>





   <nav style={{}} aria-label="Page navigation example">
      <ul class="pagination justify-content-center">
      
      <ReactPaginate
            pageCount={itemCount/10}
            previousLabel={<svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="currentColor" class="bi bi-arrow-left-square-fill" viewBox="0 0 16 16">
  <path d="M16 14a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12zm-4.5-6.5H5.707l2.147-2.146a.5.5 0 1 0-.708-.708l-3 3a.5.5 0 0 0 0 .708l3 3a.5.5 0 0 0 .708-.708L5.707 8.5H11.5a.5.5 0 0 0 0-1z"/>
</svg>}
            nextLabel={<svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="currentColor" class="bi bi-arrow-right-square-fill" viewBox="0 0 16 16">
  <path d="M0 14a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2a2 2 0 0 0-2 2v12zm4.5-6.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5a.5.5 0 0 1 0-1z"/>
</svg>}
            breakLabel={"..."}
            onPageChange={(data)=>{setCurrentPage(data.selected+1)}}
            containerClassName={"pagination"}
            pageClassName={"page-item"}
            pageLinkClassName={"page-link"}
            activeClassName = {"active"} 
          />
          
      
           
   
     
      </ul>
        
     
      
     
    </nav>       
                                
                            <div class="clearfix"></div>
                           
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-12">

                       
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade bd-example-modal-lg" tabindex="-1" id="modalSurucuKonum" role="dialog" aria-labelledby="SurucuKonum" aria-hidden="true">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title" id="SurucuKonum"><strong>Sürücü Konumu</strong></h4>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div class="modal-body">
                   
                        <div id="ajaxsonuc"></div>
                    
                    </div>
                </div>
            </div>
        </div>
       
    </div></div></div>)
}
export default Seferlerim;