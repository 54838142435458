import React, { useContext, useState } from 'react';
import {
    Routes,
    Route,
    NavLink,
    Navigate,
    useNavigate,
  } from 'react-router-dom';

import { AuthContext } from "./Auth";

const AdminPrivateRoute = ({children}) => {
    let {token,userType} = useContext(AuthContext);
   
    if(!token || userType!="admin"){
        return <Navigate to="/" replace/>;
    }
    return children;
};

export default AdminPrivateRoute;