import React, { useContext, useState } from 'react';
import {
    Routes,
    Route,
    NavLink,
    Navigate,
    useNavigate,
  } from 'react-router-dom';

import { AuthContext } from "./Auth";

const PrivateRoute = ({children}) => {
    let {token} = useContext(AuthContext);
   
    if(!token){
        return <Navigate to="/GirisYap" replace/>;
    }
    return children;
};

export default PrivateRoute;