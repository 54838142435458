const yukturleri = [  {key:307, label :"Ana metaller; fabrikasyon metal ürünleri (makine ve ekipman hariç)" },
{key:308, label :"Başka yerde sınıflandırılmamış makine ve ekipmanlar; büro makineleri ve bilgisayarlar vb" },
{key:201, label :"Beyaz eşya / Elektronik ürünler" } ,   
{key:202, label :"Bozulabilir gıda" },
{key:210,label:"Diğer"},
{key:303,label:"Gıda ürünleri, içecekler ve tütün"},
{ key:315 ,label:"Gruplandırılmış mallar: birarada taşınan karışık kategorilerdeki mallar" },
{key:205,label:"Hammadde / Maden ürünleri / İnşaat malzemesi"},
{key:206, label:"İçecekler / Kuru Gıda / Hububat"},
{key:311 , label:"İkincil hammaddeler; belediye atıkları ve diğer atıklar"},
{key:200,label:"Kereste, ağaç ürünleri ve mantar ürünleri (mobilya hariç)"},
{key:305,label:"Kimyasal ürünler, insan yapımı elyaflar; kauçuk ve plastik ürünler; nükleer yakıt"},
{key:313,label:"Malların taşınmasında kullanılan ekipman ve malzemeler"},
{key:312,label:"Mektup,Koli"} ,
{key:302,label:"Metal cevherleri ve diğer maden ve madencilik ürünleri; turba; uranyum ve toryum"} ,
{key:310,label:"Mobilya ve b.y.s diğer imalat malları"},
{key:208,label:"Otomotiv / İş makineleri"},
{key:300,label:"Tarım, avcılık ve ormancılık ürünleri; balık ve diğer balıkçılık ürünleri"},
{key:309,label:"Taşımacılık ekipmanları"}, 
{key:301,label:"Taşkömürü ve linyit; ham petrol ve doğal gaz"},
{key:209, label:"Tekstil ve tekstil ürünleri; deri ve deri ürünleri"},
{key:203,label:"Canlı Hayvan"},
{key:204,label:"Ev Büro Eşyası"},
{key:306,label:"Diğer metalik olmayan mineral ürünler"},
{key:314, label:"Hanehalkı ve büro eşyalarının taşınması; yolcuların yanlarındaki bagajları ve eşyaları"},
{key:207,label:"kargo"}, 
{key:304,label:"Kok kömürü ve rafine edilmiş petrol ürünleri"}    ]
export default yukturleri;