
import React from "react";
import { useState,useEffect } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const FatureYukleme = ()=>{
const [fatura,setFatura] = useState();
const [faturaYuklemeHata,setFaturaYuklemeHata] = useState();
const [yukleniyorButton,setYukleniyorButton] = useState()
const [imageUrl,setImageUrl]  = useState();
const [file,setFile] = useState();
const token =  localStorage.getItem("token");
const saveFile = (e)=>{
    setFile(e.target.files[0])
    console.log(e);

}
const uploadFile = () =>{
    if(file==null){
        setFaturaYuklemeHata("Lütfen Önce Resmi Seçiniz")
    }
    else{
 
   var myFile = new FormData();
   myFile.append('file',file);
   setYukleniyorButton(true)
 
   fetch("https://apipartner.yuksis.com/api/auth/FaturaYukle",{
    method: "post",
    withCredentials: true,

  
    headers: {
         
                
        "Authorization": `Bearer ${token}`
    
 
        
    },
    body: myFile
}).then(response=>response.json()).then(response=>{
    toast.success("Fatura Başarıyla Eklendi.");


    setFaturaYuklemeHata();
  
    setYukleniyorButton(false)


}).catch(error=>{ setYukleniyorButton(false);setFaturaYuklemeHata("Yüklenemedi.");console.log(error)});
}}

useEffect(()=>{
fetch("https://apipartner.yuksis.com/api/uetds/GetFaturaImage",{
    method:"get",
    headers: {
         
                
        "Authorization": `Bearer ${token}`
    
 
        
    }
}).then(response=>response.json()).then(response=>setImageUrl(response.message));
},[yukleniyorButton])
return (


    <div className="three-menu">
        
 
    <ol className="breadcrumb">
    <div style={{boxShadow:"5px 5px 12px #888888",borderRadius:6,backgroundColor:"#f8f7ff"}} >
<div class="card-body" ><div  className="container">
<h5 className="page-title">Fatura Ekle</h5>
{faturaYuklemeHata ? <div class="alert alert-danger" role="alert">
{faturaYuklemeHata} 

</div>: "" }   


<div className="row">

<div style={{marginTop:32}} className="col-8"> {yukleniyorButton ? <button class="btn btn-primary" type="button" disabled>
<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
Yükleniyor
</button> :  <button  type="button" style={{color:"black"}} class="btn btn-info btn-sm" onClick={uploadFile}><p style={{display:"inline",color:"white",fontWeight:"bold"}}>Faturayı Ekle</p></button>  } <input   style={{width:250,display:"inline",marginLeft:20}} className="form-control form-control-sm" type="file"   onChange={saveFile}></input>
    </div>


 
<div  className="col-4"><p style={{color:"black"}}><div className="container"><div className="row">
<div className="column" style={{marginTop:22}}><a style={{color:"#029ede",fontWeight:"bolder"}}></a></div>
<div className="column"><p style={{fontWeight:"bolder"}}>Fatura Eklemek İçin Resim Formatındaki Dosyanızı Seçerek Fatura Yükle Ekranına Basınız.</p></div>

</div></div>
</p></div>


</div>

</div>
   
   <img height="1300" src={`https://apipartner.yuksis.com/Images/${imageUrl}`}></img>
    </div>    
</div> 
 

    </ol>
</div>


);

}

export default FatureYukleme;