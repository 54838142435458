

import React,{useState,useEffect} from "react";
import { Link } from "react-router-dom";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



const BelgeAktiflikleriKontrolEt = ()=>{

    const token =  localStorage.getItem("token");
    const [file,setFile] = useState();  
    const [fileName,setFileName] = useState();  
    const [topluTalepErrorMessage,setTopluTalepErrorMessage] = useState();
    const [yukleniyorButton,setYukleniyorButton] = useState(false);  
    const saveFile = (e)=>{
        setFile(e.target.files[0])
        
    
    }
    
    
    const uploadFile = () =>{
        if(file==null){
            setTopluTalepErrorMessage("Lütfen Önce Excel Dosyasını Seçiniz")
        }
        else{
     
       var myFile = new FormData();
       myFile.append('file',file);
       setYukleniyorButton(true)
     
       fetch("https://apipartner.yuksis.com/api/truckTrailer/AracBelgeAktiflikKontrol",{
        method: "post",
        withCredentials: true,
       
    
      
        headers: {
             
                      
            "Authorization": `Bearer ${token}`
        },
        body: myFile
    }).then(response=>response.json()).then(response=>{
        toast.success("Belge Yetkilendirmesi Bulunan Araçlar Aktif Hale Getirildi.");
    
    
        setTopluTalepErrorMessage();
      
        setYukleniyorButton(false)
    
    
    }).catch(error=>{ setYukleniyorButton(false);setTopluTalepErrorMessage("Lütfen excel formatında bir belge yükleyiniz ve doğru bir şablon kullanınız.");console.log(error)});
    }}
    return (








        <div  className="col" >
            <div style={{marginTop:50}} className="col-lg-8 tab-list">
   
   
      
 
   <div style={{marginTop:10}} className="two-menu">
                      

                       <ol className="breadcrumb">
                     
                           <li><Link to="/" href="default.aspx" title="Anasayfa">Anasayfa </Link></li>
                           <li> /Belge Kontrol</li>      
                       </ol>     
                       <hr></hr>  
                   </div></div>
    <div className="three-menu">
        
 
                                 <ol className="breadcrumb">
                                 <div style={{boxShadow:"5px 5px 12px #888888",borderRadius:6,backgroundColor:"#f8f7ff"}} >
   <div class="card-body" ><div  className="container">
   <h5 className="page-title">Belge Kontrol</h5>
   {topluTalepErrorMessage ? <div class="alert alert-danger" role="alert">
 {topluTalepErrorMessage} 
 
 </div>: "" }   
 
                
          <div className="row">
 
          <div style={{marginTop:32}} className="col-8"> {yukleniyorButton ? <button class="btn btn-primary" type="button" disabled>
   <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
   Yükleniyor.(5-10 dk sürebilir)
 </button> :  <button  type="button" style={{color:"black"}} class="btn btn-info btn-sm" onClick={uploadFile}><p style={{display:"inline",color:"white",fontWeight:"bold"}}>Araçları Ekle</p></button>  } <input  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" style={{width:250,display:"inline",marginLeft:20}} className="form-control form-control-sm" type="file"   onChange={saveFile}></input>
                                 </div>
       
         
                              
          <div  className="col-4"><p style={{color:"black"}}><div className="container"><div className="row">
          <div className="column" style={{marginTop:22}}><a style={{color:"#029ede",fontWeight:"bolder"}}>Toplu Araç Ekleme Excel Sablonunu İndirmek İçin Tıklayınız</a></div>
          <div className="column"><p style={{fontWeight:"bolder"}}>Not: Başarılı bir şekilde toplu Araç Eklemek  için lütfen excel şabloununu kullanınız veya örnek alınız.</p></div>
          
          </div></div>
 </p></div>
        
 
          </div>
 
   </div>
                                
                                
                                 </div> 
 </div> 
                                 
                                 
                                 </ol>
                             </div>
 </div>
    )
}
export default BelgeAktiflikleriKontrolEt;