
import React,{useEffect, useState,useRef} from "react";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import iller from "./iller";
import ReactPaginate from "react-paginate";
import { Link ,Outlet,useLocation} from "react-router-dom";
import { Button } from "@mui/material";
import Popup from 'reactjs-popup';



const SettingsPage = () =>{
    const [uetdsOnOff,setUetdsOnOff]  = useState();
    const [uetdsOnOffYukTuru,setUetdsOnOffYukTuru]  = useState();
  
    const token = localStorage.getItem("token");
    useEffect(()=>{
    
        fetch("https://apipartner.yuksis.com/api/uetds/GetUetdsOnOrOff",{
            method: "Get",
            withCredentials:true,
            
            headers: {
              "content-type": "application/json",
              "Authorization": `Bearer ${token}`
          }}).then(response=>response.json()).then(response=>{console.log(response);setUetdsOnOff(response.data.onOffUetds)}).catch(error=>{console.log(error)})},[])
        
          const changeStatusOffUetds = (bool) =>{
           
      
              fetch(`https://apipartner.yuksis.com/api/uetds/ChangeUetdsOnOffStatus`,{
                  method: "POST",
                  withCredentials:true,
                  body: JSON.stringify({trueOrFalse:bool}),
                  
                  headers: {
                    "content-type": "application/json",
                    "Authorization": `Bearer ${token}`,
                }}).then(response=>response.json()).then(response=>
                  {if(response.statusCode==200){
                    toast.success("İşlem Başarılı",{autoClose: 500})
                    setUetdsOnOff(bool)
              
              
              }})
                 
                  
                  
                .catch(error=>{;console.log(error)});
                 
          }
      
           useEffect(()=>{
          
        fetch("https://apipartner.yuksis.com/api/uetds/GetIrsalyeYukTuruOnOff",{
            method: "Get",
            withCredentials:true,
            
            headers: {
              "content-type": "application/json",
              "Authorization": `Bearer ${token}`
          }}).then(response=>response.json()).then(response=>{console.log("onOffYukturr",response);setUetdsOnOffYukTuru(response.data.onOffUetds)}).catch(error=>{console.log(error)})},[])
          const changeStatusOffYukTuruUetds = (bool) =>{
        
           
      
            fetch(`https://apipartner.yuksis.com/api/uetds/ChangeIrsalyeYukTuruOnOff`,{
                method: "POST",
                withCredentials:true,
                body: JSON.stringify({trueOrFalse:bool}),
                
                headers: {
                  "content-type": "application/json",
                  "Authorization": `Bearer ${token}`,
              }}).then(response=>response.json()).then(response=>
                {if(response.statusCode==200){
                  toast.success("İşlem Başarılı",{autoClose: 500})
                  setUetdsOnOffYukTuru(bool)
            
            
            }})
               
                
                
              .catch(error=>{console.log(error)});
               
        }

    return (
<div class="container">
<div class="row justify-content-center">
    <div class="col-12 col-lg-10 col-xl-8 mx-auto">
        <h2 class="h3 mb-4 page-title">Ayarlar</h2>
        <div class="my-4">
            <ul class="nav nav-tabs mb-4" id="myTab" role="tablist">
                <li class="nav-item">
                    <a class="nav-link active" id="contact-tab" data-toggle="tab" href="#contact" role="tab" aria-controls="contact" aria-selected="false">Ege Yurt Muavin</a>
                </li>
            </ul>
            <h5 class="mb-0 mt-5"></h5>
            <p></p>
            <hr class="my-4" />
            <strong class="mb-0">Uetds Ayarları</strong>
            <p>Uetds Mobil Uygulama Ayarlarını Buradan Yönetebilirsiniz.</p>
        
           
               
                
                <button style={{marginBottom:10,marginLeft:10,boxShadow: '3px 3px 9px #F4AAB9',display:"inline-block"}} class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton"  data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >
   İrsalye Modu / Uetds Modu
  </button>
  <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
    <a  class={uetdsOnOff==false ? "dropdown-item active" : "dropdown-item"} onClick={()=>changeStatusOffUetds(false)} >İrsalye Modu</a>
    <a class={uetdsOnOff==true ? "dropdown-item active" : "dropdown-item"} onClick={()=>changeStatusOffUetds(true)}  >Uetds Modu</a>
    
  </div>

  
  <button type="button"  style={{marginBottom:10,marginLeft:5,boxShadow: '3px 3px 9px #F4AAB9'}} class="btn btn-info" data-toggle="tooltip" data-placement="top" title="Soldaki buton muavin uygulamasındaki bildirim modunu değiştirmek için kullanılmaktadır. Gerekli olmadıkça değiştirmeyiniz.">
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-circle-fill" viewBox="0 0 16 16">
  <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"/>
</svg>
</button>
<div style={{display:"inline-block",boxShadow: '4px 4px 9px #F4AAB9',padding:5,borderRadius:10,marginLeft:10,marginBottom:10,marginTop:20,backgroundColor:"white",display:"block",width:300}}>
  <label style={{color:"black",marginRight:10}} >Uetds Yük Türü Modu</label>

   
  <a type="button" className={uetdsOnOffYukTuru==true ? "btn btn-success" : "btn btn-danger" } onClick={()=>changeStatusOffYukTuruUetds(true)} style={{color:"white"}} >Açık</a>
  <a type="button" className={uetdsOnOffYukTuru==false ? "btn btn-success" : "btn btn-danger" } onClick={()=>changeStatusOffYukTuruUetds(false)} style={{marginRight:10,marginLeft:10,color:"white"}} value={false}>Kapalı</a>


    
        
    
            </div>
            
           
        </div>
    </div>
</div>

</div>

    )
}

export default SettingsPage;