

import { initializeApp } from "firebase/app";
import { getMessaging,getToken,onMessage } from "firebase/messaging";
import "firebase/messaging";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import { Link } from "react-router-dom";
// TODO: Replace the following with your app's Firebase project configuration
// See: https://firebase.google.com/docs/web/learn-more#config-object
const firebaseConfig = {
    apiKey: "AIzaSyDRr_SeCkGIEDXPEsGbkL4p2q9nBWVDRIQ",
    authDomain: "myreactwebapp.firebaseapp.com",
    projectId: "myreactwebapp",
    storageBucket: "myreactwebapp.appspot.com",
    messagingSenderId: "831668521960",
    appId: "1:831668521960:web:dd288061e6b99fcb90725d",
    measurementId: "G-T49LZ18PST"
  };
// Initialize Firebase



// Initialize Firebase Cloud Messaging and get a reference to the service
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);


export function requestPermission(token) {
    
    console.log('Requesting permission...');
    Notification.requestPermission().then((permission) => {
      if (permission === 'granted') {
        console.log('Notification permission granted.');
      
        getToken(messaging,{vapidKey :'BBsIbI_AQ9_Nndewacorob9skHcOBqtum33m6MawWE1iuGYtRhMZ0smgcksPBSJ5LcR3cu__7o4QLwWRXok0Aws' }).then((currentToken)=>{
            if(currentToken){
                console.log('currentToken',currentToken);
                if(token!=null){
                    fetch("https://apipartner.yuksis.com/api/user/AddToUserClientId",{
                        method: "POST",
                     
                        body: JSON.stringify({clientId : currentToken }),
                        headers: {
                          "content-type": "application/json",
                         
                          "Authorization": `Bearer ${token}`
                      }}).then(response=>response.json()).then(response=>{console.warn(response)})
                }
            }
            else{
                console.log('cant token')
            }
           
        }
        );}
    else{
        console.log("donthaveperssion")
    }
    })}

    export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      toast(<Link to={`/AracTaleplerim/${payload.notification.body.split(" ")[0]}`}>{payload.notification.body}</Link>,{
        autoClose:false,theme:"light"
    });
      resolve(payload)
    });
});





        requestPermission(); 


     