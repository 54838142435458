import React,{useState,useEffect} from "react";
import { Link } from "react-router-dom";
import { useContext } from "react";
import { AuthContext } from "./Auth";
import moment from "moment";
import 'moment/locale/tr'
import { onMessageListener } from "../messaging_init_in_sw";
const Panel  = () => {
const [sayfaSayisi,setSayisi]=useState(1);
const [aspiredData,setAspiredData] = useState([]);
moment.locale("tr"); 
    useEffect(()=>{
       var token = localStorage.getItem("token");
        fetch(`https://apipartner.yuksis.com/api/aspire/getaspiredLoadByWaitingLoadOwner?sayi=${sayfaSayisi}`,{
            method: "Get",
            withCredentials:true,
            
            headers: {
              "content-type": "application/json",
              "Authorization": `Bearer ${token}`
          }}).then(response=>response.json()).then(response=>{console.log(response);setAspiredData(response.data.$values)}).catch(err=>console.log(err));


    },[])
    const {userType} = useContext(AuthContext);
    return (  <div className="row" >
    <div className="content" >            
    
        <div className="container">
        { userType=="admin" ? <div className="row" style={{borderBottom:1}}>
                <div className="menu-title" style={{fontSize:"medium"}}><strong></strong></div>
               
                <div className="col-md">
                
                </div>
        
             

           
                
              

                
              
            </div>: ""}
        


 
            <div className="column">    
            <div className="row">
            <i className="fa fa-light fa-circle-info"></i>
               <div style={{ border: `2px solid #f9f3f0`,borderColor:"#f8f2db",backgroundColor:"#f8f2db",borderRadius:10,padding:10,margin:10,alignItems:"center",justifyContent:"center"}}><h5 style={{textAlign:"center",textAlignVertical:"center",marginTop:10}}>Ege Yurt Muavin Beta Sürümünü Görüntülemektesiniz. Uygulamamız Geliştirilmeye Devam Etmektedir.</h5></div>  
                <div className="menu-title" style={{fontsize:"medium"}}><strong>Panel</strong></div>      <hr></hr>   
              
             {userType=="user" || userType=="admin" ?     <div className="col-lg-2 col-sm-4 col-xs-6">
                    <Link to="/AracTalep#step-1" href="#step-1">
                        <div className="box-menu">
                            <div className="fas fa-truck-loading"></div>
                            <span>Araç Talebi Oluştur</span>
                        </div>
                    </Link>
                </div> : "" }
           


                {userType=="user" || userType=="admin" ?     <div className="col-lg-2 col-sm-4 col-xs-6">
                    <Link to="/AracTaleplerim">
                        <div className="box-menu">
                            <div className="fas fa-truck-moving"></div>
                            <span>Araç Taleplerim</span>
                        </div>
                    </Link>
                </div> : ""}

     
                {userType=="user" || userType=="admin" ?   <div className="col-lg-2 col-sm-4 col-xs-6">
                    <Link to="Seferlerim" >
                        <div className="box-menu">
                            <div className="fas fa-road"></div>
                            <span>Seferler</span>
                        </div>
                    </Link> 
</div> : ""}
               { userType=="admin" ? <div className="col-lg-2 col-sm-4 col-xs-6">
                    <Link to="YeniSurucuEkle">
                        <div className="box-menu">
                            <div className="fas fa-user"></div>
                            <span>Yeni Üye Kaydı</span>
                        </div>
                    </Link>
                </div> : "" } 
               
       


     
                { userType=="admin" ?     <div className="col-lg-2 col-sm-4 col-xs-6">
                    <Link to="AracKaydi">
                        <div className="box-menu">
                            <div className="fas fa-truck"></div>
                            <span>Yeni Araç Kaydı</span>
                        </div>
                    </Link>
                </div> : "" }
           

                { userType=="admin" ?  <div className="col-lg-2 col-sm-4 col-xs-6">
                    <Link to='Uyeler'>
                        <div className="box-menu">
                            <div className="fas fa-users"></div>
                            <span>Üyeler</span>
                        </div>
                    </Link>
                </div> : ""}


                { userType=="admin" ?  <div className="col-lg-2 col-sm-4 col-xs-6">
                   <Link to="Araclar">
                        <div className="box-menu">
                            <div className="fas fa-truck-moving"></div>
                            <span>Araçlar</span>
                        </div>
                        </Link>
                </div> : ""}
    
                { userType=="admin" ?  <div className="col-lg-2 col-sm-4 col-xs-6">
                   <Link to="FaturaYukle">
                        <div className="box-menu">
                            <div className="fa fa-solid fa-file-invoice"></div>
                            <span>Fatura Yükle</span>
                        </div>
                        </Link>
                </div> : ""}
             
         



       
             
           
              {userType=="admin" ? <div className="col-lg-2 col-sm-4 col-xs-6">
                    <Link to="/Uetds" >
                        <div className="box-menu">
                            <div className="fab fa-usps"></div>
                            <span>UETDS Talepleri</span>
                        </div>
                    </Link>
                </div> : "" }
               
                {userType=="admin" ? <div className="col-lg-2 col-sm-4 col-xs-6">
                    <Link to="/UetdsNonActiveUser" >
                        <div className="box-menu">
                            <div className="fa fa-list"></div>
                            <span>UETDS Yapmayanlar</span>
                        </div>
                    </Link>
                </div> : "" }
                { userType=="admin" ? <div className="col-lg-2 col-sm-4 col-xs-6">
                    <Link to="BelgeKontrol">
                        <div className="box-menu">
                            <div className="fa fa-file"></div>
                            <span>Belge Aktiflik Kontrol</span>
                        </div>
                    </Link>
                </div> : "" } 
                { userType=="admin" ? <div className="col-lg-2 col-sm-4 col-xs-6">
                    <Link to="IletisimEkle">
                        <div className="box-menu">
                            <div className="fa fa-file"></div>
                            <span>İletişim Numarası Ekle</span>
                        </div>
                    </Link>
                </div> : "" } 
                { userType=="admin" ? <div className="col-lg-2 col-sm-4 col-xs-6">
                    <Link to="AracKiralamaSayfasi">
                        <div className="box-menu">
                            <div className="notifications-outline"><svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor" class="bi bi-bell-fill" viewBox="0 0 16 16">
  <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zm.995-14.901a1 1 0 1 0-1.99 0A5.002 5.002 0 0 0 3 6c0 1.098-.5 6-2 7h14c-1.5-1-2-5.902-2-7 0-2.42-1.72-4.44-4.005-4.901z"/>
</svg></div>
                            <span>KİRALIK İLAN YAYINLA</span>
                        </div>
                    </Link>
                </div> : "" } 

                { userType=="admin" ? <div className="col-lg-2 col-sm-4 col-xs-6">
                    <Link to="SendNotifyPage">
                        <div className="box-menu">
                            <div className="notifications-outline"><svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor" class="bi bi-bell-fill" viewBox="0 0 16 16">
  <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zm.995-14.901a1 1 0 1 0-1.99 0A5.002 5.002 0 0 0 3 6c0 1.098-.5 6-2 7h14c-1.5-1-2-5.902-2-7 0-2.42-1.72-4.44-4.005-4.901z"/>
</svg></div>
                            <span>Kullanıcılara Bildirim Gönder</span>
                        </div>
                    </Link>
                </div> : "" } 
                { userType=="admin" ? <div className="col-lg-2 col-sm-4 col-xs-6">
                    <Link to="SettingsPage">
                        <div className="box-menu">
                            <div className="settings"><svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor" class="bi bi-gear-fill" viewBox="0 0 16 16">
  <path d="M9.405 1.05c-.413-1.4-2.397-1.4-2.81 0l-.1.34a1.464 1.464 0 0 1-2.105.872l-.31-.17c-1.283-.698-2.686.705-1.987 1.987l.169.311c.446.82.023 1.841-.872 2.105l-.34.1c-1.4.413-1.4 2.397 0 2.81l.34.1a1.464 1.464 0 0 1 .872 2.105l-.17.31c-.698 1.283.705 2.686 1.987 1.987l.311-.169a1.464 1.464 0 0 1 2.105.872l.1.34c.413 1.4 2.397 1.4 2.81 0l.1-.34a1.464 1.464 0 0 1 2.105-.872l.31.17c1.283.698 2.686-.705 1.987-1.987l-.169-.311a1.464 1.464 0 0 1 .872-2.105l.34-.1c1.4-.413 1.4-2.397 0-2.81l-.34-.1a1.464 1.464 0 0 1-.872-2.105l.17-.31c.698-1.283-.705-2.686-1.987-1.987l-.311.169a1.464 1.464 0 0 1-2.105-.872l-.1-.34zM8 10.93a2.929 2.929 0 1 1 0-5.86 2.929 2.929 0 0 1 0 5.858z"/>
</svg></div>
                            <span>Ayarlar</span>
                        </div>
                    </Link>
                </div> : "" } 
      
      
                <div style={{backgroundColor:"white",height:250,overflowY:"scroll"}}>
                <ul class="list-group list-group-flush">  {aspiredData?.map(x=>{
                    return( 
 <span><li class="list-group-item"><Link to={`AracTaleplerim/${x?.waitinLoadId}`}>{x?.waitinLoadId} numaralı yüke yeni talep gelmiştir. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;   {x.createdOn}</Link></li>
  </span>

)
                  })}</ul>
                </div>
            </div>
    

           
    
        </div>

    </div>
</div></div>)
}
export default Panel;