import React, { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext, useAuth } from "./Auth";
import loginpage from "./css/loginpage.css";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const LoginPage = () =>{
const navigate = useNavigate()
const {login,girisEkranıHataMesajı,girisEkranıLoading } = useContext(AuthContext);
const image = require("./img/icon/Yuksis_Logo.png")
const [email,setEmail] = useState();
const [password,setPassword] = useState();
const [loading,setLoading] = useState(false);
const [adsoyadCustomer,setAdSoyadCustomer] = useState();
const [emailCustomer,setEmailCustomer] = useState();
const [iletisimFormuGonderildi,setIletisimFormuGonderildi] = useState(false);
const [phoneNumberCustomer,setPhoneNumberCustomer] = useState();
const onFormSubmit  = () => {
    setLoading(true);
    login(email,password);
    setLoading(false)
    
    
}
const customerSendMessageToMe = async () => {
  
    
     
       fetch("http://localhost:22695/api/auth/AddContackCustomer", {
           method: "Post",
           withCredentials: true,
           body: JSON.stringify({phoneNumber:phoneNumberCustomer,email:emailCustomer,adSoyad:adsoyadCustomer}),
           headers: {
               "content-type": "application/json",
  
           }
       }).then(response=>response.json()).then(response=>{toast.success("Başvurunuz Gönderildi.");setPhoneNumberCustomer();setAdSoyadCustomer();setEmailCustomer();setIletisimFormuGonderildi(true)}).catch(err=>console.log(err))}
  

    return(<div style={{ backgroundImage:`url("https://images01.nicepage.com/c461c07a441a5d220e8feb1a/48ebdb8e5b8250b5b175d71f/dfdfdfdff.jpg")`,backgroundSize:"cover",height:1250}}>
        <nav class="navbar navbar-expand-lg " style={{backgroundColor:"rgba(16,25,99,1)",opacity:0.8}}>
        <img style={{width:50}} src={image} alt="YÜKSİS" className="login-logo"/>
  <a style={{color:"#FF6D28"}} class="navbar-brand"  href="#">Ege Yurt Muavin</a>
  <button   class="navbar-toggler navbar-dark" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav mr-auto">
      <li class="nav-item active">
        <a class="nav-link"  style={{color:"white"}} href="https://egeyurtmuavin.com/hakkinda/">Hakkımızda </a>
      </li>
    
   
      <li class="nav-item">
        <a class="nav-link" href="https://egeyurtmuavin.com/iletisim/" style={{color:"white"}}>İletişim</a>
      </li>
    </ul>
    <form class="form-inline my-2 my-lg-0">
      

    </form>
  </div>
</nav>
        <div id="loginForm" className="container bounceInDown animated">
 
        <div className="col col-centered" style={{marginTop:75}} >
      
            <div className="row">
   
                <div className="col text-center" >
                    <div className="col">
     
                        <div className="login-left">
      
                            <div className="col-md-12 text-center">
                                <br/>
                                <br/>
                                <img src={image} alt="YÜKSİS" className="login-logo"/>
                            </div>
                            <div className="clearfix"></div>
                            <br/>
                            <h2>Yeni Nesil Taşımacılık</h2>
                            <h3></h3>
                        </div>
                    </div>
                </div>
                <div className="col">
                    <div className="col">
                        <div className="login-right">
                        {
            girisEkranıHataMesajı.length==0 ? "" : 
            girisEkranıHataMesajı.map(hataMesajı=>{
                return (
                    <div class="alert alert-danger" role="alert">
  {hataMesajı}
</div>
                )
            })
        }

        {girisEkranıLoading ? <div class="spinner-border" role="status">
  <span class="sr-only">Loading...</span>
</div> :  <div id="pnlGiris"> 
                                <div className="clearfix"></div>
                                <h2>Kullanıcı Girişi</h2>
                                <div className="form-group">
                                    <input value={email} onChange={(e)=>setEmail(e.target.value)} name="email" type="email" id="txtusername" className="input-login"  autoComplete="off" placeholder="ornek@email.com" required="" maxLength="50"/>
                                </div>

                                <div className="form-group">
                                    <input name="password" onChange={(e)=>setPassword(e.target.value)} value={password} type="password" id="txtpassword" className="input-login" placeholder="Parola" required=""/>
                                </div>
                                <div className="form-group">

                                    <a href="#" className="pull-right">Şifremi Unuttum</a>
                                    <div className="clearfix"></div>
                                </div>
                                <div className="form-group">
                                    <input onClick={onFormSubmit} type="submit"  value="GİRİŞ YAP" id="btnGiris" className="btn-giris"/>
                                </div>

                                <div className="clearfix"></div>                                            
                          </div> }
                          
                            <div className="form-group text-center">
                                <br/>
                            
                               
                            </div>
                         
                        </div>
                        
                    </div>
                    
                </div>
                <div className="clearfix"></div>
                
            </div>
            <div class="container" >
            <div class="row justify-content-center">
            <div class="col-10">
       </div></div></div>
        </div>
       
        
   
    </div></div>
  
    )

}
export default LoginPage;