import React,{useEffect, useState,useRef} from "react";
import Defaultcss from "./css/Default.css"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import iller from "./iller";
import ReactPaginate from "react-paginate";
import { Link ,Outlet,useLocation} from "react-router-dom";
import { Button } from "@mui/material";
import 'reactjs-popup/dist/index.css';
import Popup from 'reactjs-popup';


const Uyeler = () =>{
    const [sayfaSayisi,setSayfaSayisi] = useState(1);
const [tcNo,setTcNo] = useState("");
const [phoneNumber,setPhoneNumber]  = useState("");
const [AdSoyadUnvan,setAdSoyadUnvan] = useState("");
const [eyurtNumber,setEyurtNumber] = useState("");
const [uyelerLoading,setUyelerLoading] = useState(true);
const [tcNoDuzenlenecek,setTcNoDuzenlenecek] = useState();
const [duzenlenecek,setDuzenlenecek] = useState();
const [editedPhoneNumber,setEditedPhoneNumber] = useState()
const [currentPage,setCurrentPage] = useState(1);
const [toplamSayfa,setToplamSayfa] = useState();
const [uyeler,setUyeler] = useState([]);
const [updateLoading,setUpdateLoading] = useState(false);
const [adSoyadDuzenlencek,setAdSoyadDuzenlencek] = useState();
const [duzenlencekUserId,setDuzenlencekUserId] = useState();
const token = localStorage.getItem("token");
const [surucuEklePopup,setSurucuEklePopup] = useState();
const closeSurucuEKlePopup = () => setSurucuEklePopup(false);
const closeBaglıOlduguUyelerPopup = () => setBaglıOlduguUyelerPopup(false);
const [surucuEklenecekOlanRuhsatSahibi,setSurucuEklenecekOlanRuhsatSahibi] = useState();
const [surucuEklenecekOlanRuhsatSahibiAdi,setSurucuEklenecekOlanRuhsatSahibiAdı] = useState();
const [surucuPhoneNumber,setSurucuPhoneNumber] = useState();
const [surucuEklemeHataMesajı,setSurucuEklemeHataMesajı] = useState();
const [suruculeriGorPopup,setSuruculeriGorPopup] = useState();
const closeSuruculeriGorPopup = () => setSuruculeriGorPopup(false);
const [suruculer,setSuruculer] = useState();
const [excelLoading,setExcelLoading] = useState(false);
const [meslekiYeterlilikPopup,setMeslekiYeterlilikPopup] = useState();
const closeMeslekiYeterlilikPopup = () =>setMeslekiYeterlilikPopup(false);
const [UserMeslekiYeterlilikler,setUserMeslekiYeterlilikler] = useState();
const [duzenlenecekEyurtMusteriId,setDuzenlenecekEyurtMusteriId] = useState();
const [UserMeslekiYeterliliklerNull,setUserMeslekiYeterliliklerNull] = useState();
const [updateUyeResponseMessage,setUpdateUyeResponseMessage] = useState("");
const [baglıOlduguUyelerPopup,setBaglıOlduguUyelerPopup] = useState(false);
const [baglıOlduguUyelerPopupLoading,setBaglıOlduguUyelerPopupLoading] = useState(false);
const [baglıOlduguUyelerData,setBaglıOlduguUyelerData] = useState([]);
const meslekiYeterlilikSorgula = (tckn) =>{
  var stringTckn = tckn;
  fetch(`https://apipartner.yuksis.com/api/user/UserMeslekiYeterlilikSorgula?Tckn=${stringTckn}`,{
    method: "Get",
    withCredentials:true,
    
    headers: {
      "content-type": "application/json",
      "Authorization": `Bearer ${token}`
  }}).then(response=>response.json()).then(response=>{console.log(response);if(response.statusCode==200){
    setUserMeslekiYeterlilikler(response.data?.$values)
    
  }
else{
  setUserMeslekiYeterlilikler(response.error?.errors.$values)
}}

).then(response=>setMeslekiYeterlilikPopup((o)=>!o)).catch(err=>console.log(err))
}
const  GetExcelUser = () =>{
  setExcelLoading(true);
    fetch(`https://apipartner.yuksis.com/api/user/GetUsersFromDatabaseToExcel`,{
      method: "Get",
      withCredentials:true,
      
      headers: {
        "content-type": "application/json",
        "Authorization": `Bearer ${token}`
    }}).then(response=>response.json()).then(response=>{console.log(response);setExcelLoading(false);
      const link = document.createElement('a');
      link.href = `http://localhost:22695/Images/Uyeler.xlsx`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }).catch(err=>console.log(err));
  }






const suruculeriGoruntule = (phoneNumber) =>{
  fetch(`https://apipartner.yuksis.com/api/user/KullanicininSuruculeriniGor?PhoneNumber=${phoneNumber}`,{
        method: "Get",
        withCredentials:true,
        
        headers: {
          "content-type": "application/json",
          "Authorization": `Bearer ${token}`
      }}).then(response=>response.json()).then(response=>{console.log(response);setSuruculer(response.data.$values)}).catch(err=>console.log(err));

    }

console.log(sayfaSayisi);

const filtreleriTemizle  = () =>{
  setUyelerLoading(true);
  fetch(`https://apipartner.yuksis.com/api/user/SuruculeriGetir10?sayfaSayisi=${currentPage}`,{
      method: "Get",
      withCredentials:true,
      
      headers: {
        "content-type": "application/json",
        "Authorization": `Bearer ${token}`
    }})
  .then(response=>response.json()).then(response=>{setUyelerLoading(false);setUyeler(response.data.musteriListesi.$values);setToplamSayfa(response.data.count); setPhoneNumber("");
  setTcNo("")
      setEyurtNumber("");
      setAdSoyadUnvan("");}).catch(err=>{console.log(err);setUyelerLoading(false)});

}
console.log(currentPage+"currentPage")
  const aramaYap = () =>{
    setUyelerLoading(true);
    fetch(`http://localhost:22695/api/user/SuruculeriGetir10?sayfaSayisi=${currentPage}&&tcNo=${tcNo}&&phoneNumber=${phoneNumber}&&AdSoyadUnvan=${AdSoyadUnvan}&&eyurtNumber=${eyurtNumber}`,{
        method: "Get",
        withCredentials:true,
        
        headers: {
          "content-type": "application/json",
          "Authorization": `Bearer ${token}`
      }})
    .then(response=>response.json()).then(response=>{setUyelerLoading(false);setUyeler(response.data.musteriListesi.$values);setToplamSayfa(response.data.count);setCurrentPage(1)}).catch(err=>{console.log(err);setUyelerLoading(false)});
  }
useEffect(()=>{
    setUyelerLoading(true);
    fetch(`http://localhost:22695/api/user/SuruculeriGetir10?sayfaSayisi=${currentPage}&&AdSoyadUnvan=${AdSoyadUnvan}&&eyurtNumber=${eyurtNumber}&&tcNo=${tcNo}&&phoneNumber=${phoneNumber}`,{
        method: "Get",
        withCredentials:true,
        
        headers: {
          "content-type": "application/json",
          "Authorization": `Bearer ${token}`
      }})
    .then(response=>response.json()).then(response=>{console.log(response);setUyelerLoading(false);setUyeler(response.data.musteriListesi.$values);setToplamSayfa(response.data.count)}).catch(err=>{console.log(err);setUyelerLoading(false)});
},[AdSoyadUnvan,currentPage,updateLoading]) 
const UpdateUye = () =>{
  setUpdateLoading(true);
  fetch(`https://apipartner.yuksis.com/api/user/UpdateUye`,{method:"Post",
  withCredentials:true,
  body: JSON.stringify({phoneNumber:editedPhoneNumber,editedTcNo:tcNoDuzenlenecek,adSoyadUnvan:adSoyadDuzenlencek,duzenlencekUserId:duzenlencekUserId,duzenlenecekEyurtMusteriId:duzenlenecekEyurtMusteriId}),
  headers:{
    "content-type": "application/json",
    "Authorization": `Bearer ${token}`
  }}).then(response=>response.json()).then(response=>{if(response.statusCode==200){
    setDuzenlenecek();
    setUpdateLoading(false);
    setUpdateUyeResponseMessage(response.message);
    setDuzenlencekUserId();

  }}).catch(err=>{console.log(err);setUpdateLoading(false)});
}
const surucuEkle = () =>{
  fetch(`https://apipartner.yuksis.com/api/user/KullaniciyaSurucuEkle`,{method:"Post",
  withCredentials:true,
  body: JSON.stringify({masterIdPhoneNumber:surucuEklenecekOlanRuhsatSahibi,SlaveIdPhoneNumber:surucuPhoneNumber}),
  headers:{
    "content-type": "application/json",
    "Authorization": `Bearer ${token}`
  }}).then(response=>response.json()).then(response=>{
    if(response.statusCode==200)
    {
    closeSurucuEKlePopup();
  setSurucuPhoneNumber();
    toast.success("Sürücü Başarıyla Eklendi.");
    setSurucuEklemeHataMesajı("")


    }
    if(response.statusCode==400){
      setSurucuEklemeHataMesajı("Hata! Girdiğiniz Telefon Numarasına Bağlı Bir Kullanıcı Bulunamamış Olabilir. Lütfen Önce Kullanıcıyı Eklemeyi Deneyiniz.");
    }
  }
    
  )
}
console.log(surucuEklenecekOlanRuhsatSahibi,surucuPhoneNumber);


const baglıOlduguUyeleriGor = (id)=>{
  setBaglıOlduguUyelerPopup(true);
  setBaglıOlduguUyelerPopupLoading(true);
  fetch(`https://apipartner.yuksis.com/api/user/BaglıOlduguUyeleriGor?id=${id}`,{
    method: "Get",
    withCredentials:true,
    
    headers: {
      "content-type": "application/json",
      "Authorization": `Bearer ${token}`
  }})
.then(response=>response.json()).then(response=>{
  if(response.data == null)
  {
    setBaglıOlduguUyelerData([]);
  }
  else if(response.data!=null) {
   
   setBaglıOlduguUyelerData(response.data.$values)
  }
  setBaglıOlduguUyelerPopupLoading(false);
   
    }).catch(err=>{console.log(err);setBaglıOlduguUyelerPopupLoading(false)});
}
return (
    <div className="row">
    <div className="col-lg-12 tab-list">
  
 
        <ul className="nav nav-tabs" role="tablist">
            <li role="presentation" className="active"><a href="#" aria-controls="home" role="tab" data-toggle="tab" style={{color:"#555"}}>Üyeler</a></li>
        </ul>
      
            <div   id="collapseExample">
            <ul style={{marginBottom:20}} class="nav justify-content-center">
            <li class="nav-item">

  </li>  

 
  <li class="nav-item">

   
  </li>  <li class="nav-item">

   
  </li>
  
    

  <li class="nav-item">

   
  </li>
  <li class="nav-item">

   
  </li>
  <li class="nav-item" style={{marginLeft:100}}>
  
  </li>
  

</ul></div>
       
     <div style={{margin:20}} className="tab-content">
  {updateUyeResponseMessage.length==0 ? "" :  updateUyeResponseMessage=="Başarıyla Güncellendi" ? 
  <div class="alert alert-primary" role="alert">
  Başarıyla Güncellendi
</div> :     <div class="alert alert-danger" role="alert">
 {updateUyeResponseMessage }
</div>
 
  }   

            <div role="tabpanel" className="tab-pane active" id="home" style={{overflow:"scroll"}}>  
 
                <table  className="table table-responsive table-striped">
                <tr >
                  <th >  <input  onKeyDown={(e) => {
    if (e.code === "Enter" || e.code=="NumpadEnter") {
      aramaYap();
    }}} class="form-control" value={eyurtNumber} onChange={e=>{setEyurtNumber(e.target.value)}} placeholder="E-Yurt Müşteri Numarası"/></th>
                  <th >  <input  class="form-control" value={AdSoyadUnvan} onChange={e=>{setCurrentPage(1);setAdSoyadUnvan(e.target.value)}}  placeholder="Ad Soyad-Firma Adı"/></th>
                  <th>  <input onKeyDown={(e) => {
    if (e.code === "Enter" || e.code=="NumpadEnter") {
      aramaYap();
    }}} class="form-control" value={tcNo} onChange={e=>{setTcNo(e.target.value)}} placeholder="Tc Kimlik Numarası"/></th>
                  <th>  <input onKeyDown={(e) => {
    if (e.code === "Enter" || e.code=="NumpadEnter") {
      aramaYap();
    }}} class="form-control" value={phoneNumber} onChange={e=>{setPhoneNumber(e.target.value)}} placeholder="Telefon Numarası"/></th>
                  <th><button onClick={()=>aramaYap()} style={{backgroundColor:"#e5e619" ,boxShadow: '3px 3px 9px #F4AAB9'}} type="button" className="btn">Filtrele<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-funnel-fill" viewBox="0 0 16 16">
  <path d="M1.5 1.5A.5.5 0 0 1 2 1h12a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.128.334L10 8.692V13.5a.5.5 0 0 1-.342.474l-3 1A.5.5 0 0 1 6 14.5V8.692L1.628 3.834A.5.5 0 0 1 1.5 3.5v-2z"/>
</svg></button></th>
                  <th><button onClick={()=>{filtreleriTemizle()}} style={{backgroundColor:"#ef233c" ,boxShadow: '3px 3px 9px #F4AAB9'}} type="button" className="btn btn-danger"> Filtreleri Temizle</button></th>
                  <th>{excelLoading==true ? <button style={{marginBottom:10,marginLeft:10    ,boxShadow: '3px 3px 9px #F4AAB9'}} class="btn btn-primary" type="button" disabled>
  <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
 İndiriliyor...
</button> :  <button type="button"  onClick={()=>GetExcelUser()} class="btn btn-success bg-success" style={{marginBottom:10,marginLeft:10    ,boxShadow: '3px 3px 9px #F4AAB9'}}>Excel Çıktısı Al</button>} </th>
                </tr>{uyelerLoading ? <div class="spinner-border" role="status">
  <span class="sr-only">Loading...</span>
</div>:
                                            <tbody>
                                   
                        <tr>
                       

                     
                            <th>E-Yurt Numarası</th>
                            <th>Ad Soyad Unvan</th>
                            <th>Tc Kimlik Numarası</th>
                            <th>Telefon Numarası</th>
                            <th>İl/İlçe</th>

                            <th>Düzenle</th>
                            <th>Bağlı Olduğu Üyeler</th>
                            <th>Sürücüler</th>
                            <th>Sürücü Ekle</th>
                          <th>Mesleki Yeterlilik Sorgula</th>
                       
                      
                      
                        </tr>
{ uyeler?.length!=0 ? uyeler?.map(u=>{
    
    return(
        
        
        
        
     
       
  
             
          
   <tr>
         
     {u?.user?.id==duzenlencekUserId ?   <td><input value={duzenlenecekEyurtMusteriId} className={"form-control"} maxLength={99} placeholder={`${duzenlenecekEyurtMusteriId}`} onChange={(e)=>setDuzenlenecekEyurtMusteriId(e.target.value)}></input></td> : <td><a href={`http://e-yurt.yazilimci.info/center/musteridetay.aspx?id=${u?.user?.eyurtNumber}`} style={{color:"black",textDecoration:"underline",color:"blue"}} target="_blank" >{u?.user?.eyurtNumber}</a></td> } 
           {u?.user?.id==duzenlencekUserId ?   <td><input value={adSoyadDuzenlencek} className={"form-control"} maxLength={99} placeholder={`${adSoyadDuzenlencek}`} onChange={(e)=>setAdSoyadDuzenlencek(e.target.value)}></input></td> : 
           <td>{u?.user?.adSoyadUnvan}</td> }
           {u?.user?.id==duzenlencekUserId ? 
   <td>
                <input value={tcNoDuzenlenecek} className={"form-control"} maxLength={11} placeholder={`${tcNoDuzenlenecek}`} onChange={(e)=>setTcNoDuzenlenecek(e.target.value)}></input>
                
                 
                </td>
                
   :  <td>{u?.user?.tckn}</td> }
   {u?.user?.id==duzenlencekUserId ? 
    <td><input value={editedPhoneNumber} className={"form-control"} maxLength={10} placeholder={`${editedPhoneNumber}`} onChange={(e)=>setEditedPhoneNumber(e.target.value)}></input></td> : 
           <td>{u?.user?.phoneNumber}</td>}
           <td>{u?.user?.il} {u?.user?.ilce}</td>


           {u?.user?.id==duzenlencekUserId ? <td><button type="button" class="btn btn-success" onClick={()=>UpdateUye()}>Kaydet</button><button type="button" class="btn btn-danger" onClick={()=>{setDuzenlenecek();setTcNoDuzenlenecek();setEditedPhoneNumber();setAdSoyadDuzenlencek();setDuzenlencekUserId()}} style={{marginLeft:5}}>İptal</button></td> :
           <td><button onClick={()=>{setDuzenlenecek(u?.user?.eyurtNumber);setTcNoDuzenlenecek(u?.user?.tckn);setEditedPhoneNumber(u?.user?.phoneNumber);setAdSoyadDuzenlencek(u?.user?.adSoyadUnvan);setDuzenlencekUserId(u?.user?.id);setDuzenlenecekEyurtMusteriId(u?.user?.eyurtNumber)}} type="button" class="btn btn-secondary">Düzenle</button></td> }
           <td>{u?.baglıOlduguUyeVarYok == true ? <button className="btn btn-light" style={{borderWidth:1,borderColor:"black"}} onClick={()=>baglıOlduguUyeleriGor(u?.user?.id)}>Bağlı Olduğu Üyeler</button> : "-" }</td>
            <td>{u?.surucuVarYok==true ? <button onClick={()=>{suruculeriGoruntule(u?.user?.phoneNumber);setSuruculeriGorPopup((o)=>!o)}} className="btn btn-warning" >Sürücüler</button> :<button onClick={()=>{suruculeriGoruntule(u?.user?.phoneNumber);setSuruculeriGorPopup((o)=>!o)}} className="btn btn-warning" style={{backgroundColor:"#F9D923"}} disabled >Sürücü Yok</button> }
           </td><td> <button onClick={()=>{setSurucuEklenecekOlanRuhsatSahibi(u?.user?.phoneNumber);setSurucuEklenecekOlanRuhsatSahibiAdı(u?.user?.adSoyadUnvan);setSurucuEklePopup((o=>!o))}} style={{marginLeft:5}} className="btn btn-info" >Sürücü Ekle</button></td>
 
          <td><button className="btn btn-primary"  onClick={()=>meslekiYeterlilikSorgula(u?.user?.tckn)} >Mesleki Yeterlilik Sorgula</button></td>
       </tr>);


   })  : <h4 style={{alignSelf:"center"}}>Sonuç Bulunamadı.</h4>  }
                       
                   
                       
            
                                  
   </tbody> } 
                   
                </table>    
                               
                <div className="clearfix"></div>
               
            </div>
            <nav style={{marginTop:20}} aria-label="Page navigation example">
  <ul class="pagination justify-content-center">
  <Popup open={surucuEklePopup} closeOnDocumentClick onClose={closeSurucuEKlePopup}  contentStyle={{borderRadius:5,padding:50}}>
  <h5 style={{color:"red"}}>{surucuEklemeHataMesajı}</h5>
  <h5 >{surucuEklenecekOlanRuhsatSahibiAdi} adlı kullaniciya Sürücü Eklemek için </h5>
<h5>Telefon Numarası Giriniz</h5>
   
    <input maxLength={10} value={surucuPhoneNumber} onChange={(e)=>setSurucuPhoneNumber(e.target.value)} className="form-control" placeholder="5*********"></input>
    <button onClick={()=>surucuEkle()} className="btn btn-info"  style={{marginTop:10,width:200,fontSize:16}}>Ekle</button>
  </Popup>
  <Popup open={suruculeriGorPopup} contentStyle={{borderRadius:5,padding:15}}  onClose={closeSuruculeriGorPopup} >
   {suruculer?.length==0 ? <div ><svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="currentColor" class="bi bi-info-circle-fill" viewBox="0 0 16 16">
  <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"/>

</svg><p style={{marginTop:5,color:"#1d3557",display:"inline-block",marginLeft:10,fontSize:20}}>Sürücü Bulunmamaktadır.</p></div> : 
   

<table className="table table-responsive table-striped">
<tr>
  <th>Ad Soyad</th>
  <th>Tc Kimlik</th>
  <th>Telefon Numarası</th>
</tr>
{suruculer?.map(x=>{
  return (
<tr>

   <td>{x?.adSoyadUnvan}</td>
   <td>{x?.tckn}</td>
   <td>{x?.phoneNumber}</td>


</tr>
)
   }
  
   )}
   
</table>

   
   
   
   }

 


  </Popup>
  <Popup open={meslekiYeterlilikPopup} closeOnDocumentClick onClose={closeMeslekiYeterlilikPopup} contentStyle={{borderRadius:7,backgroundColor:"#6FEDD6",borderWidth:0}}  >

<div class="card"  >
<div class="card-header">
Mesleki Yeterlilikler
</div>
<ul class="list-group list-group-flush">
{UserMeslekiYeterlilikler?.map(x=>{
  return(  <li class="list-group-item">{x}</li> )
}


)}
</ul>





</div>

 


</Popup>
<Popup open={baglıOlduguUyelerPopup} closeOnDocumentClick onClose={closeBaglıOlduguUyelerPopup} contentStyle={{borderRadius:7,borderWidth:0,opacity:0.95,padding:10,paddingTop:17}}  >
{baglıOlduguUyelerPopupLoading ? <div class="spinner-border" role="status">
  <span class="sr-only">Loading...</span>
</div> : baglıOlduguUyelerData.length==0 ? <h5 style={{padding:10}}>Bağlı Olduğu Üye Bulunmamaktadır.</h5> :  <table class="table table-bordered table-dark">
  <thead>
    <tr>
      <th scope="col">#</th>
      <th scope="col">Ad Soyad</th>
      <th scope="col">Telefon Numarası</th>
      <th scope="col">E-Yurt Id</th>
    </tr>
  </thead>

  {baglıOlduguUyelerData?.map(x=>{
   return (
  <tbody>
    <tr>
      <th scope="row">1</th>
      <td>{x?.adSoyadUnvan}</td>
      <td>{x?.phoneNumber}</td>
      <td>{x?.eyurtNumber}</td>
    </tr>
   
  </tbody>
)
   
  })}
</table> }








 


</Popup>

  <ReactPaginate
         pageCount={toplamSayfa/10}
         containerClassName={"pagination"}
            pageClassName={"page-item"}
            pageLinkClassName={"page-link"}
            activeClassName = {"active"} 
            forcePage = {currentPage-1}
           

            onPageChange={(data)=>{setCurrentPage(data.selected+1)}}
            breakLabel={"..."}
         
  
            previousLabel={<h2></h2>}
            nextLabel={<h3></h3>}
          />
  
      
 
   
  
 
 
  </ul>
</nav>       
       </div>  
   
   
         </div></div>
)
}
export default Uyeler;