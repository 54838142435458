

import {BrowserRouter as Router, Routes, Route, Link,Navigator, useNavigate} from "react-router-dom";
import PrivateRoute from "./compenents/PrivateRoute.js";
import { AuthContext } from "./compenents/Auth.js";
import LoginPage from "./compenents/LoginPage.js";
import Panel from "./compenents/Panel.js";
import { useContext, useState,useEffect } from "react";
import KayitOl from "./compenents/KayitOl.js";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import UetdsDuzenle from "./compenents/UetdsDuzenle.js";
import Panelcss from "./compenents/css/Panel.css";
import AdminPrivateRoute from "./compenents/AdminPrivateRoute.js";
import YukeGelenTeklifVeDetay from "./compenents/YukeGelenTeklifVeDetay.js";
import AddNewUser from "./compenents/AddNewUser.js";
import Navbar from "./compenents/css/NavbarFooter.css"
import Uetds from "./compenents/Uetds.js";
import AracTaleplerim from "./compenents/AracTaleplerim.js";
import AracTalep from "./compenents/AracTalep.js";
import Seferlerim from "./compenents/Seferlerim.js";
import UserDetay from "./compenents/UserDetay.js";
import UetdsNonActiveUser from "./compenents/UetdsNonActiveUser.js";
import Uyeler from "./compenents/Uyeler.js";
import AddNewTruck from "./compenents/AddNewTruck.js";
import BelgeAktiflikleriKontrolEt from "./compenents/BelgeAktiflikleriKontrolEt.js";
import FatureYukleme from "./compenents/FaturaYukleme.js";
import { Input } from "@mui/material";
import Araclar from "./compenents/Araclar.js";
import IletisimEkle from "./compenents/IletisimEkle.js";
import SendNotifyPage from "./compenents/SendNotifyPage.js";
import {requestPermission,onMessageListener} from "./messaging_init_in_sw";
import { Helmet } from "react-helmet";
import SettingsPage from "./compenents/SettingsPage.js";
import AracKiralaSayfasi from "./compenents/AracKiralaSayfasi.js";



function App() {
  let navigation = useNavigate();
  const imageYüzey = require("./compenents/img/icon/resimmm.png");
  const YuksisLogo = require("./compenents/img/icon/Yuksis_Logo.png");
const [token,setToken] = useState(localStorage.getItem("token"));
const [hataMesajı,setHataMesajı ] = useState([]);

const [userType,setUserType ] = useState();
const [userName,setUserName] = useState();
var moment = require('moment');
const [accessTokenExpiration,setAccessTokenExpiration] = useState(localStorage.getItem("expft"));
const [refreshToken,setRefreshToken] = useState(localStorage.getItem("tokent"));

var now = moment(new Date());

console.log(now);
console.log(moment(accessTokenExpiration));
const [kayıtOlLoading,setKayıtOlLoading] = useState(false);
const [girisEkranıLoading,setgirisEkranıLoading] = useState(false);
console.log(now>accessTokenExpiration, );
useEffect(()=>{
if(accessTokenExpiration!=null){
  
        if(now.isAfter(accessTokenExpiration)){
            fetch("https://apipartner.yuksis.com/api/auth/CreateTokenByRefreshToken",{  method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({refreshToken :localStorage.getItem("tokent")}),
          }).then(response=>response.json()).then(response=> { localStorage.setItem("token",response.data.accessToken);
          setToken(response.data.accessToken);localStorage.setItem("tokent",response.data.refreshToken);localStorage.setItem("expft",response.data.accessTokenExpiration);}).catch(error=>console.log("hi"))
        }
        }
        
    },[accessTokenExpiration])


function jwtDecode(token) {
    let t = {};
    //t.raw = token;
   
    t = JSON.parse(window.atob(token.split('.')[1]));
     var role = t["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"];
   var userName = t["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name"];
    setUserType(role);
    setUserName(userName);

 
  }

    useEffect(()=>{
        if(token!=null){
           jwtDecode(token);
          
        }
    
     },[token]);


 console.log(userType);
const [girisEkranıHataMesajı , setgirisEkranıHataMesajı] = useState([]);

  const login = (email,password) => {
      setgirisEkranıLoading(true);
    fetch("https://apipartner.yuksis.com/api/auth/loginUserWithPassword",{
       method: 'POST', // or 'PUT'
       headers: {
         'Content-Type': 'application/json',
       },
       body: JSON.stringify({email : email , password: password}),
     }).then(response=>response.json()).then(response=>{if(response.statusCode==200)
   
      { localStorage.setItem("token",response.data.accessToken);
      setToken(response.data.accessToken);setRefreshToken(response.data.refreshToken);localStorage.setItem("tokent",response.data.refreshToken);localStorage.setItem("expft",response.data.accessTokenExpiration);setAccessTokenExpiration(response.data.accessTokenExpiration);navigation("");setgirisEkranıHataMesajı("");setgirisEkranıLoading(false)}
   
else{
    setgirisEkranıHataMesajı(response.error.errors.$values);
    setgirisEkranıLoading(false)
}

}
   
   
   ).catch(error=>{console.log(error);setgirisEkranıLoading(false)});
    
    
}

 const createUser = (email,password,ticariunvan,yetkiliadisoyadi,phonenumber) => {
     setKayıtOlLoading(true)
     
     fetch("https://apipartner.yuksis.com/api/auth/CreateUserWithPassword",{
        method: 'POST', // or 'PUT'
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({email:email,password:password,ticariUnvan:ticariunvan,yetkiliAdi:yetkiliadisoyadi,phonenumber:phonenumber}),
      }).then(response=>response.json()).then(response=>{if(response.statusCode==200){localStorage.setItem("token",response.data.accessToken);setToken(response.data.accessToken);setRefreshToken(response.data.refreshToken);localStorage.setItem("tokent",response.data.refreshToken);localStorage.setItem("expft",response.data.accessTokenExpiration);setAccessTokenExpiration(response.data.accessTokenExpiration);setKayıtOlLoading(false);setHataMesajı("");navigation("") } else{
          setHataMesajı(response.data.$values)
          setKayıtOlLoading(false)
      } }
    
       
    ).catch(error=>{setKayıtOlLoading(false)});
     
     
 }
 const logout = () => {
  
    localStorage.removeItem("token");
    setToken(null);
    setUserType(null);
  
    
    
 }

useEffect(()=>{
   var token =  localStorage.getItem("token")
requestPermission(token);

},[])





    onMessageListener().then(payload => {
        console.log("foreground",payload);
 
  

  }).catch(err => console.log('failed: ', err))

   


 

  return (
    
     <AuthContext.Provider value={{token,login,createUser,logout,hataMesajı,girisEkranıHataMesajı,userType,kayıtOlLoading,girisEkranıLoading,userName}}>
    <div className="App">
    {token ? <nav className="navbar navbar-light fixed-top"> 
    <ToastContainer position="bottom-left"
autoClose={10000}
hideProgressBar={false}
newestOnTop={true}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover/>
            <div className="container">
              <Link to=""><img src={YuksisLogo} className="navbar-brand" href="#" style={{height:85}}/></Link>  
                <a className="navbar-nav mr-auto" style={{"fontSize":15}} ><strong style={{margin:1}}>{userName},</strong>Ege Yurt Muavin Kullanıcı Paneline Hoşgeldiniz</a>
 

           
        
                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" class="bi bi-bell" viewBox="0 0 16 16">
  <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zM8 1.918l-.797.161A4.002 4.002 0 0 0 4 6c0 .628-.134 2.197-.459 3.742-.16.767-.376 1.566-.663 2.258h10.244c-.287-.692-.502-1.49-.663-2.258C12.134 8.197 12 6.628 12 6a4.002 4.002 0 0 0-3.203-3.92L8 1.917zM14.22 12c.223.447.481.801.78 1H1c.299-.199.557-.553.78-1C2.68 10.2 3 6.88 3 6c0-2.42 1.72-4.44 4.005-4.901a1 1 0 1 1 1.99 0A5.002 5.002 0 0 1 13 6c0 .88.32 4.2 1.22 6z"/>
</svg>
                <button className="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar">
                <span className="navbar-toggler-icon"></span>
                </button>
                <div className="offcanvas offcanvas-end" tabindex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
                <div className="offcanvas-header" style={{borderBottom:1}}>
                    <div className="col"><img src={YuksisLogo} className="navbar-brand" href="#" style={{height:120}}/></div>
                    <div className="col"><a style={{color:"#f24f00"}}><strong>Ege Yurt Muavin</strong></a></div>
                    <div className="col"><button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button></div>
                </div>
                <div className="offcanvas-body">
                    <ul className="navbar-var justify-content-end flex-grow-1 pe-3">
                        <Link to=""  className="nav-item">
                            <a className="nav-link" href="#" id="offcanvasItem"><i className="fas fa-house-user fa-fw"></i>  Ana Sayfa</a>
                        </Link>
                                          
                        <a className="nav-item">
                            <Link to="/AracTaleplerim" className="nav-link" href="#" id="offcanvasItem"><i className="fas fa-truck fa-fw"></i>  Araç Taleplerim</Link>
                        </a>
                       
                       
                       {userType=="admin" ? <Link to="/Uetds" className="nav-item">
                            <Link to="/Uetds" className="nav-link"  id="offcanvasItem"><i className="fas fa-file-import fa-fw"></i>  UETDS Talepleri</Link>
                        </Link> : ""} 
                        <a className="nav-item">
                            <Link to="Seferlerim" className="nav-link" href="#" id="offcanvasItem"><i className="fas fa-road fa-fw"></i>  Seferler</Link>
                        </a>
              
                      
                        <a className="nav-item" style={{marginTop:200,position:"absolute"}}>
                        <a className="nav-link" onClick={()=>logout()} href="#"><i className="fas fa-power-off"></i> Çıkış Yap</a>
                        </a>
                      
                
                    
                              
                    </ul>
                        
                 
                     
                </div>
                </div>
            </div>
        </nav> : ""  }
     
      
      <main>
   
      
       <Routes>
      <Route  path="/GirisYap" element={<LoginPage/>}></Route>
      <Route path="/KayitOl" element={<KayitOl/>}></Route>
     
     
     <Route path=""
     element={
         <PrivateRoute>

             <Panel/>
         </PrivateRoute>
     }
      ></Route>
          <Route path="Uetds"
     element={
         <AdminPrivateRoute>

             <Uetds/>
         </AdminPrivateRoute>
     }
      >
 



      </Route>
      <Route path="Uetds/:id"
     element={
         <PrivateRoute>

             <UetdsDuzenle/>
         </PrivateRoute>
     }
      ></Route>
       <Route path="AracTaleplerim"
     element={
         <PrivateRoute>

             <AracTaleplerim/>
         </PrivateRoute>
     }
      ></Route>
      <Route path="AracTalep"
     element={
         <PrivateRoute>

             <AracTalep/>
         </PrivateRoute>
     }
      ></Route>
      <Route path="AracTaleplerim/:id"
     element={
         <PrivateRoute>

             <YukeGelenTeklifVeDetay/>
         </PrivateRoute>
     }
      ></Route>
        <Route path="FaturaYukle"
     element={
         <PrivateRoute>

             <FatureYukleme/>
         </PrivateRoute>
     }
      ></Route>
       <Route path="Seferlerim"
     element={
         <PrivateRoute>

             <Seferlerim/>
         </PrivateRoute>
     }
      ></Route>
      <Route path="Profile/:userName"
     element={
         <PrivateRoute>

             <UserDetay/>
         </PrivateRoute>
     }
      ></Route>
       <Route path="UetdsNonActiveUser"
     element={
         <PrivateRoute>

             <UetdsNonActiveUser/>
         </PrivateRoute>
     }
      ></Route>
       <Route path="YeniSurucuEkle"
     element={
         <PrivateRoute>

             <AddNewUser/>
         </PrivateRoute>
     }
      ></Route>
       <Route path="AracKaydi"
     element={
         <PrivateRoute>

             <AddNewTruck/>
         </PrivateRoute>
     }
      ></Route>
        <Route path="Uyeler"
     element={
         <PrivateRoute>

             <Uyeler/>
         </PrivateRoute>
     }
      ></Route>
      <Route path="BelgeKontrol"
     element={
         <PrivateRoute>

             <BelgeAktiflikleriKontrolEt/>
         </PrivateRoute>
     }
      ></Route>
       <Route path="Araclar"
     element={
         <PrivateRoute>

             <Araclar/>
         </PrivateRoute>
     }
      ></Route>
       <Route path="IletisimEkle"
     element={
         <PrivateRoute>

             <IletisimEkle/>
         </PrivateRoute>
     }
      ></Route>
          <Route path="SendNotifyPage"
     element={
         <PrivateRoute>

             <SendNotifyPage/>
         </PrivateRoute>
     }
      ></Route>
       <Route path="SettingsPage"
     element={
         <PrivateRoute>

             <SettingsPage/>
         </PrivateRoute>
     }
      ></Route>
             <Route path="AracKiralamaSayfasi"
     element={
         <PrivateRoute>

             <AracKiralaSayfasi/>
         </PrivateRoute>
     }
      ></Route>
      
      
      

      
      


     
      
    
                  
 
    
    </Routes>
  
    
      
  
    


       
       </main>
      
       
    

   
      
</div>
   </AuthContext.Provider>   
  ); 
}

export default App;
