
import React,{useEffect, useState,useRef} from "react";
import Defaultcss from "./css/Default.css"
import { useContext } from "react";
import { AuthContext } from "./Auth";
import ReactPaginate from "react-paginate";

const UetdsNonActiveUser = ()=>{
    const token = localStorage.getItem("token");
    const [userList,setUserList] = useState([]);
    const [loading,setLoading] = useState(true);
    const [currentPage,setCurrentPage] = useState(1);
    const [itemCount,setItemCount] = useState(0);
    const [pageArray,setPageArray] = useState([]);

   
console.log(currentPage);
    
        useEffect(()=>{
setLoading(true)
        fetch(`https://apipartner.yuksis.com/api/uetds/GetUedsByGroup?currentPage=${currentPage}`,{
            method: "Get",
            withCredentials:true,
            
            headers: {
              "content-type": "application/json",
              "Authorization": `Bearer ${token}`
          }}).then(response=>response.json()).then(response=>{setUserList(response.data.userList.$values);setItemCount(response.data.count)}).then(response=>{setLoading(false);console.log(userList)}).catch(error=>console.log(error))},[currentPage])
     
          
    return (      <div  className="tab-content container ">
 
       

        <div  security="" style={{height:750}} role="tabpanel" className="tab-pane active contanier" id="home" >  
        <h3>Son 7 Gün İçerisinde 1 Kez Veya Daha Az Bildirim Yapan Kullanıcılar</h3>
        <div className="row">
        <div className="col-4"></div>
            <table style={{width:500}} className="table table-bordered table-striped table-responsive col-4">
            
         
              
                    <tr>
                   
                   
                 
                        <th>Adı Soyadı</th>
                        <th>Telefon Numarası</th>
                        <th>Ara</th>
                  
                  
                    </tr>  
{ userList?.length!=0 ? userList?.map(u=>{

return(
    <tbody >
    {loading ? <div class="spinner-border" role="status">
  <span class="sr-only">Loading...</span>
</div>:  

<tr  >
<td  >{u.name} {u.surname}</td>

  <td >{u.phoneNumber}</td>


  <td ><button onClick={()=>{window.open(`https://yurtcagri.alo-tech.com/api/?function=click2call&phonenumber=0${u.phoneNumber}&apptoken=ahRzfm11c3RlcmktaGl6bWV0bGVyaXIfCxISVGVuYW50QXBwbGljYXRpb25zGICAhLHB4tYLDKIBFnl1cnRjYWdyaS5hbG8tdGVjaC5jb20`, "", "width=200,height=100");
}}  type="button" class="btn btn-success">Ara</button></td>

  </tr>  
 
  }
</tbody>
)



})  : "Bütün Kullancılar Düzenli Bir Şekilde Bildirim Yapmaktadır." }
                   
               
                   
        
                              
              
           
            </table>    
            </div>
            <div className="clearfix"></div>
           
        </div>
        <nav style={{marginTop:20}} aria-label="Page navigation example">
<ul class="pagination justify-content-center">



        
   
          <ReactPaginate
            pageCount={itemCount/10}
            previousLabel={<svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="currentColor" class="bi bi-arrow-left-square-fill" viewBox="0 0 16 16">
  <path d="M16 14a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12zm-4.5-6.5H5.707l2.147-2.146a.5.5 0 1 0-.708-.708l-3 3a.5.5 0 0 0 0 .708l3 3a.5.5 0 0 0 .708-.708L5.707 8.5H11.5a.5.5 0 0 0 0-1z"/>
</svg>}
            nextLabel={<svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="currentColor" class="bi bi-arrow-right-square-fill" viewBox="0 0 16 16">
  <path d="M0 14a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2a2 2 0 0 0-2 2v12zm4.5-6.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5a.5.5 0 0 1 0-1z"/>
</svg>}
            breakLabel={"..."}
            onPageChange={(data)=>{setCurrentPage(data.selected+1)}}
            containerClassName={"pagination"}
            pageClassName={"page-item"}
            pageLinkClassName={"page-link"}
            activeClassName = {"active"} 
          />

   





</ul>
</nav>       
   </div>   
  
)
}
export default UetdsNonActiveUser;