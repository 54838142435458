import Default from "./css/Default.css"
import React, { useContext, useState } from "react";
import { AuthContext, useAuth } from "./Auth";
import { Link, Navigate } from "react-router-dom";




const KayitOl = () =>{
    const {createUser,hataMesajı,kayıtOlLoading } = useContext(AuthContext);
    const [email,setEmail] = useState();
    const [password,setPassword] = useState();
    const [ticariunvan,setTicariUnvan] = useState();
    const [yetkiliadisoyadi,setYetkiliAdiSoyadi] = useState();
    const [phoneNumber,setPhoneNumber] = useState();
    console.log(hataMesajı)
const FormSubmit = () =>{
    
    createUser(email,password,ticariunvan,yetkiliadisoyadi,phoneNumber)
}

return(<div>
    


    <div className="row" >
 
 

        <div className="col" style={{marginTop:50}}>
            <div className="content-detail" >
                <div className="page-detail" >
                    <div className="col-lg-12 tab-list" >
                       

                     
                        <div className="tab-content" style={{backgroundColor:"#ebeff2"}}>
                            <div role="tabpanel" className="tab-pane active" id="home">
                                <div className="register">
                                {hataMesajı.length==0 ? "" : hataMesajı.map(hata=>{
      return (<div class="alert alert-danger" role="alert">
 {hata.description}
</div>) 
  })}
        
                        {kayıtOlLoading==true ? <div class="spinner-border" role="status">
  <span class="sr-only">Loading...</span>
</div>:  <div id="ContentPlaceHolder1_pnlKayit" style={{backgroundColor:"#ebeff2"}}>
	
    <div className="form-group row">
        <label className="col-sm-2 col-xs-12 form-control-label">E-Posta*</label>
        <div className="col-sm-6 col-xs-12">
            <input name="ctl00$ContentPlaceHolder1$txtEposta" type="email" id="ContentPlaceHolder1_txtEposta" value={email} onChange={(e)=>setEmail(e.target.value)} className="form-control" autoComplete="off" placeholder="info@yuksis.com" required=""/>
            <span id="ContentPlaceHolder1_regexEmailValid" style={{color:"red",visibility:"hidden"}}>ePosta Formatınızı Kontrol Ediniz</span>
        </div>
    </div>
    <div className="form-group row">
        <label className="col-sm-2 col-xs-12 form-control-label">Şifre*</label>
        <div className="col-sm-6 col-xs-12">
            <input name="ctl00$ContentPlaceHolder1$txtSifre" value={password} onChange={(e)=>setPassword(e.target.value)} type="password" id="ContentPlaceHolder1_txtSifre" className="form-control" autoComplete="off" required={true} placeholder="*********" />
        </div>
    </div>
  
    <div className="form-group row">
        <label className="col-sm-2 col-xs-12 form-control-label">Ticari Ünvan*</label>
        <div className="col-sm-9 col-xs-12">
            <input name="ctl00$ContentPlaceHolder1$txtTicariUnvan" value={ticariunvan} onChange={(e)=>setTicariUnvan(e.target.value)} type="text" id="ContentPlaceHolder1_txtTicariUnvan" placeholder="Örn:Ege Yurt Lojistik" className="form-control" required=""/>
        </div>
    </div>
    <div className="form-group row">
        <label className="col-sm-2 col-xs-12 form-control-label">Yetkili Ad Soyad*</label>
        <div className="col-sm-4 col-xs-12">
            <input name="ctl00$ContentPlaceHolder1$txtAdSoyad" type="text" id="ContentPlaceHolder1_txtAdSoyad" value={yetkiliadisoyadi} onChange={(e)=>setYetkiliAdiSoyadi(e.target.value)} placeholder="Ad Soyadı" className="form-control" required=""/>
        </div>
    </div>
    <div className="form-group row">
        <label className="col-sm-2 col-xs-12 form-control-label">GSM*</label>
        <div className="col-sm-4 col-xs-12">
             <input name="ctl00$ContentPlaceHolder1$txtGSM" type="text" id="ContentPlaceHolder1_txtGSM" className="form-control" value={phoneNumber} onChange={(e)=>setPhoneNumber(e.target.value)} data-mask="(000) 000 00 00" placeholder="(555) 555 55 55" required="" autoComplete="off" maxLength="15"/>
        </div>
    </div>
    
    <div className="form-group row">
        <label className="col-sm-2 form-control-label"></label>
        <div className="col-sm-9">
            <input type="checkbox" name="q" id="chkSozlesme" required=""/>
            <a href="upload/UyelikSozlesmesi.pdf" target="_blank"><span style={{color:"blue"}}>Kullanıcı Sözleşmesini </span></a>okudum, onayladım.
        </div>
    </div>
    <div className="form-group row">
        <label className="col-sm-2 form-control-label"></label>
        <div className="col-sm-9">
            <input type="checkbox" name="q" id="chkMesafeliSatis" required=""/>
            <a href="upload/MesafeliHizmetSözleşmesi_ÖnBilgilendirme-YÜK-SİS.pdf" target="_blank"><span style={{color:"blue"}}>Mesafeli Hizmet Sözleşmesi+Ön Bilgilendirme</span></a> okudum, onaylıyorum
        </div>
    </div>
    <div className="form-group row">
        <label className="col-sm-2 form-control-label"></label>
        <div className="col-sm-9">
            <input type="checkbox" name="q" id="chkSms"/>
            Kampanyalardan  sms ile haberdar olmak istiyorum.
        </div>
    </div>
    <div className="form-group row">
        <label className="col-sm-2 form-control-label"></label>
        <div className="col-sm-9">
            <input type="checkbox" name="q" id="chkKampanya"/>
            Kampanyalardan eposta  ile haberdar olmak istiyorum.
        </div>
    </div>
    <div className="form-group row">
        <label className="col-sm-2 form-control-label"></label>
        <div className="col-sm-9">
            <input onClick={FormSubmit} type="button" name="ctl00$ContentPlaceHolder1$btnKaydet" value="Kayıt Ol" id="ContentPlaceHolder1_btnKaydet" className="btn btn-primary"/>
        </div>
        <div className="form-group">
<Link to="/GirisYap"  type="submit"  name="btnGiris"   className="btn-giris">Eğer Zaten Bir Hesabın Var İse Giriş Yap</Link>
</div>
    </div>


</div>}                                   
                                      
                                    
                                    <div className="clearfix"></div>
                                </div>
                            </div>
                            <div role="tabpanel" className="tab-pane" id="telkayit" style={{backgroundColor:"#ebeff2"}}>

                                <center><br/>
                                    <h1><a href="tel:4446985"><span className="fa fa-phone-alt" ></span>444 6 985</a></h1>
                                    <h2>Telefon ile kayıt olmak için</h2>
                                    <h3>Hemen Bizi Arayın!</h3>
                                </center>
                            </div>
                        </div>


                    </div>

                    <div className="clearfix"></div>

                </div>
            </div>
        </div>
    </div>

</div>
)

}
export default KayitOl;