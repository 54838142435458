import React,{useState,useEffect} from "react";


const SendNotifyPage = () =>{

  const [message,setMessage] = useState();
  const [bildirimLoading,setBildirimLoading] = useState(false);
  const [bildirimGonderildi,setBildirimGonderildi] = useState(false);
  
  const token = localStorage.getItem("token");
    const bildirimGonder = () =>{
    setBildirimLoading(true);
        fetch("https://apipartner.yuksis.com/api/user/SendAllClintNotify", {
            method: "Post",
            withCredentials: true,
            body: JSON.stringify({ clientId:message}),
            headers: {
                "content-type": "application/json",
    
                "Authorization": `Bearer ${token}`
            }
        }).then(response=>response.json()).then(response=>{setMessage();setBildirimLoading(false);setBildirimGonderildi(true)}).catch(err=>{setBildirimLoading(false);console.log(err)})
    }
return(
    <div className="row" >
     
    <div className="col-lg-12 tab-list">
   
  
        <ul className="nav nav-tabs" role="tablist">
            <li role="presentation" className="active"><a href="#" aria-controls="home" role="tab" data-toggle="tab" style={{color:"#555"}}>Bildirim Gönder</a></li>
        </ul> <nav class="navbar navbar-light bg-light">
  <span class="navbar-brand mb-0 h1  ">Bildirim Gönderme Paneli</span>
</nav>
      
            <div   id="collapseExample">
            <ul style={{marginBottom:20}} class="nav justify-content-center">
            <li class="nav-item">

  </li>  

  
  <li class="nav-item">

   
  </li>  <li class="nav-item">

   
  </li>
  
    

  <li class="nav-item">

   
  </li>
  <li class="nav-item">

   
  </li>
  <li class="nav-item" style={{marginLeft:100}}>
  
  </li>
  

</ul></div>
       
     <div style={{margin:20}} className="tab-content  d-flex justify-content-center align-self-center">
    
    <form>
    <div style={{width:600}} class="form-group">
      <label for="exampleInputEmail1">Mesaj</label>
     {bildirimGonderildi==true ? <div class="alert alert-success" role="alert">
  Bildirim Başarıyla Gönderildi
</div> : <textarea value={message} onChange={(e)=>setMessage(e.target.value)} disabled={bildirimLoading==true ? true : false} type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Lütfen Göndermek İstediğiniz Bildirim Mesajını Giririniz."/> }  
      <small id="emailHelp" class="form-text text-muted"></small>
    </div>
 
   
    {bildirimLoading==true ?   <button class="btn btn-primary" type="button" disabled>
  <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
  Bildirimler Gönderiliyor...
</button> : bildirimGonderildi==true ? <button type="submit" class="btn btn-secondary"  onClick={()=>setBildirimGonderildi(false)}>Tekrar Bildirim Gönder</button> :   <button type="submit" class="btn btn-primary"  onClick={()=>bildirimGonder()}>Bildirim Gönder</button>}  
  </form></div></div></div>
)
}
export default SendNotifyPage;