
import React, { useEffect,useState } from "react";
import { Link, useParams,useNavigate, Navigate,useLocation,useHref } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useContext } from "react";
import { AuthContext } from "./Auth";
import kasatipler from "./kasatipler.js";
import moment, { now } from "moment";


const YukeGelenTeklifVeDetay = () =>{
  const {userType} = useContext(AuthContext);
  const {userName} = useContext(AuthContext);
  console.log(userName);
  const [guncelleMod,setGuncellemeMod] = useState();
  const [yuk,setYuk] = useState();
  const [guncellenecekItem,setGuncellenecekItem] = useState();
  
  const [teklifler,setTeklifler] = useState([]);
  console.log(teklifler);
  let navigate = useNavigate();
  let {id} = useParams();  
  const token = localStorage.getItem("token");
  useEffect(()=>
  {  fetch(`https://apipartner.yuksis.com/api/load/GetById?id=${id}`,{
    method: "Get",
    withCredentials:true,
    
    headers: {
      "content-type": "application/json",
      "Authorization": `Bearer ${token}`
  }})
.then(response=>response.json()).then(response=>{setYuk(response.data);setTeklifler(response.data.aspiredLoads.$values);console.log(response)})}

  ,[guncellenecekItem]) 




  const TruckTypes = [{id : 1 , name : "Tır Dorseli" },{id : 2 , name : "Tır Kısa Damper Dorseli" },{id : 3 , name : "Kırkayak" },{id : 4 , name : "10 Teker" },
  {id : 5 , name : "6 Teker" },{id : 6 , name : "Kamyonet" },{id :7 , name : "Panelvan" },{id :8 , name : "Kamyon Römork" },{id :9 , name : "Özel Amaçlı" }]
  console.log(guncellenecekItem);
  const approveAspired = (id) =>{
  
    fetch(`https://apipartner.yuksis.com/api/aspire/ApproveAspire`,{
    method: "Post",
    withCredentials:true,
    body: JSON.stringify({id:id}),
    
    headers: {
      "content-type": "application/json",
      "Authorization": `Bearer ${token}`
  }})
.then(response=>response.json()).then(response=>{if(response.statusCode==200){
  toast.success("Sevkiyat Başarıyla Oluşturuldu.")
  navigate("/Seferlerim")
}
else if(response.statusCode==201){
  toast.info(response.error.errors.$values[0]);
 
}}).catch(error=>console.log(error));
  }
  console.log(yuk);

var  trailerTypes = kasatipler.filter(kasa=>kasa.truck_type_id == guncellenecekItem?.truckType);
const yukuGuncelle = () =>{
  fetch("https://apipartner.yuksis.com/api/load/UpdateLoad",{   
    method: "Post",
    withCredentials:true,
    body: JSON.stringify({...guncellenecekItem,truckName:TruckTypes.find(kasa=>kasa.id==guncellenecekItem.truckType)?TruckTypes.find(kasa=>kasa.id==guncellenecekItem.truckType).name : "null",trailerName:kasatipler.find(trailer=>trailer.trailer_type_id==guncellenecekItem.trailerType)?kasatipler.find(trailer=>trailer.trailer_type_id==guncellenecekItem.trailerType).trailer_type : "null"}),
    
    headers: {
      "content-type": "application/json",
      "Authorization": `Bearer ${token}`
  } 
  }).then(response=>response.json()).then(response=>{toast.success(
    "Yük Başarıyla Güncellendi."
      );setGuncellemeMod(false);setGuncellenecekItem()}).catch(err=>console.log(err));
}
  
    return(

    
    <div className="container" >
{yuk ?  <div class="row">
    <div class="col-12"><div class="text-center">
  <div >

  </div>
  <div style={{marginTop:10}} className="three-menu">
                                <h5 className="page-title">Yük Özellikleri</h5>
                                {guncelleMod==true ? <div > <button type="button" class="btn btn-success" onClick={()=>yukuGuncelle()}>Kaydet</button>
                                 <button  type="button" style={{marginLeft:10}} class="btn btn-danger"  onClick={()=>{setGuncellemeMod(false)}}>İptal</button>
                               </div>

                                 : 
                                  <button onClick={()=>{setGuncellemeMod(true);setGuncellenecekItem(yuk)}} type="button" class="btn btn-primary">Güncelle</button>
                                
                                }
                              
                              
                             
                            </div> 
  <div class="row" style={{backgroundColor:"white"}}>
    <div class="col-6"> <div class="card-body">
    <h5 class="card-title">Araç Türü</h5>
    {guncelleMod==true  ?  <select value={guncellenecekItem?.truckType} onChange={((e)=>{setGuncellenecekItem({...guncellenecekItem,truckType:e.target.value})})}  className="form-select" >

    {TruckTypes.map(truck => {
                                                       return ( <option value={truck.id} >{truck.name}</option>)
                                                   })}


    </select>:  <p class="card-text">{yuk.truckName}</p> }
   
    <hr></hr>
    {yuk.trailerType!=null ?<div> <h5 className="card-title">Kasa Tipi</h5>
    {guncelleMod==true  ? <select value={guncellenecekItem?.trailerType} onChange={((e)=>{setGuncellenecekItem({...guncellenecekItem,trailerType:e.target.value})})} className="form-control" > {trailerTypes.map(trailer=>{
                                                       return (
                                                      <option value={trailer.trailer_type_id}>
                                                           {trailer.trailer_type}
                                                       </option>)})}</select> : <p class="card-text">{yuk.trailerName}</p> }     <hr></hr></div> : " "}
    
    <h5 class="card-title">Yükleme Yeri</h5>
    <p class="card-text">{yuk.senderAdressCity}-{yuk.senderAdressTown}</p>
    <hr></hr>
    <h5 class="card-title">Yük Boşaltma Yeri</h5>
    <p class="card-text">{yuk.receiverCity}-{yuk.receiverAdressTown}</p>
    <hr></hr>
    <h5 class="card-title">Taşıma Bedeli</h5>
    {guncelleMod==true  ?  <input value={guncellenecekItem?.freight} onChange={((e)=>{setGuncellenecekItem({...guncellenecekItem,freight:e.target.value})})} className="form-control" ></input>  : <p class="card-text">{yuk.freight}₺ </p> }  <hr></hr>
    <h5 class="card-title">Toplam Mesafe</h5>
    <p class="card-text">{yuk.distance} Km </p><hr></hr>
    <h5 class="card-title">Ödeme Tipi</h5> 
    
    {guncelleMod==true  ?                     <select onChange={((e)=>{setGuncellenecekItem({...guncellenecekItem,paymentType:e.target.value})})}  value={guncellenecekItem.paymentType} name="ctl00$ContentPlaceHolder1$drpOdemeSekli" id="ContentPlaceHolder1_drpOdemeSekli" className="selectpicker" required="required" data-show-subtext="true" data-live-search="true" >
                                            <option value="null" disabled selected>Ödeme Yöntemi Seçiniz</option>
                     <option value="Karşı Ödemeli">Karşı Ödemeli</option>
                     <option value="Havale">Havale</option>
                     <option value="Havale(7Gün)">Havale(7Gün)</option>
                     <option value="Havale(14Gün)">Havale(14Gün)</option>
                     <option value="Nakit+Yakıt">Nakit+Yakıt</option>
                     <option value="Yakıt">Yakıt</option>
                     <option value="Diğer">Diğer</option>





                                            </select>  : <p class="card-text">{yuk.paymentType}</p>}<hr></hr>
    <h5 class="card-title">Gönderici Unvan</h5>
   {guncelleMod ? <input value={guncellenecekItem?.senderName} onChange={((e)=>{setGuncellenecekItem({...guncellenecekItem,senderName:e.target.value})})} className="form-control" ></input> : <p class="card-text">{yuk.senderName}</p> }  <hr></hr>
    
  </div></div>
    <div class="col-6"> <div class="card-body">
    <h5 class="card-title">Alıcı Unvan</h5>
  {guncelleMod ? <input value={guncellenecekItem?.receiverName} onChange={((e)=>{setGuncellenecekItem({...guncellenecekItem,receiverName:e.target.value})})} className="form-control" ></input>  :  <p class="card-text">{yuk.receiverName}</p> }  <hr></hr>
  <h5 class="card-title">Gönderici Telefon Numarası</h5>
   {guncelleMod ? <input value={guncellenecekItem.senderGSM} onChange={((e)=>{setGuncellenecekItem({...guncellenecekItem,senderGSM:e.target.value})})} className="form-control"  ></input> :  <p class="card-text">{yuk.senderGSM}</p>} 
 <hr></hr><h5 class="card-title">Alıcı Telefon Numarası</h5> 
 {guncelleMod ? <input value={guncellenecekItem.receiverGSM} onChange={((e)=>{setGuncellenecekItem({...guncellenecekItem,receiverGSM:e.target.value})})} className="form-control"  ></input> :       <p class="card-text">{yuk.receiverGSM}</p> }

 <hr></hr>
    
    <h5 class="card-title">Tahmini Yükleme Tarihi</h5>
    {guncelleMod ? <input name="ctl00$ContentPlaceHolder1$drpYuklemeAdres" onChange={(e)=>{setGuncellenecekItem({...guncellenecekItem,planningLoadDate:e.target.value.split('-').reverse().join("/")})}} value={guncellenecekItem.planningLoadDate.split('/').reverse().join("-")}  type="date" className="form-select" id="ContentPlaceHolder1_drpYuklemeAdres" class="selectpicker" required="required"  />  :   <p class="card-text">{yuk.planningLoadDate} {yuk.planningLoadTime}</p> }
  <hr></hr>
    <h5 class="card-title">Tahmini Boşaltma Tarihi</h5>
    {guncelleMod ?    <input name="ctl00$ContentPlaceHolder1$drpYuklemeAdres" onChange={(e)=>{setGuncellenecekItem({...guncellenecekItem,planningUnloadDate:e.target.value.split('-').reverse().join("/")})}}  value={guncellenecekItem.planningUnloadDate.split('/').reverse().join("-")} type="date" className="form-select" id="ContentPlaceHolder1_drpYuklemeAdres" class="selectpicker" required="required"  /> :   <p class="card-text">{yuk.planningUnloadDate} {yuk.planningUnloadTime} </p> }
  <hr></hr>
   <h5 class="card-title">Paketleme Tipi</h5>
  {guncelleMod ?             <select onChange={((e)=>{setGuncellenecekItem({...guncellenecekItem,paketlemeType:e.target.value})})} value={guncellenecekItem.paketlemeType}  name="ctl00$ContentPlaceHolder1$drpPaketleme" id="ContentPlaceHolder1_drpPaketleme" className="selectpicker" required="required" data-show-subtext="true" data-live-search="true" >
                                            <option value="null" disabled selected>Paketleme Tipini Seçiniz...</option>
                                            <option value="Paletli Yuk"> Paletli Yuk</option>
                                            <option value="Kolili"> Kolili</option>
                                         
                                            <option value="Bidon Varil"> Bidon Varil</option>
                                            <option value="Big Bag"> Big Bag</option>
                                            <option value="Dokme">Dokme</option>
                                            <option value="IBC Tank">IBC Tank</option>


                                     
                                            
                                           </select> :  <p class="card-text">{yuk.paketlemeType}</p>} 
    <hr></hr>
    <h5 class="card-title">Açıklama</h5>
    {guncelleMod ? <textarea value={guncellenecekItem.loadDescription} onChange={(e)=>{setGuncellenecekItem({...guncellenecekItem,loadDescription:e.target.value})}} className="form-control"></textarea> : <p className="card-text">{yuk.loadDescription}</p> }

  </div></div>
  </div>
 
  <div class="card-footer text-muted">
      
  </div>
</div></div>
  {teklifler.map((aspiredLoad,index)=>{
    return (<div class="col-4" ><div class="card border-success mb-3" style={{maxWidth:200}}>
    <div class="card-header bg-transparent border-info">{index+1}.Teklif</div>
    <div style={{backgroundColor:"#89CFFD"}}><p style={{fontSize:20,color:"white",marginTop:10}} >{aspiredLoad?.createdOn} </p></div>
    <div class="card-body text-primary">
      <h5 class="card-title">{aspiredLoad.userName}</h5>
      <p class="card-text">0{aspiredLoad.phoneNumber}</p>
      { userType=="admin" ?    <button onClick={()=>{window.open(`https://yurtcagri.alo-tech.com/api/?function=click2call&phonenumber=0${aspiredLoad.phoneNumber}&apptoken=ahRzfm11c3RlcmktaGl6bWV0bGVyaXIfCxISVGVuYW50QXBwbGljYXRpb25zGICAhLHB4tYLDKIBFnl1cnRjYWdyaS5hbG8tdGVjaC5jb20`, "", "width=200,height=100");
}}  type="button" style={{marginBottom:10}} class="btn btn-info">Teklif Vereni Ara</button>  : ""}
   {aspiredLoad.isApproved ?  <div class="alert alert-success" role="alert">
  Onay Verildi
</div> :  <div class="alert alert-info" role="alert">
  Onay Bekliyor
</div> }  
    </div>
    <div class="card-footer bg-transparent border-info "><Link to={`/Profile/${aspiredLoad.phoneNumber}`} type="button"  class="btn btn-primary">Teklif Vereni Gör</Link>
   {aspiredLoad.isApproved ? <button type="button" class="btn btn-secondary btn-lg" style={{margin:10}} disabled>Onaylandı</button>  : <button type="button" class="btn btn-success" style={{margin:10}} onClick={()=>approveAspired(aspiredLoad.id)} >Teklifi Kabul Et</button> } </div>
  </div></div>)})}
  </div> : <div class="spinner-grow" role="status">
  <span class="sr-only">Loading...</span>
</div>}


</div>
)
}
 export default YukeGelenTeklifVeDetay;