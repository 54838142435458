


import React,{useState,useEffect} from "react";
import { Link, Navigate } from "react-router-dom";
import ilceler from "./ilceler";
import iller from "./iller";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from "react-router-dom";
const AddNewUser =()=>{
  const navigate = useNavigate()
    const token =  localStorage.getItem("token");
    const [il,setIl] = useState("");
const [ilce,setIlce] =useState("");
const [adSoyad,setAdSoyad] = useState("");
const [tcKimlikNumarası,setTcKimlikNumarası] = useState();
const [telefonNumarası,setTelefonNumarası] = useState();
const [adressContent,setAdressContent] = useState();
const [eyurtNumber,setEyurtNumber] = useState();
const [file,setFile] = useState();  
const [fileName,setFileName] = useState();  
const [topluTalepErrorMessage,setTopluTalepErrorMessage] = useState();
const [yukleniyorButton,setYukleniyorButton] = useState(false);   
const [loading,setLoading] = useState(false);
const saveFile = (e)=>{
    setFile(e.target.files[0])
    

}
const AddUser = () =>{
  setLoading(true);
    if(tcKimlikNumarası!=null && telefonNumarası!=null){
      fetch(`https://apipartner.yuksis.com/api/auth/AddUserFromClient`,{
        method: "Post",
        withCredentials:true,
        body: JSON.stringify({adSoyadUnvan:adSoyad,eyurtNumber:eyurtNumber,tCKN:tcKimlikNumarası,il:iller.find(x=>x.id==il).name,ilce:ilce,adressContent:adressContent,phoneNumber:telefonNumarası}),
        
        headers: {
          "content-type": "application/json",
          "Authorization": `Bearer ${token}`
      }}).then(response=>response.json()).then(response=>{if(response.statusCode===200){
        toast.success("Kullanıcı Başarıyla Eklendi")
        setLoading(false)
      }
      else if(response.statusCode==400){            
        response.error.errors.$values.map(x=>
      toast.error(x
        ,{autoClose:9999999})
          
          )
          setLoading(false)
      }
      
      }).catch(error=>{console.log(error);setLoading(false)})




    }
    else{
      toast.error("Lütfen Telefon Numarası ve Tc Kimlik Numarası Bilgilerini Girdiğinizden Emin olunuz.");setLoading(false)
    }
   }
  const uploadFile = () =>{
    if(file==null){
        setTopluTalepErrorMessage("Lütfen Önce Excel Dosyasını Seçiniz")
    }
    else{
 
   var myFile = new FormData();
   myFile.append('file',file);
   setYukleniyorButton(true)
 
   fetch("https://apipartner.yuksis.com/api/auth/AddUserFromClientToplu",{
    method: "post",
    withCredentials: true,
   

  
    headers: {
         

        "Authorization": `Bearer ${token}`
    },
    body: myFile
}).then(response=>response.json()).then(response=>{
    toast.success("Kullanıcılar Başarıyla Eklendi.");

    setTopluTalepErrorMessage();

    setYukleniyorButton(false)
    navigate("/");

}).catch(error=>{ setYukleniyorButton(false);setTopluTalepErrorMessage("Lütfen excel formatında bir belge yükleyiniz ve doğru bir şablon kullanınız.")});
}}
 return (
    <div  className="col" >
   <div className="three-menu">
       

                                <ol className="breadcrumb">
                                <div style={{boxShadow:"5px 5px 12px #888888",borderRadius:6,backgroundColor:"#f8f7ff"}} >
  <div class="card-body" ><div  className="container">
  <h5 className="page-title">Toplu Kullanıcı Ekle</h5>
  {topluTalepErrorMessage ? <div class="alert alert-danger" role="alert">
{topluTalepErrorMessage} 

</div>: "" }   

                         
         <div className="row">

         <div style={{marginTop:32}} className="col-8"> {yukleniyorButton ? <button class="btn btn-primary" type="button" disabled>
  <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
  Yükleniyor
</button> :  <button  type="button" style={{color:"black"}} class="btn btn-info btn-sm" onClick={uploadFile}><p style={{display:"inline",color:"white",fontWeight:"bold"}}>Kullanıcıları Ekle</p></button>  } <input  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" style={{width:250,display:"inline",marginLeft:20}} className="form-control form-control-sm" type="file"   onChange={saveFile}></input>
                                </div>
      
        
                             
         <div  className="col-4"><p style={{color:"black"}}><div className="container"><div className="row">
         <div className="column" style={{marginTop:22}}><Link target="_blank" download to={"/files/UyelerSablon.xlsx"} style={{color:"#029ede",fontWeight:"bolder"}}>Toplu Kullanıcı Ekleme Excel Sablonunu İndirmek İçin Tıklayınız</Link></div>
         <div className="column"><p style={{fontWeight:"bolder"}}>Not: Başarılı bir şekilde toplu Kullanıcı Eklemek  için lütfen excel şabloununu kullanınız veya örnek alınız.</p></div>
         
         </div></div>
</p></div>
       

         </div>

  </div>
                               
                               
                                </div> 
</div> 
                                
                                
                                </ol>
                            </div>
    <div style={{marginTop:50}} className="col-lg-8 tab-list">
  
  


        <div style={{marginTop:10}} className="two-menu">
                           
  
                            <ol className="breadcrumb">
                          
                                <li><Link to="/" href="default.aspx" title="Anasayfa">Anasayfa </Link></li>
                                <li> /Yeni Sürücü Kaydı</li>      
                            </ol>     
                            <hr></hr>  
                        </div>
                
        <div  className="tab-content">
    <form>
    <div class="form-row">
      <div class="col-md-4 mb-3">
        <label for="validationDefault01">Ad Soyad </label>
        <input type="text" value={adSoyad} onChange={(e)=>setAdSoyad(e.target.value)} class="form-control" id="validationDefault01" placeholder="Ad Soyad"  required/>
      
      </div>
      <div class="col-md-4 mb-3">
        <label for="validationDefault01">E-Yurt Müşteri Numarası (Varsa Kesinlikle Giriniz) </label>
        <input type="text" value={eyurtNumber} onChange={(e)=>setEyurtNumber(e.target.value)} class="form-control" id="validationDefault01" placeholder="E-Yurt Müşteri Numarası"  required/>

      
      </div>
      <div class="col-md-4 mb-3">
        <label for="validationDefault01">Tc Kimlik Numarası </label>
        <input maxLength={15} type="number" value={tcKimlikNumarası} onChange={(e)=>setTcKimlikNumarası(e.target.value)} class="form-control" id="validationDefault01" placeholder="Tc Kimlik Numarası"  required/>
      
      </div>
  

    </div>
    <div class="form-row">
      <div class="col-md-6 mb-3">
        <label for="validationDefault03">Şehir</label>
        <select class="form-select" value={il} onChange={e=>setIl(e.target.value)}>
      <option disabled selected value="">{il?.length==0  ? "Lütfen Kullanıcın Bulunduğu Şehrini Seçiniz"  : il}</option>
{iller.map(il=>{
  return (<option value={il.id}>{il.name}</option>)
})}

    </select>
      </div>
      <div class="col-md-6 mb-3">
        <label for="validationDefault03">Şehir</label>
        <select  class="form-select" value={ilce} onChange={e=>setIlce(e.target.value)}>
      <option disabled selected value="" >{ilce?.length==0  ? "Lütfen Kullanıcın Bulunduğu İlçeyi Seçiniz"  : ilce}</option>
    {ilceler.filter(ilce=>ilce.ilce_sehirkey==il).map(ilce=>
                                                   {
                                                       return(
                                                           <option value={ilce.ilce_title}>{ilce.ilce_title}</option>
                                                       )
                                                   })}

    </select>
      </div>
     
      <div class="col-md-3 mb-3">
        <label for="validationDefault04">Adres Açıklaması</label>
        <textarea type="text" value={adressContent} onChange={(e)=>setAdressContent(e.target.value)} class="form-control" id="validationDefault04" placeholder="Mahalle Cadde Sokak No" />
      </div>
      <div class="col-md-3 mb-3">
        <label for="validationDefault05">Telefon Numarası</label>
        <input type="text" value={telefonNumarası} onChange={(e)=>setTelefonNumarası(e.target.value)} class="form-control" id="validationDefault05" maxLength={10} placeholder="5*********" required/>
      </div>
    </div>
    <div class="form-group">
     
    </div>
  {loading ?  <button class="btn btn-primary" disabled onClick={AddUser} type="button">Yükleniyor...</button> :  <button class="btn btn-primary" onClick={AddUser} type="button">Kullanıcı Ekle</button>} 
  </form></div></div></div>
 )
}
export default AddNewUser;