
import React,{useState,useEffect} from "react";
import { Link } from "react-router-dom";
import kasatipler from "./kasatipler";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import iller from "./iller";
import moment from "moment/moment";

const AracKiralaSayfasi = () =>{
    
    const token =  localStorage.getItem("token");
    const [tasitId,setTasitId] = useState("");
const [plaka,setPlaka] =useState("");
const [truckType,setTruckType] = useState("");
const [calisilacakIl,setCalisilacakIl] = useState("");
const [calisilacakGuzergah,setCalisilacakGuzergah] = useState("");
const [aracSayisi,setAracSayisi] = useState();
const [errorMessage,setErrorMessage] = useState();
const [trailerType,setTrailerType] = useState("0");
const [surucuSayisi,setSurucuSayisi] = useState("1");
const [file,setFile] = useState();  
const [fileName,setFileName] = useState();  
const [topluTalepErrorMessage,setTopluTalepErrorMessage] = useState();
const [yukleniyorButton,setYukleniyorButton] = useState(false);   
const [yukcinsi,setYukCinsi] = useState("");
const [yuzKmdeYakılanYakıt,setYuzKmdeYakılanYakıt] = useState("");
const [yakitYuzdesi,setYakitYuzdesi] = useState("");
const [aylikOrtalamaKm,setAylikOrtalamaKm] = useState("");
const [kiraBedeli,setKiraBedeli] = useState("");
const [odemeSekli,setOdemeSekli] = useState("");
const [sozlesmeSuresi,setSozlesmeSuresi] = useState("");
const [takagrofUyumlu,setTakagrofUyumlu] = useState(true);
const [tatilGunleri,setTatilGunleri] = useState("");
const [ilanIcinAranacakNumara,setIlanIcınAranacakNumara] = useState("");
const [ilanVerenFirma,setIlanVerenFirma] = useState("");
const [ilanSahibiYetkiliKisi,setIlanSahibiYetkiliKisi] = useState("");
const [ilanSahibiYetkiliTelefonu,setIlanSahibiYetkiliTelefonu] = useState("");

const [ilanBitisTarihi,setIlanBitisTarihi] = useState(new Date().toString());
const saveFile = (e)=>{
    setFile(e.target.files[0])
    

}
const addRentVehicle = () =>{

   fetch("https://apipartner.yuksis.com/api/truckTrailer/AddRentVehicle",{
     method:"Post",
     withCredentials:true,
     body: JSON.stringify({aranacakNo:ilanIcinAranacakNumara,aracTuru:truckType,kasaTipi:trailerType,aracSayisi:parseInt(aracSayisi),surucuSayisi:parseInt(surucuSayisi),calisilacakIl:calisilacakIl,calisilacakGuzergah:calisilacakGuzergah,yukunCinsi:yukcinsi,yakitYuzdesi:yakitYuzdesi,aylikOrtalamaKm:aylikOrtalamaKm,kiraBedeli:kiraBedeli,odemeSekli:odemeSekli,sozlesmeSuresi:parseInt(sozlesmeSuresi),takagrofUyumlu:takagrofUyumlu,tatilGunleri:tatilGunleri,ilanSahibi:ilanVerenFirma,ilanSahibiYetkilisi:ilanSahibiYetkiliKisi,ilanSahibiTelefonNumarasi:ilanSahibiYetkiliTelefonu,IlanBitisTarihi:moment(ilanBitisTarihi).add(1,"month").format("yyyy-MM-DD")}),
     headers: {
         "content-type": "application/json",
         "Authorization": `Bearer ${token}`
     }}).then(response=>response.json()).then(response=>{if(response.statusCode==200){
         toast.success("İlan Başarıyla Eklendi.");
        
        

        
     }
 
 }).catch(error=>{setErrorMessage("Bir Hata Meydana Geldi Lütfen Alanları  Kontrol Ederek Tekrar Yüklemeyi Deneyiniz.");console.log(error)});

  }
 



const TruckTypes = [{id : 1 , name : "Tır Dorseli" },{id : 2 , name : "Tır Kısa Damper Dorseli" },{id : 3 , name : "Kırkayak" },{id : 4 , name : "10 Teker" },
{id : 5 , name : "6 Teker" },{id : 6 , name : "Kamyonet" },{id :7 , name : "Panelvan" },{id :8 , name : "Kamyon Römork" },{id :9 , name : "Özel Amaçlı" },{id:10,name: "Farketmez"}]
var  trailerTypes = kasatipler.filter(kasa=>kasa.truck_type_id == truckType);


    return ( <div  className="col" >
    <div className="three-menu">
        
 
                                 <ol className="breadcrumb">
                                 <div style={{boxShadow:"5px 5px 12px #888888",borderRadius:6,backgroundColor:"#f8f7ff"}} >

 </div> 
                                 
                                 
                                 </ol>
                             </div>
     <div style={{marginTop:50}} className="col-lg-8 tab-list">
   
   
      
 
         <div style={{marginTop:10}} className="two-menu">
                            
   
                             <ol className="breadcrumb">
                           
                                 <li><Link to="/" href="default.aspx" title="Anasayfa">Anasayfa </Link></li>
                                 <li>/Kiralık Araç </li>      
                             </ol>     
                             <hr></hr>  
                         </div>
                 
         <div  className="tab-content">
     <form>  {errorMessage!=null ? <div class="alert alert-danger" role="alert">
  {errorMessage}
</div> : "" }  
     <div class="form-row">
       <div class="col-md-4 mb-3">
         <label for="validationDefault01">Araç Sayısı</label>
         <input type="text" value={aracSayisi} onChange={(e)=>setAracSayisi(e.target.value)} class="form-control" id="validationDefault01" placeholder="Araç Adet Sayısını Giriniz"  required/>
       
       </div>
       <div class="col-md-4 mb-3">
         <label for="validationDefault01">Araç Türünü Seçiniz</label>
         <div className="btn-group bootstrap-select">
                                               
                                               <select  className="form-select"  onChange={e=>setTruckType(e.target.value)}>
                                      
                                                 <option value="0" disabled selected>Lütfen Araç Türünü Seçiniz</option>
                                                 {TruckTypes.map(truck => {
                                                     return ( <option value={truck.id} >{truck.name}</option>)
                                                 })}
                                            

                                              </select>
                                          </div>
 
       
       </div>
       <div class="col-md-4 mb-3">
         <label for="validationDefault01">Araç Kasa Tipini Seçiniz</label>
         <div className="btn-group bootstrap-select">
                                               
         <select onChange={e=>setTrailerType(e.target.value)} className="form-select">
                                               <option disabled selected value="0">{truckType==0 ? "Lütfen Önce Araç Türünü Seçiniz" : trailerTypes.length==0 ? "Bu araç İçin Kasa Tipi Bulunmamaktadır." : "Lütfen Bir Kasa Tipi Seçiniz"}</option>
                                               {trailerTypes.map(trailer=>{
                                                       return (
                                                      <option value={trailer.trailer_type}>
                                                           {trailer.trailer_type}
                                                       </option>)})}
                                                    
                                                    


                                             </select> 
                                          </div>
 
       
       </div>

   
 
     </div>
    
 
    
     <div class="form-row">
     <div class="col-md-4 mb-3">
         <label for="validationDefault01">Sürücü Sayısı</label>
         <select class="form-select" value={surucuSayisi} onChange={e=>setSurucuSayisi(e.target.value)}>
       <option disabled selected value="1">{surucuSayisi?.length==0  ? "Lütfen Sürücü Sayisini Seçiniz"  : surucuSayisi}</option>

<option  value="1">1</option>
<option value="2">2</option>


</select>
 
       
       </div>
       <div class="col-md-4 mb-3">
         <label for="validationDefault03">Çalışılacak İl</label>
         <select class="form-select" value={calisilacakIl} onChange={e=>setCalisilacakIl(e.target.value)}>
       <option disabled selected value="">{calisilacakIl?.length==0  ? "Lütfen Çalışılacak İli Seçiniz"  : calisilacakIl}</option>
{iller.map(x=>
{return (
    <option value="Trucks">{x?.name}</option>
)}
)}


</select></div>

<div class="col-md-4 mb-3">
         <label for="validationDefault01">Çalışılacak Güzergah</label>
         <input type="text" value={calisilacakGuzergah} onChange={(e)=>setCalisilacakGuzergah(e.target.value)} class="form-control" id="validationDefault01" placeholder="Çalışılacak Güzergahı Yazınız"  required/>
       
       </div>
       <div class="col-md-4 mb-3">
         <label for="validationDefault01">Yükün Cinsi</label>
         <input type="text" value={yukcinsi} onChange={(e)=>setYukCinsi(e.target.value)} class="form-control" id="validationDefault01" placeholder="Taşınacak Yükün Cinsini Yazınız"  required/>
       
       </div>
     
       <div class="col-md-4 mb-3">
         <label for="validationDefault01">Yakıt Yüzdesi</label>
         <input type="text" maxLength={2} value={yakitYuzdesi} onChange={(e)=>setYakitYuzdesi(e.target.value)} class="form-control" id="validationDefault01" placeholder="Yakıt Yüzdesini Giriniz"  required/>
       
       </div>
       <div class="col-md-4 mb-3">
         <label for="validationDefault01">Aylık Ortalama Km.</label>
         <input type="text" value={aylikOrtalamaKm} onChange={(e)=>setAylikOrtalamaKm(e.target.value)} class="form-control" id="validationDefault01" placeholder="Aylık Ortalama Km'yi Giriniz"  required/>
       
       </div>
       <div class="col-md-4 mb-3">
         <label for="validationDefault01">Kira Bedeli KDV Hariç</label>
         <input type="text" value={kiraBedeli} onChange={(e)=>setKiraBedeli(e.target.value)} class="form-control" id="validationDefault01" placeholder="Kira Bedelini Giriniz(KDV Hariç)"  required/>
       
       </div>
       <div class="col-md-4 mb-3">
         <label for="validationDefault01">Ödeme Şekli</label>
         <input type="text" value={odemeSekli} onChange={(e)=>setOdemeSekli(e.target.value)} class="form-control" id="validationDefault01" placeholder="Ödeme Şeklini Giriniz"  required/>
       
       </div>
       <div class="col-md-4 mb-3">
         <label for="validationDefault01">Sözleşmenin Süresi(Ay)</label>
         <input type="text" value={sozlesmeSuresi} onChange={(e)=>setSozlesmeSuresi(e.target.value)} class="form-control" id="validationDefault01" placeholder="Sözleşmenin Süresi"  required/>
       
       </div>
       <div class="col-md-4 mb-3">
         <label for="validationDefault01">Takograf Uyumlu</label>
         <select class="form-select" value={takagrofUyumlu} onChange={e=>setTakagrofUyumlu(e.target.value)}>
    

<option  value={true}>Uyumlu</option>
<option value={false}>Uyumlu Değil</option>


</select>
 
       
       </div>
       <div class="col-md-4 mb-3">
         <label for="validationDefault01">Tatil Günleri</label>
      
         <input type="text" value={tatilGunleri} onChange={(e)=>setTatilGunleri(e.target.value)} class="form-control" id="validationDefault01" placeholder="Örnek : Pazar"  required/>
       
       </div>
       <div class="col-md-4 mb-3">
         <label for="validationDefault01">İlan İçin Aranacak Telefon</label>
      
         <input type="text" maxLength={10} value={ilanIcinAranacakNumara} onChange={(e)=>setIlanIcınAranacakNumara(e.target.value)} class="form-control" id="validationDefault01" placeholder="05*******"  required/>
       
       </div>
       <div class="col-md-4 mb-3">
         <label for="validationDefault01">İlanı Veren Firma(İlanda Gözükmeyecek)</label>
      
         <input type="text"  value={ilanVerenFirma} onChange={(e)=>setIlanVerenFirma(e.target.value)} class="form-control" id="validationDefault01" placeholder="Firma İsmi"  required/>
       
       </div>    
       <div class="col-md-4 mb-3">
         <label for="validationDefault01">İlan Sahibi Yetkilisi</label>
      
         <input type="text"  value={ilanSahibiYetkiliKisi} onChange={(e)=>setIlanSahibiYetkiliKisi(e.target.value)} class="form-control" id="validationDefault01" placeholder="İlan Sahibi Yetkili Kişi"  required/>
       
       </div>
       <div class="col-md-4 mb-3">
         <label for="validationDefault01">İlan Sahibi Yetkili Telefonu</label>
      
         <input type="text"  maxLength={10} value={ilanSahibiYetkiliTelefonu} onChange={(e)=>setIlanSahibiYetkiliTelefonu(e.target.value)} class="form-control" id="validationDefault01" placeholder="İlan Sahibi Yetkili Telefonu"  required/>
       
       </div>   <div class="col-md-4 mb-3">
         <label for="validationDefault01">İlan Bitiş Tarihi</label>
      
         <input type={"date"}  maxLength={10} value={moment(ilanBitisTarihi).add(1,"month").format("yyyy-MM-DD")} onChange={(e)=>setIlanBitisTarihi(e.target.value)} class="form-control" id="validationDefault01" placeholder="İlan Sahibi Yetkili Telefonu"  required/>
       
       </div>
  
                    
       

</div>


      
    
    
     <button class="btn btn-primary" onClick={()=>addRentVehicle()} type="button">İlanı Ekle</button>
   </form></div></div></div>
  )
}
export default AracKiralaSayfasi;